<div *ngIf='!viewMode' class='form-input'>
  <app-form-input-label
    [attr.data-cy]='fieldInput.fullControlName + "--input-label"'
    [formInput]='fieldInput'
    [viewMode]='viewMode'
    (click)="focusOnSelect()"
    class='input-label'
  ></app-form-input-label>
  <div [formGroup]="fieldInput.parentFormGroup">
    <div class='input-container'>
      <div *ngIf='!fieldInput.parentFormArray && !viewMode'>
        <mat-form-field appearance="outline">
          <mat-select
            #matSelectElem
            id="{{fieldInput.fullControlName}}"
            [attr.data-cy]='fieldInput.fullControlName + "--input"'
            formControlName="{{fieldInput.fieldName}}"
            placeholder='{{formInput.dataModel.placeholder}}'
            class="select"
          >
            <mat-option
              *ngFor="let item of fieldInput.dataModel.options"
              [attr.name]="item.value"
              role="option"
              [value]="item.value"
              [attr.data-cy]='fieldInput.fullControlName + "--input--option-" + item.value'
            >{{item.label}}</mat-option>
          </mat-select>
          <mat-hint
            *ngIf='fieldInput.dataModel.description'
            [attr.data-cy]='fieldInput.fullControlName + "--hint"'
          >{{fieldInput.dataModel.description}}</mat-hint>
          <mat-error>{{fieldInput.errorMessage}}</mat-error>
        </mat-form-field>
      </div>
      <div *ngIf='fieldInput.parentFormArray && !viewMode' formArrayName='{{fieldInput.parentArrayName}}'>
        <mat-form-field appearance="outline">
          <mat-select
            [attr.data-cy]='fieldInput.fullControlName + "--input"'
            formControlName="{{fieldInput.fieldName}}"
            placeholder='{{formInput.dataModel.placeholder}}'
            class="select"
          >
            <mat-option
              *ngFor="let item of fieldInput.dataModel.options"
              [attr.name]="item.value"
              role="option"
              [value]="item.value"
              [attr.data-cy]='fieldInput.fullControlName + "--input--option-" + item.value'
            >{{item.label}}</mat-option>
          </mat-select>
          <mat-hint
            *ngIf='fieldInput.dataModel.description'
            [attr.data-cy]='fieldInput.fullControlName + "--hint"'
          >{{fieldInput.dataModel.description}}</mat-hint>
          <mat-error>{{fieldInput.errorMessage}}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<div *ngIf='viewMode && isEditable' class='form-view'>
  <app-form-input-label
    [attr.data-cy]='fieldInput.fullControlName + "--input-label"'
    [formInput]='fieldInput'
    [viewMode]='viewMode'
    class='input-label'
    [ngClass]="{'field-error': fieldInput.formControl.invalid}"
  ></app-form-input-label>
  <div
    [attr.data-cy]='fieldInput.fullControlName + "--view"'
    class='result-value'
    [ngClass]="{'field-error': fieldInput.formControl.invalid}"
  > <span *ngIf="fieldInput.formControl.invalid"><acacium-group-ng-form-error-indicator >!</acacium-group-ng-form-error-indicator>&nbsp;<em>{{fieldInput.errorMessage}}</em></span>
    <span *ngIf="fieldInput.formControl.valid">{{fieldInput.formControl.value ? fieldInput.formControl.value : '-'}}</span>

  </div>
</div>
<div *ngIf='viewMode && !isEditable' class='form-view'>
  <app-form-input-label
    [attr.data-cy]='fieldInput.fullControlName + "--input-label"'
    [formInput]='fieldInput'
    [viewMode]='viewMode'
    class='input-label'
  ></app-form-input-label>
  <div
    [attr.data-cy]='fieldInput.fullControlName + "--view"'
    class='result-value'
  >
    <span *ngIf="fieldInput.formControl.invalid">-</span>
    <span *ngIf="fieldInput.formControl.valid">{{fieldInput.formControl.value ? fieldInput.formControl.value : '-'}}</span>
  </div>
</div>
