import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { InputModel } from '../configuration-models/input-model';
import { FormInput } from './form-input';
import { EventTrigger } from '../enums/event-trigger.enum';
import { ControlType } from '../enums/control-type.enum';

export class FieldInput extends FormInput {
  public fieldControl!: UntypedFormControl;
  public parentFormGroup!: UntypedFormGroup;
  public parentFormArray!: UntypedFormArray;
  public parentArrayName!: string;

  constructor(rootFormGroup: UntypedFormGroup, dataModel: InputModel, fullControlName: string, parentArrayName = '') {
    super(rootFormGroup, dataModel, fullControlName);

    let updateOn: 'change' | 'blur' | 'submit';

    switch (dataModel.updateOn) {
      case EventTrigger.OnBlur:
        updateOn = 'blur';
        break;
      case EventTrigger.OnChange:
        updateOn = 'change';
        break;
      case EventTrigger.OnSubmit:
        updateOn = 'submit';
        break;
    }

    this.fieldControl = new UntypedFormControl(
      {
        value: this.dataModel.type === ControlType.Files && !this.dataModel.defaultValue ? [] : this.dataModel.defaultValue,
        disabled: this.dataModel.isReadOnly
      },
      {
        validators: [],
        updateOn: 'change'
      });

    this.formControl = this.fieldControl;

    this.initializeFormGroups(rootFormGroup, parentArrayName);

    if(parentArrayName) {
      this.parentArrayName = parentArrayName;
      this.parentFormArray = this.parentFormGroup.get(parentArrayName) as UntypedFormArray;
      this.parentFormArray.push(this.fieldControl);
    }

    this.addValidators();
  }

  public setValue(value: any): void {
    this.fieldControl.setValue(value);
  }

  private addValidators() {
    if (this.dataModel.isRequired) {
      this.fieldControl.addValidators(Validators.required);
    }

    if (this.dataModel.minLength) {
      this.fieldControl.addValidators(Validators.minLength(this.dataModel.minLength));
    }

    if (this.dataModel.maxLength) {
      this.fieldControl.addValidators(Validators.maxLength(this.dataModel.maxLength));
    }

    if (this.dataModel.regexExpression) {
      this.fieldControl.addValidators(Validators.pattern(this.dataModel.regexExpression));
    }

    if (this.dataModel.validationRule) {
      // ValidatorFn must return `null` if there is no error, not `{customError: { errorMessage: null }}`
      const validationFunction:  ValidatorFn = (control) => {
        const values = this.dataModel.validationDependencies?.map(e => this.rootFormGroup.get(e)?.value);


        if(values!==undefined && this.dataModel.validationRule!==undefined) {
          const error = this.dataModel.validationRule(control.value, values);
          if (error !== null) {
            return { customError: { errorMessage: error } } ;
          }
        }
        return null;
      };

      this.fieldControl.addValidators(validationFunction);
    }

    if (this.dataModel.isCapitalized) {
      this.fieldControl.valueChanges.subscribe(value => {
        const capitalizedValue = value?.toString().toUpperCase();

        if (value === capitalizedValue) {
          return;
        }

        this.fieldControl.setValue(value?.toString().toUpperCase());
      });
    }
  }
}
