import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'acacium-group-ng-form-error-indicator',
  templateUrl: './form-error-indicator.component.html',
  styleUrls: ['./form-error-indicator.component.scss']
})
export class FormErrorIndicatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
