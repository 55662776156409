import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorDialogData } from './dialog-error.model';

@Component({
  selector: 'acacium-group-ng-dialog-error',
  templateUrl: './dialog-error.component.html',
  styleUrls: ['./dialog-error.component.scss']
})
export class DialogErrorComponent implements OnInit {
  title = '';
  message = '';     // Message can contain html tags
  constructor(
    public dialogRef: MatDialogRef<DialogErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ErrorDialogData
  ) {}

  ngOnInit() {
    this.title = this.data.title;
    this.message = this.data.message
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
