import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PageGreetingComponent } from './page-greeting/page-greeting.component';

@NgModule({
  declarations: [
    PageGreetingComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    PageGreetingComponent
  ]
})
export class ComponentsModule { }
