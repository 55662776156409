import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort, Sort, SortDirection } from '@angular/material/sort'

@Component({
  selector: 'acacium-group-ng-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss']
})
export class SortComponent {
  @Input() sortEntity = 'sort';
  @Input() sortLabel = 'Asc';
  @Input() defaultDirection: SortDirection = 'asc';
  @Input() sortName = 'name';

  @Output() runSort = new EventEmitter<Sort>();
  @ViewChild(MatSort) sort: MatSort | undefined;

  triggerSort(event: Sort) {
    this.runSort.emit(event);
  }
}
