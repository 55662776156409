import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlyNumberDirective } from './only-number/only-number.directive';
import { NhsNumberDirective } from './nhs-number/nhs-number.directive';



@NgModule({
  declarations: [
    OnlyNumberDirective,
    NhsNumberDirective
  ],
  exports: [
    OnlyNumberDirective,
    NhsNumberDirective
  ],
  imports: [
    CommonModule
  ]
})
export class DirectivesModule { }
