/* eslint-disable @angular-eslint/component-selector */
import { Component, Input } from '@angular/core';
import { IFooterData } from '@acacium-group-ng/data-definition';

@Component({
  selector: 'acacium-group-ng-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Input() public footerDef: IFooterData;

  constructor() {
    this.footerDef = {
      address: { text: '1 street, town, country. AB55 CDE.', class: '' },
      email: { text: 'email@email.com', class: '' },
      phone: { text: '0123 456 7890', class: '' },
      socialLinks: [
        { url: '', target: '', icon: '', class : '' }
      ],
      baseTexts: [{ text:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', class:'' }],
      cqcNumber: {text: 'QCQ Provider Id', class: '' },
      privacyPolicyLink: { linkText: 'Privacy policy', url: '', target: '', icon: '', class: ''},
      accessibilityLink: { linkText: 'Accessibility statement', url: '', target: '', icon: '', class: ''},
      serviceAccessLink: { linkText: 'Service Access and Eligibility Statement', url: '', target: '', icon: '', class: ''},
    };
  }
}
