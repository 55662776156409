import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-page-greeting',
  templateUrl: './page-greeting.component.html',
  styleUrls: ['./page-greeting.component.scss']
})
export class PageGreetingComponent {
  @Input() public title = '';
  @Input() public strapline = '';
}
