import { Component, forwardRef, Input } from '@angular/core';
import { FormInput } from '../form-models/inputs/form-input';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'acacium-group-ng-form-input-nhs-number-display-only',
  templateUrl: './form-input-nhs-number-display-only.component.html',
  styleUrls: ['./form-input-nhs-number-display-only.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputNhsNumberDisplayOnlyComponent),
      multi: true,
    },
  ]
})
export class FormInputNhsNumberDisplayOnlyComponent implements ControlValueAccessor {
  @Input() formInput!: FormInput;
  @Input() isEditable = true;

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: any): void {
  }
}
