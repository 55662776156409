<div class="files-list">
  <div *ngIf='viewMode && !uploadItems?.length' class="single-file">
    <div class="no-file"><em>(No files)</em></div>
  </div>
  <div *ngFor="let file of uploadItems | sortByDate; let i = index" class="single-file" [ngClass]="{'last-file' : i === uploadItems.length-1}" >
    <div class="file-item" *ngIf="file.progress > 0 && (isEditable || !viewMode)">
      <acacium-group-ng-file-item class="file-item__wrapper" [viewMode]="viewMode" [file]="file"></acacium-group-ng-file-item>
      <div *ngIf='!viewMode' class="delete">
        <div *ngIf='file.progress !== 100'>
          <mat-spinner [diameter]="24" color="accent"></mat-spinner>
        </div>
        <div *ngIf='file.progress === 100 && !hasWarnStatus(file)'>
          <mat-icon svgIcon="trash" (click)="deleteFile(file)" class="delete-icon color accent"></mat-icon>
        </div>
        <div *ngIf='hasWarnStatus(file)'>
          <mat-icon svgIcon="close" (click)="deleteFile(file)" color="warn" class="delete-icon color accent"></mat-icon>
        </div>
      </div>

    </div>
    <div class="file-item" *ngIf="!isEditable && viewMode">
      <acacium-group-ng-file-item class="file-item__wrapper" [viewMode]="viewMode" [file]="file"></acacium-group-ng-file-item>
    </div>
  </div>
</div>
