import {AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import { FieldInput } from './field-input';
import { InputModel } from '../configuration-models/input-model';
import { FormInput } from './form-input';

export class SimpleArrayInput extends FormInput {
  public arrayControl!: UntypedFormArray;
  public parentFormGroup!: UntypedFormGroup;
  public items: FormInput[] = [];
  public columnDefinitions: string[] = [];

  constructor(rootFormGroup: UntypedFormGroup, dataModel: InputModel, fullControlName: string) {
    super(rootFormGroup, dataModel, fullControlName);

    this.rootFormGroup = rootFormGroup;
    this.arrayControl = new UntypedFormArray([], {updateOn: 'change'});

    this.formControl = new UntypedFormControl(this.arrayControl.value);
    if(dataModel.columnDefinitions) {
      this.columnDefinitions = this.columnDefinitions.concat(dataModel.columnDefinitions);
    }

    this.initializeFormGroups(rootFormGroup);
    this.initialize();
  }

  public setValue(value: any): void {
    this.items = [];
    this.arrayControl.controls = [];

    for(let i = 0; i < value.length; i++) {
      this.addNewItem();
      this.items[i].formControl.setValue(value[i]);
    }
  }

  private initialize(): void {
    this.items = [];
    this.arrayControl.controls = [];

    if(!this.dataModel.defaultValue) {
      return;
    }

    for(let i = 0; i < this.dataModel.defaultValue.length; i++) {
      this.addNewItem();
      this.items[i].formControl.setValue(this.dataModel.defaultValue[i]);
    }
  }

  getFormControl(index: number): AbstractControl {
    return this.items[index].formControl;
  }

  addNewItem() {
    if(!this.dataModel.inputTemplate) {
      throw new Error('No child elements to add.');
    };

    const newDataModel = { ...this.dataModel.inputTemplate };
    newDataModel.bindingPath = `${this.items.length}`;
    newDataModel.controlName = `${this.items.length}`;
    const childFormElement = new FieldInput(this.parentFormGroup, newDataModel, `${this.fullControlName}--${newDataModel.controlName}`, this.fieldName);

    this.items.push(childFormElement);
  }

  removeItem(index: number) {
    if(!this.dataModel.inputTemplate) {
      throw new Error('No child elements to add.');
    };

    this.items.splice(index, 1);
    this.arrayControl.controls.splice(index, 1);

    for(let i = 0; i < this.items.length; i++) {
      this.items[i].dataModel.bindingPath = `${i}`;
    }

    this.arrayControl.updateValueAndValidity();
  }
}
