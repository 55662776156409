<app-drag-drop-upload
  [preventBodyDrop]="true"
  [styleOptions]="styleOptions"
  [fileOptions]="fileOptions"
  [baseUrl]="baseUrl"
  [description]='formInput.dataModel.description'
  [blobStoragePath]="blobStoragePath"
  [uploadItems]='formInput.formControl.value'
  [viewMode]='viewMode'
  [isEditable]="isEditable"
  (uploaded)='onUpload($event)'
  class="fix-width"
></app-drag-drop-upload>
