<div class="generic-wrapper">
  <form
    [id]='formElement.dataModel.controlName'
    [attr.data-cy]='formElement.dataModel.controlName'
    [formGroup]="formElement.formGroup"
  >
    <div class="content-padding content-header mat-elevation-header header-container">
      <app-page-greeting [title]="formElement.dataModel.label" [strapline]="formElement.dataModel.description"></app-page-greeting>
    </div>
    <mat-stepper
      [attr.data-cy]='formElement.dataModel.controlName + "--tabs"'
      #matStepper
      linear
      (click)='onNavigate($event)'
      class='sections-group'
    >
      <mat-step
        *ngFor="let section of formElement.visibleSections; index as stepIndex"
        [attr.data-cy]='formElement.dataModel.controlName + "--tab-" + stepIndex'
        [label]="section.dataModel.label"
        completed="false"
      >
        <div class="form-section">
          <div class="form-section__content">
            <acacium-group-ng-form-view-card *ngIf="section.dataModel.announcementBanner"
                                             [safeContent]="section.dataModel.announcementBanner"></acacium-group-ng-form-view-card>
            <mat-card *ngFor='let subsection of section.subsections'>
              <h2
                [attr.data-cy]='subsection.fullControlName + "--subsection-label"'
                label
                class='header'
              ></h2>
              <div
                *ngFor='let formInput of subsection.formInputs; index as inputIndex'
                [class]='(inputIndex === subsection.formInputs.length - 1) ? "form-input-last" : "form-input-middle"'>
                <app-form-input
                  *ngIf='formInput.isDisplayed'
                  parentStepIndex='{{stepIndex}}'
                  activeStep='{{matStepper.selectedIndex}}'
                  [attr.data-cy]='formInput.fullControlName + "--form-input"'
                  [formInput]='formInput'
                  [viewMode]='false'
                ></app-form-input>
              </div>
            </mat-card>
            <div class='buttons-container'>
              <button [disabled]="matStepper.selectedIndex === 0"
                *ngIf="matStepper.selectedIndex !== formElement.sections.length"
                [attr.data-cy]='formElement.dataModel.controlName + "--tab-" + stepIndex + "--previous-button"'
                (click)="selectPrevious()"
                mat-button
              >{{section.previousButtonLabel}}</button>
              <button
                [attr.data-cy]='formElement.dataModel.controlName + section.nextSkipLabel + "--tab-" + stepIndex + "-button"'
                *ngIf='matStepper.selectedIndex !== formElement.sections.length'
                (click)='selectNext()'
                [disabled]="section.nextButtonLabel === 'Save and continue' && ((allAttachmentsUploaded | async) === false && this.stepIndex === 1)"
                mat-flat-button
                class="stretched"
              >{{section.nextButtonLabel}}</button>
            </div>
          </div>
        </div>
      </mat-step>
      <mat-step
        [attr.data-cy]='formElement.dataModel.controlName + "--result-tab-"'
        label="Review and Submit"
        completed='true'
      >
        <div class="form-section">
          <div class="form-section__content">
            <app-form-result-view
              class="generic-form-result-view"
              [attr.data-cy]='formElement.dataModel.controlName + "--result-view"'
              [sections]="formElement.sections"
              [formValid]="allSectionsValid"
              (navigateTo)='matStepper.steps.get($event)?.select()'
            ></app-form-result-view>
            <div class='buttons-container' [ngClass]="{'buttons-container-extra-margin': matStepper.selectedIndex === formElement.visibleSections.length}">
              <div *ngIf='matStepper.selectedIndex === formElement.visibleSections.length'>
                <div><mat-form-field>
                  <input class="unfilled" matInput type="text" placeholder="Please enter Your name*" formControlName="referrerName">
                  <mat-error *ngIf="hasError()
                  && (this.formElement.formGroup.controls['referrerName'].dirty || this.formElement.formGroup.controls['referrerName'].touched)"
                  class='mat-small error'>Your name is required</mat-error>
                </mat-form-field></div>
                <div class='buttons-review-and-submit'>
                  <button
                    [attr.data-cy]='formElement.dataModel.controlName + "--tab-last" + "--previous-button"'
                    (click)='selectPrevious()'
                    class="button-primary-open"
                    mat-button
                  >Previous step</button>
                  <button
                    *ngIf='matStepper.selectedIndex === formElement.visibleSections.length'
                    [attr.data-cy]='formElement.dataModel.controlName + "--tab-last" + "--submit-button"'
                    (click)='submit()'
                    mat-flat-button
                    type='submit'
                    class="stretched"
                  >Submit</button>
                </div>
              </div>
          </div>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </form>
</div>
