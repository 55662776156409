import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileAvatarComponent } from './components/profile-avatar/profile-avatar.component';
import { MatMenuModule } from '@angular/material/menu';
import { ProfileHeadingComponent } from './components/profile-heading/profile-heading.component';
import { ProfileDetailComponent } from './components/profile-detail/profile-detail.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule
  ],
  declarations: [
    ProfileAvatarComponent,
    ProfileHeadingComponent,
    ProfileDetailComponent
  ],
  exports: [
    ProfileAvatarComponent,
    ProfileHeadingComponent
  ]
})
export class ProfileModule {}
