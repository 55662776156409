import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nhsNumber'
})
export class NhsNumberPipe implements PipeTransform {

  transform(nhsNumberUnformatteed: string): string {
    const _nhsNumberUnformatteed = nhsNumberUnformatteed.replace(/\D/g, '');
    // if (_nhsNumberUnformatteed.length > 10) {
    //   throw new Error('Invalid nhs format.')
    // }
    if (_nhsNumberUnformatteed.length > 6) {
      return  _nhsNumberUnformatteed.substring(0, 3) + ' ' + _nhsNumberUnformatteed.substring(3, 6) + ' ' + _nhsNumberUnformatteed.substring(6, _nhsNumberUnformatteed.length)
    }
    if (nhsNumberUnformatteed.length > 3) {
      return  _nhsNumberUnformatteed.substring(0, 3) + ' ' + _nhsNumberUnformatteed.substring(3, _nhsNumberUnformatteed.length)
    }
    return _nhsNumberUnformatteed;
  }

}
