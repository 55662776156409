import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogConfirmModel } from '../models/dialog-confirm.model';

@Component({
  selector: 'acacium-group-ng-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss'],
})
export class DialogConfirmComponent {
  public dialog: DialogConfirmModel;
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogConfirmModel
  ) {
    this.dialog = new DialogConfirmModel(data.options);
  }
  handleClose(): void {
    this.dialogRef.close();
  }
  onNo(): void {
    this.dialogRef.close(false);
  }

  onOk(): void {
    this.dialogRef.close(true);
  }

  onCancel(): void {
    this.dialogRef.close({ cancel: true });
  }
}
