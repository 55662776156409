<!--view review and submit in a draft-->
<div *ngIf='isEditable' class='form-view'>
  <app-form-input-label
    [attr.data-cy]='formInput.fullControlName + "--input-label"'
    [formInput]='formInput'
    [viewMode]='true'
    class='input-label'
    [ngClass]="{'field-error': formInput.formControl.invalid}"
  ></app-form-input-label>
  <div class='result-value'>
    <div
      *ngIf='formInput.formControl.value && formInput.formControl.valid'
      [attr.data-cy]='formInput.fullControlName + "--view"'
    >{{formInput.formControl.value | nhsNumber}}</div>
    <em
      *ngIf='!formInput.formControl.value && formInput.formControl.valid'
      [attr.data-cy]='formInput.fullControlName + "--view"'
    >-</em>
    <div
      *ngIf='formInput.formControl.invalid'
      [attr.data-cy]='formInput.fullControlName + "--view-error"'
      class="field-error"
    ><acacium-group-ng-form-error-indicator>!</acacium-group-ng-form-error-indicator>&nbsp;<em>{{formInput.errorMessage}}</em></div>
  </div>
</div>

<!--view submitted referral-->
<div *ngIf='!isEditable' class='form-view'>
  <app-form-input-label
    [attr.data-cy]='formInput.fullControlName + "--input-label"'
    [formInput]='formInput'
    [viewMode]='true'
    [ngClass]="{'field-error': formInput.formControl.invalid}"
    class='input-label'
  ></app-form-input-label>
  <div class='result-value'>
    <div
      *ngIf='formInput.formControl.value && formInput.formControl.valid'
      [attr.data-cy]='formInput.fullControlName + "--view"'
    >{{formInput.formControl.value | nhsNumber}}</div>
    <em
      *ngIf='!formInput.formControl.value && formInput.formControl.valid'
      [attr.data-cy]='formInput.fullControlName + "--view"'
    >-</em>
    <div
      *ngIf='formInput.formControl.invalid'
      [attr.data-cy]='formInput.fullControlName + "--view-error"'
      class="field-error"
    ><acacium-group-ng-form-error-indicator>!</acacium-group-ng-form-error-indicator>&nbsp;<em>{{formInput.errorMessage}}</em></div>
  </div>
</div>
