import { Injectable } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
  // ToDo YOU FUCKING SERIOUS????
  providedIn: 'any'
})
export class MatIconRegistrationService {

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.Initialise();
  }

  public Initialise() {
    this.Register('twitter', 'assets/icons/twitter-brands.svg');
    this.Register('home-health', 'assets/icons/home-health.svg');
    this.Register('linkedIn', 'assets/icons/linkedin-in-brands.svg');
    this.Register('save', 'assets/icons/save-solid.svg');
    this.Register('upload', 'assets/icons/file-upload-solid.svg');
    this.Register('download', 'assets/icons/file-download-solid.svg');
    this.Register('file', 'assets/icons/file-alt-solid.svg');
    this.Register('trash', 'assets/icons/trash-solid.svg');
    this.Register('cross', 'assets/icons/times-solid.svg');
    this.Register('arrow-up', 'assets/icons/arrow-alt-circle-up-solid.svg');
    this.Register('check-circle-solid', 'assets/icons/check-circle-solid.svg');
    this.Register('check-circle-regular', 'assets/icons/check-circle-regular.svg');
    this.Register('edit-solid', 'assets/icons/edit-solid.svg');
    this.Register('cancel', 'assets/icons/ban-solid.svg');
    this.Register('plus-solid', 'assets/icons/plus-solid.svg');
    this.Register('check', 'assets/icons/check-circle-solid.svg');
    this.Register('paper-clip', 'assets/icons/paperclip-solid.svg');
    this.Register('user-plus-solid', 'assets/icons/user-plus-solid.svg');
    this.Register('magnifying-glass-solid', 'assets/icons/magnifying-glass-solid.svg');
    this.Register('arrow-right-solid', 'assets/icons/arrow-right-solid.svg');
    this.Register('arrow-left-solid', 'assets/icons/arrow-left-solid.svg');
    this.Register('signout', 'assets/icons/right-from-bracket-solid.svg');
    this.Register('phone', 'assets/icons/phone-solid.svg');
    this.Register('email', 'assets/icons/envelope-solid.svg');
    this.Register('chevron-right-solid', 'assets/icons/chevron-right-solid.svg');
  }

  private Register(name: string, path: string) {
    this.matIconRegistry.addSvgIcon(name,
      this.domSanitizer.bypassSecurityTrustResourceUrl(path));
  }
}
