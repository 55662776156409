<table
  [attr.data-cy]="sortEntity + '-sort'"
  matSort
  [matSortDirection]="defaultDirection"
  (matSortChange)="triggerSort($event)"
>
  <tr>
    <th mat-sort-header="{{ sortName }}" >{{ sortLabel }}</th>
  </tr>
</table>
