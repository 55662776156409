<div class="section">
  <div *ngIf="questionAnswers.length > 0">
    <!--   [disabled] attribute doesn't work.. -->
    <button type="button" class="section__download" (click)="downloadCopy()">
      <mat-icon svgIcon="download" class="section__download--icon"></mat-icon>Download a copy
    </button>

    <h1 class="section__title">{{ title }}</h1>
    <h2 class="section__subtitle">{{ subTitle }}:</h2>

    <li>
      <ol *ngFor="let questionAnswer of questionAnswers; let i = index">
        <a class="section__contents" (click)="scrollToElement(i)">{{ i+1 }}. {{ questionAnswer.question }}</a>
      </ol>
    </li>

    <div class="section__paragraph" *ngFor="let questionAnswer of questionAnswers; let i = index" [id]="i" >
      <h3 class="section__paragraph--question">{{ questionAnswer.question }}</h3>
      <p class="section__paragraph--answer">{{ questionAnswer.answer }}</p>
    </div>
  </div>

  <div *ngIf="questionAnswers.length <= 0">
    <button type="button" class="section__download section__download--disabled" disabled>
      <mat-icon svgIcon="download" class="section__download--icon"></mat-icon>Download a copy
    </button>
    <h1 class="section__title">{{ title }}</h1>
    <div>{{ title }} details haven't been added yet.</div>
  </div>
</div>

