import { Pipe, PipeTransform } from '@angular/core';
import { IUploadItem } from '@acacium-group-ng/data-definition';

@Pipe({
  name: 'sortByDate'
})
export class SortByDatePipe implements PipeTransform {

  transform(items: IUploadItem[]): IUploadItem[] {
    return items?.sort((a: IUploadItem, b: IUploadItem) => {
      const dateA = new Date(a.uploadedDateTime);
      const dateB = new Date(b.uploadedDateTime);
      return dateA.getTime() - dateB.getTime();
    });
  }

}
