import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FormInput } from '../form-models/inputs/form-input';
import { FormInputComplexArrayComponent } from '../form-input-complex-array/form-input-complex-array.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements OnChanges {
  @Input() formInput!: (FormInput);
  @Input() viewMode!: boolean;
  @Input() isEditable!: boolean;
  @Input() activeStep!: string;
  @Input() parentStepIndex!: string;

  @ViewChild('appFormInputComplexArray') appFormInputComplexArray: FormInputComplexArrayComponent | undefined;
  ngOnChanges(changes: SimpleChanges): void {
    // eslint-disable-next-line no-prototype-builtins
    if (changes.hasOwnProperty('activeStep') &&
      this.formInput.dataModel.controlName === 'relationship' &&
      !!changes.activeStep.previousValue &&
      changes.activeStep.previousValue === '3' &&
      !!this.appFormInputComplexArray) {
      this.appFormInputComplexArray.cancel();
    }
    // eslint-disable-next-line no-prototype-builtins
    if (changes.hasOwnProperty('activeStep') &&
      !!changes.activeStep.previousValue &&
      !!this.parentStepIndex &&
      changes.activeStep.previousValue === this.parentStepIndex) {
      // mark form control as touched if user navigating away from the step that contain this control
      this.formInput.formControl.markAsTouched();

    }
  }
}
