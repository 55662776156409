import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { DateFormats, FooterModule } from '@acacium-group-ng/ui-components';
// Self installed modules
import { AuthModule } from '@auth0/auth0-angular';
import { NavComponentModule } from './shared/components/nav-component.module';
import { environment, environment as env } from './../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { UserIdentityHelperService } from './shared/services/user-identity/user-identity-helper.service';
import { LocalStorageRefService } from './shared/services/storage/local-storage-ref.service';
import { LocalStorageService } from './shared/services/storage/local-storage.service';
import { HttpCacheService } from './shared/services/storage/http-cache.service';
// Future feature modules needed for these:
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserIdleModule } from 'angular-user-idle';
import { UrlService } from './shared/services/url/url.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtAuthService } from './shared/features/jwt-auth/services/jwt-auth.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  MAT_DATE_FORMATS,
  MatNativeDateModule,
  MatRippleModule,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { DatePipe } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { enGB } from 'date-fns/locale';
import { httpInterceptorProviders } from './shared/services/interceptors';
import { APP_CONFIG } from '@acacium-group-ng/shared/data-access/agng-app-config';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FooterModule,
    NavComponentModule,
    // Self installed module imports
    AuthModule.forRoot({
      ...env.auth,
      cacheLocation: 'localstorage',
      useRefreshTokens: true,
    }),
    HttpClientModule,
    AppRoutingModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatFormFieldModule,
    MatRippleModule,
    MatSelectModule,
    MatDialogModule,
    MatNativeDateModule,
    MatDateFnsModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatStepperModule,
    UserIdleModule.forRoot({ ...env.idleTimeout }),
  ],
  providers: [
    {
      provide: APP_CONFIG,
      useValue: { ...environment },
    },
    DatePipe,
    LocalStorageRefService,
    LocalStorageService,
    JwtAuthService,
    HttpCacheService,
    UserIdentityHelperService,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        autoFocus: true,
        maxWidth: 957,
        minWidth: 957,
        panelClass: 'xyla-mat-dialog-container',
      },
    },
    UrlService,
    httpInterceptorProviders,
    { provide: MAT_DATE_LOCALE, useValue: enGB },
    { provide: MAT_DATE_FORMATS, useValue: DateFormats },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
