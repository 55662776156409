import { UntypedFormGroup } from '@angular/forms';
import { FormModel } from './configuration-models/form-model';
import { Section } from './section-element.model';
import { Subscription } from 'rxjs';

export class FormElement {
  public formGroup: UntypedFormGroup;
  public sections: Section[] = [];
  public fullControlName: string;
  subscriptions: Subscription = new Subscription();

  public get visibleSections(): Section[] {
    return this.sections.filter(e => e.isDisplayed);
  }

  constructor(public dataModel: FormModel) {
    this.formGroup = new UntypedFormGroup({}, {updateOn: 'change'});
    this.fullControlName = this.dataModel.controlName;

    if(!this.dataModel.sections?.length) {
      throw new Error('Form control should have at least one section');
    }

    for(let i = 0; i < this.dataModel.sections.length; i++) {
      this.sections.push(new Section(
        this.formGroup,
        this.dataModel.sections[i],
        `${this.fullControlName}--${this.dataModel.sections[i].controlName}`
      ));
    }

    this.subscribeToDependencies();
  }

  public setValue(value: any): void {
    this.sections.forEach(section => section.setValue(value));
  }

  // Because without value changes form input doesn't refresh validation,
  // it is impossible to validate external changes out of input data.
  // We have to manually subscribe to all dependencies and refresh input manually.
  private subscribeToDependencies(): void {
    this.dataModel.sections
      .map(e => e.subsections)
      .flat()
      .map(e => e.inputs)
      .flat()
      .filter(e => e.validationDependencies?.length)
      .forEach(e => {
        const input = this.formGroup.get(e.bindingPath);
        e.validationDependencies?.forEach(dependency => {
          this.subscriptions.add(this.formGroup.get(dependency)?.valueChanges.subscribe(_ => {
            input?.updateValueAndValidity();
          }))
        });
      });
  }
}
