import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { JwtAuthGuard } from './shared/guards/jwt-auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import ('./features/auth/app.auth.module').then(m => m.AppAuthModule)
  },
  {
    path: '',
    canActivateChild: [ AuthGuard, JwtAuthGuard ],
    children: [
      {
        path: 'home',
        loadChildren: () => import ('./features/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'referrals',
        loadChildren: () => import ('./features/referral/referral.module').then(m => m.ReferralModule)
      },
      // ToDo: uncomment when pathways will become part of the project
      // {
      //   path: 'pathways',
      //   data: {preload: true, featureFlag: featureFlags['pathways'] },
      //   loadChildren: () => import ('./features/pathway/pathway.module').then(m => m.PathwayModule)
      // },
    ]
  },
  {
    // Default path - path not found
    path: '**',
    component: NotFoundComponent
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    preloadingStrategy: NoPreloading,
    useHash: true,
    scrollPositionRestoration: 'top'
  })],//, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
