import { MatIconRegistrationService } from '@acacium-group-ng/ui-components';
import { Component, ErrorHandler, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../environments/environment';
import Bugsnag, { Client } from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { IFooterData } from '@acacium-group-ng/data-definition';
import { JwtAuthService } from './shared/features/jwt-auth/services/jwt-auth.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: []
})
export class AppComponent implements OnInit  {
  public title = 'Referrer-Portal';
  public footerDef: IFooterData = {
    address: { text: 'Xyla Digital Therapies, 9 Appold Street, London, United Kingdom, EC2A 2AP', class: 'mat-title' },
    email: { text: 'info@xyladigitaltherapies.com', class: 'mat-title' },
    phone: { text: '0333 577 3024', class: 'mat-title' },
    baseTexts: [
      { text: 'Registered office address: 9 Appold Street, London, United Kingdom, EC2A 2AP. Registered in England and Wales.', class: 'mat-subheading-2'},
      { text: 'Company registration number 04793945. Xyla Digital Therapies is a trading name of ICS Operations Ltd | © 2023', class: 'mat-subheading-2' }
    ],
    socialLinks: [
      { url: 'https://twitter.com/Xyla_DT', target: '_blank', class: 'footer', icon: 'twitter' },
      { url: 'https://www.linkedin.com/company/xyla-digital-therapies/', target: '_blank', class: 'footer', icon: 'linkedIn' },
    ],
    cqcNumber: { text: 'CQC Provider ID: 1-9346113699', class: 'cqc-text'},
    privacyPolicyLink: { linkText: 'Privacy policy', url: 'https://xyladigitaltherapies.com/privacy-policy/', target: '_blank', class: 'policy-link'},
    accessibilityLink: { linkText: 'Accessibility Statement', url: 'https://xyladigitaltherapies.com/children-and-young-persons-portal-accessibility-statement/', target: '_blank', class: 'policy-link'},
    serviceAccessLink: { linkText: 'Service Access and Eligibility Statement', url: 'https://xyladigitaltherapies.com/service-access-and-eligibility-statement/', target: '_blank', class: 'policy-link'}
  };

  constructor(
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private matIconRegistration: MatIconRegistrationService,
    private jwtService: JwtAuthService
  ) {
    this.jwtService.boot();
    let bsClient: Client | null = null;
    // initialise bugsnag if needed
    if (environment.logging.bugsnag && environment.bugsnag.apiKey) {
      bsClient = Bugsnag.start({
        apiKey: environment.bugsnag.apiKey,
        autoTrackSessions: false,
        releaseStage: environment.name,
        appVersion: environment.version });
      bsClient.startSession();
    }
    // initialise
    if (environment.logging.appInsights) {
      const angularPlugin = new AngularPlugin();
      // array of additional error handlers
      const _errorServices: ErrorHandler[] = [];
      if (environment.logging.bugsnag && environment.bugsnag.apiKey && bsClient !== null) {
        _errorServices.push( new BugsnagErrorHandler(bsClient))
      }
      const _appInsights = new ApplicationInsights({ config: {
          instrumentationKey: environment.appInsights.instrumentationKey,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          extensions: [angularPlugin],
          extensionConfig: {
            [angularPlugin.identifier]: {
              router: this.router,
              errorServices: _errorServices}
          }
        } });
      const appInsights = _appInsights.loadAppInsights();
      this.auth.user$.subscribe({next: (v) => {
          appInsights.trackEvent({ name: 'Application Started'} )
          if (v && v.email) {
            appInsights.setAuthenticatedUserContext(v.email );
          }
        }
      })
    }

  }

  ngOnInit() {

    if ((new URLSearchParams(window.location.search)).get('invitation') !== null) {
      this.router.navigate(['/auth'], { queryParams: {
        invitation: (new URLSearchParams(window.location.search)).get('invitation'),
        organization: (new URLSearchParams(window.location.search)).get('organization')
      } }).catch(err => console.error('Navigation error: ', err));
    }
  }
}
