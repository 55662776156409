import { IProfileAvatarItem } from '@acacium-group-ng/ui-components';
import { Injectable } from '@angular/core';
import { IdToken } from '@auth0/auth0-angular';

import { IAuthUser, IAuthUserIdentity } from "@acacium-group-ng/data-definition";

@Injectable({
  providedIn: 'root'
})

export class UserIdentityHelperService {
  public static getIdentity(token: IdToken | undefined): IAuthUserIdentity {
    const userIdentity: IAuthUserIdentity = { trust: {}, access: {}, user: {} };
    if (token) {
      userIdentity.user = token as IAuthUser;
      userIdentity.trust = JSON.parse(token['http://info.trust.com']);
      userIdentity.access = JSON.parse(token['http://access.trust.com']);
    }

    return userIdentity
  }

  public static getProfileAvatar(token: IdToken | undefined): IProfileAvatarItem {
    let profileAvatarItem: IProfileAvatarItem = { name: '', trust: '' };
    const identity = this.getIdentity(token);
    profileAvatarItem = {
      name: identity.user.name || '',
      nickname: identity.user.nickname || '',
      picture: identity.user.picture,
      email: identity.user.email || '',
      updated_at: identity.user.updated_at,
      trust: identity.trust.name || ''
    }

    return profileAvatarItem;
  }
}
