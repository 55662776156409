import { ControlType } from './enums/control-type.enum';
import { FieldInput } from './inputs/field-input';
import { SimpleArrayInput } from './inputs/simple-array-input.model';
import { ComplexArrayInput } from './inputs/complex-array-input.model';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SubsectionModel } from './configuration-models/subsection-model';
import { FormInput } from './inputs/form-input';

export class Subsection {
  public dataModel: SubsectionModel;
  public fullControlName!: string;
  public formInputs: FormInput[] = [];
  public visibilityDependencies: UntypedFormControl[] = [];

  public get isDisplayed(): boolean {
    if (!this.dataModel.visibilityRule) {
      return true;
    }

    return this.dataModel.visibilityRule(
      this.visibilityDependencies.map((e) => e.value)
    );
  }

  constructor(
    rootFormGroup: UntypedFormGroup,
    dataModel: SubsectionModel,
    fullControlName: string
  ) {
    this.dataModel = dataModel;
    this.fullControlName = fullControlName;
    this.visibilityDependencies = dataModel.visibilityDependencies
      ? dataModel.visibilityDependencies.map(
          (e) => rootFormGroup.get(e) as UntypedFormControl
        )
      : [];

    if (!this.dataModel.inputs?.length) {
      throw new Error('Subsection must contains at least one child item');
    }

    for (let i = 0; i < this.dataModel.inputs.length; i++) {
      const childDataModel = this.dataModel.inputs[i];

      let formInput: FormInput;

      switch (childDataModel.type) {
        case ControlType.SimpleArray:
          formInput = new SimpleArrayInput(
            rootFormGroup,
            childDataModel,
            `${this.fullControlName}--${childDataModel.controlName}`
          );
          break;
        case ControlType.ComplexArray:
          formInput = new ComplexArrayInput(
            rootFormGroup,
            childDataModel,
            `${this.fullControlName}--${childDataModel.controlName}`
          );
          break;
        default:
          formInput = new FieldInput(
            rootFormGroup,
            childDataModel,
            `${this.fullControlName}--${childDataModel.controlName}`
          );
          break;
      }

      this.formInputs.push(formInput);
    }
  }

  /*
      value = the whole array of values from the form
      i.e. patient.NHSNumber patient.firstName etc, referral.frequencyDescription referral.currentMedication ,relationships[] - array of complex type
   */
  public setValue(value: any): void {
    this.formInputs.forEach(input => input.setValue(this.getValue(input.dataModel.bindingPath, value)));
  }

  private getValue(path: string, object: any): any {
    const propertyNames = path.split('.');

    let value = object;

    for (let i = 0; i < propertyNames.length; i++) {
      value = value[propertyNames[i]];
    }

    return value;
  }
}
