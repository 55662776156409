import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'acacium-group-ng-pop-up-message',
  templateUrl: './pop-up-confirm.component.html',
  styleUrls: ['./pop-up-confirm.component.scss']
})
export class PopUpConfirmComponent {

  constructor(
    public dialogRef: MatDialogRef<PopUpConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string,
  ) {}

}
