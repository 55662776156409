import {AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import { InputModel } from '../configuration-models/input-model';
import { FormInput } from './form-input';
import { Subsection } from '../subsection';

export class ComplexArrayInput extends FormInput {
  public arrayControl!: UntypedFormArray;
  public parentFormGroup!: UntypedFormGroup;
  public items: { formGroup: UntypedFormGroup; subsections: Subsection[] }[] = [];
  public columns!: InputModel[];
  public columnDefinitions: string[] = [];

  constructor(
    rootFormGroup: UntypedFormGroup,
    dataModel: InputModel,
    fullControlName: string
  ) {
    super(rootFormGroup, dataModel, fullControlName);

    this.rootFormGroup = rootFormGroup;
    this.arrayControl = new UntypedFormArray([], {updateOn: 'change'});

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.formControl = this.arrayControl ;

    if (dataModel.columnDefinitions) {
      this.columnDefinitions = this.columnDefinitions.concat(
        dataModel.columnDefinitions
      );
    }


    if (dataModel.arrayViewColumns !== undefined) {
      this.columns = dataModel.arrayViewColumns.map((e) => {
        const input = dataModel.formTemplate
            ?.map((e) => e.inputs)
            .flat(1)
            .find((c) => c.bindingPath === e)
        if (input !== undefined) {
          return input;
        } else

            // throw new Error('No child elements to add.');
          return new InputModel();


      });
    }
    this.initializeFormGroups(rootFormGroup);
    this.initialize();
  }

  public setValue(value: any): void {
    this.items = [];
    this.arrayControl.controls = [];

    for (let i = 0; i < value.length; i++) {
      this.addNewItem();
      this.items[i].subsections[0].formInputs.forEach((input) =>
        input.setValue(this.getValue(input.dataModel.bindingPath, value[i]))
      );
    }
  }

  private getValue(path: string, object: any): any {
    const propertyNames = path.split('.');

    let value = object;

    for (let i = 0; i < propertyNames.length; i++) {
      value = value[propertyNames[i]];
    }

    return value;
  }

  private initialize(): void {
    this.items = [];
    this.arrayControl.controls = [];

    if (!this.dataModel.defaultValue) {
      return;
    }
    for (let i = 0; i < this.dataModel.defaultValue.length; i++) {
      this.addNewItem();
      this.items[i].formGroup.setValue(this.dataModel.defaultValue[i]);
    }
  }

  getFormControl(index: number, bindingPath: string): AbstractControl | null {

    return this.items[index].formGroup.get(bindingPath);
  }

  addNewItem() {
    if (!this.dataModel.formTemplate?.length) {
      throw new Error('No child elements to add.');
    }

    const newItem: { formGroup: UntypedFormGroup; subsections: Subsection[] } = {
      formGroup: new UntypedFormGroup({}, {updateOn: 'change'}),
      subsections: [],
    };

    for (let i = 0; i < this.dataModel.formTemplate.length; i++) {
      const subsectionModel = this.dataModel.formTemplate[i];
      const newDataModel = { ...subsectionModel };

      const subsection = new Subsection(
        newItem.formGroup,
        newDataModel,
        `${this.fullControlName}--${this.items.length}--${newDataModel.controlName}`
      );
      newItem.subsections.push(subsection);
    }

    this.arrayControl.push(newItem.formGroup);
    this.items.push(newItem);

    newItem.subsections
      .flat()
      .map((e) => e.formInputs)
      .flat()
      .filter((e) => e.dataModel.validationDependencies?.length)
      .forEach((e) => {
        const input = newItem.formGroup.get(e.dataModel.bindingPath);
        e.dataModel.validationDependencies?.forEach((dependency) => {
          newItem.formGroup.get(dependency)?.valueChanges.subscribe((_) => {
            input?.updateValueAndValidity();
          });
        });
      });

    // This block is to try to remove the invalidation when an item is added from the review screen
    // It doesn't currently work but left in for possible refinement later
    const parentControlName = this.dataModel.controlName;
    const parentFormGroup = this.rootFormGroup.get(parentControlName);
    parentFormGroup?.setErrors(null);
    parentFormGroup?.markAsUntouched();
    parentFormGroup?.markAsPristine();
    parentFormGroup?.updateValueAndValidity();
  }

  removeItem(index: number) {
    if (!this.dataModel.formTemplate?.length) {
      throw new Error('No child elements to add.');
    }

    this.items.splice(index, 1);

    for (let i = 0; i < this.items.length; i++) {
      for (let ii = 0; ii < this.items[i].subsections.length; ii++) {
        const subsection = this.items[i].subsections[ii];
        subsection.fullControlName = `${this.fullControlName}--${i}--${subsection.dataModel.controlName}`;

        for (let iii = 0; iii < subsection.formInputs.length; iii++) {
          const formInput = subsection.formInputs[iii];
          formInput.fullControlName = `${subsection.fullControlName}--${formInput.dataModel.controlName}`;
        }
      }
    }

    this.arrayControl.controls.splice(index, 1);
    this.arrayControl.updateValueAndValidity();
  }
}
