import { IProfileAvatarItem } from '../../models/profile-avatar-item.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'acacium-group-ng-profile-heading',
  templateUrl: './profile-heading.component.html',
  styleUrls: ['./profile-heading.component.scss']
})
export class ProfileHeadingComponent implements OnInit {
  @Input() public profileAvatarItem: IProfileAvatarItem = { name: '', trust: '' };
  @Input() public cssClasses = '';
  @Input() public maxLength = 10;
  @Input() public elementId = '';

  public nameFixedLength = '';
  public trustFixedLength = '';

  ngOnInit(): void {
    this.nameFixedLength = this.trimStringAddElipses(this.profileAvatarItem.name, this.maxLength);
    this.trustFixedLength = this.trimStringAddElipses(this.profileAvatarItem.trust, this.maxLength);
  }

  private trimStringAddElipses(target: string, maxLength: number): string {
    if (target.length > maxLength) {
      return `${target.substring(0, 27)}...`;
      //target.substr(0, 27);
    }

    return target;
  }
}
