/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import { LocalStorageRefService } from "./local-storage-ref.service";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
   protected _localStorage: Storage;

   constructor(protected _localStorageRefService: LocalStorageRefService) {
      this._localStorage = _localStorageRefService.localStorage
   }

  set(key: string, value: any) {
    this._localStorage.setItem(key, value);
  }

  get(key: string): string | null {
      return this._localStorage.getItem(key);
  }

  remove(key: string) {
    this._localStorage.removeItem(key);
  }

  clear() {
    this._localStorage.clear();
  }
}
