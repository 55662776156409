<div class="fields-set">
  <h3
    [attr.data-cy]='subsection.fullControlName + "--label"'
    class="fields-set-header"
  >{{subsection.dataModel.label}}</h3>
  <ng-template ngFor let-formInput [ngForOf]="subsection.formInputs">
    <app-form-input
      *ngIf="formInput.isDisplayed"
      [attr.data-cy]='formInput.fullControlName + "--form-input"'
      [formInput]="formInput"
      [viewMode]="viewMode"
      [isEditable]="isEditable"
    ></app-form-input>
  </ng-template>
</div>
