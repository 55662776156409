import {
  Component, DoCheck,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup, FormGroupDirective,
  NgControl, NgForm,
  Validators
} from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormInputNhsFieldComponentMixinBase } from '../form-input/nhs-field-mixin';

@Component({
  selector: 'acacium-group-ng-form-input-nhs-field',
  templateUrl: './form-input-nhs-field.component.html',
  styleUrls: ['./form-input-nhs-field.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FormInputNhsFieldComponent, multi: true }],
})
export class FormInputNhsFieldComponent extends FormInputNhsFieldComponentMixinBase implements OnDestroy, OnInit, ControlValueAccessor, MatFormFieldControl<string>, DoCheck {

  static nextId = 0;
  touched = false;
  focused = false;
  autofilled = false;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private _onChange: (value: any) => void;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private _onTouched: () => void;
  private _errorState = false;
  private subscriptions = new Subscription();
  public innerFormGroup: UntypedFormGroup;
  public stateChanges = new Subject<void>();
  controlType = 'nhs-number-input';

  @HostBinding('attr.aria-describedby') describedBy = '';

  @HostBinding() id = `acacium-group-ng-form-input-nhs-field-${FormInputNhsFieldComponent.nextId++}`;

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy!: string;
  @Input() errorStateMatcher!: ErrorStateMatcher;
  @Input() inputId!: string;

  get empty() {
    const {
      value: { p1, p2, p3 }
    } = this.innerFormGroup;

    return !p1 && !p2 && !p3;
  }


  get errorState(): boolean {
    return this._errorState;
  }
  set errorState(e) {
    if (this._errorState !== e) {
      this._errorState = e;
    }
  }


  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.innerFormGroup.disable() : this.innerFormGroup.enable();
    this.stateChanges.next();
  }
  private _disabled = false;


  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private _placeholder: string;


  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;


  @Input()
  get value(): string | null {
    return (this.innerFormGroup.value.p1 === null ? '' : this.innerFormGroup.value.p1) +
      (this.innerFormGroup.value.p2 === null ? '' : this.innerFormGroup.value.p2) +
      (this.innerFormGroup.value.p3 === null ? '' : this.innerFormGroup.value.p3);
  }
  set value(num: string | null) {
    this._setValue(num);
    this.stateChanges.next();
  }


  @ViewChild('p1') p1Input!: ElementRef<HTMLInputElement>;
  @ViewChild('p2') p2Input!: ElementRef<HTMLInputElement>;
  @ViewChild('p3') p3Input!: ElementRef<HTMLInputElement>;


  constructor(
    private fb: UntypedFormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() public _parentForm: NgForm,
    @Optional() public _parentFormGroup: FormGroupDirective,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl, {});
    this.innerFormGroup = fb.group({
      'p1': [
        null,
        [Validators.minLength(3), Validators.maxLength(3), Validators.required]
      ],
      'p2': [
        null,
        [Validators.minLength(3), Validators.maxLength(3), Validators.required]
      ],
      'p3': [
        null,
        [Validators.minLength(4), Validators.maxLength(4), Validators.required]
      ]
    }, {
      validators: []
    });
    if (this.ngControl) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  get valueDiffers(): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (this.innerFormGroup.value.p1 + this.innerFormGroup.value.p2 + this.innerFormGroup.value.p3) !== this.ngControl.value;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.innerFormGroup.valueChanges.pipe(
      ).subscribe({
        next: (value: { p1: string, p2: string, p3: string }) => {
          const newValue = (value.p1 === null ? '' : value.p1) +
          (value.p2 === null ? '' : value.p2) +
          (value.p3 === null ? '' : value.p3);
          if (newValue !== this.ngControl.value) {
            // Broadcast changes to the parent form group:
            this._onChange(newValue);
          }
        },
        error: err => {
          console.error('ngOnInit', err);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  private _setValue(str: string | null): void {
    if (str === null) {
      this.innerFormGroup.setValue({
        ['p1']: null,
        ['p2']: null,
        ['p3']: null
      });
    } else {
      const _numStr = str.replace(/ /g, ''); // TEMP solution
      if (_numStr.length === 0) {
        this.innerFormGroup.setValue({
          ['p1']: '',
          ['p2']: '',
          ['p3']: ''
        });
      } else {
        if (_numStr.length <= 3) {
          this.innerFormGroup.setValue({
            ['p1']: _numStr,
            ['p2']: '',
            ['p3']: ''
          });
        } else {
          if (_numStr.length <= 6) {
            this.innerFormGroup.setValue({
              ['p1']: _numStr.substr(0, 3),
              ['p2']: _numStr.substr(3, _numStr.length - 3),
              ['p3']: ''
            });
          } else {
            this.innerFormGroup.setValue({
              ['p1']: _numStr.substr(0, 3),
              ['p2']: _numStr.substr(3, 3),
              ['p3']: _numStr.substr(6, _numStr.length - 6)
            });
          }
        }
      }
    }
  }

  setInputs(value: string | null):void{
    this._setValue(value);
    // reset focused field
    this.onContainerClick();
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  writeValue(num: string | null): void {
    // console.log('asd')
    // console.log(num)
    if (typeof num === 'string') {
      this._setValue(num);
    } else {
      this._setValue(num);
    }
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this._onTouched();
      this.stateChanges.next();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onContainerClick(): void {
    if (this.value === null || (typeof this.value === 'string' && this.value.length < 3)) {
      this.p1Input.nativeElement.focus()
    } else if (this.value.length < 6 ) {
      this.p2Input.nativeElement.focus();
    } else if (this.value.length <=10 ) {
      this.p3Input.nativeElement.focus();
    }
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (typeof control.value !== 'string' || control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    this.autoFocusNext(control, nextElement);
  }


  ngDoCheck(): void {
    this.updateErrorState();
  }

}
