import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { SelectOptions } from './select.model';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'acacium-group-ng-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent {
  @Input() entity = 'status';
  @Input() entityLabel = 'Any status';
  @Input() options: SelectOptions[] = [
    { value: '0', viewValue: 'Please configure me' },
  ];
  @Output() selectChanged = new EventEmitter<string[]>();
  @ViewChild(MatSelect) matSelect: any;

  onSelectChange(value: any): void {
    this.selectChanged.emit(value);
  }

  clearAll(): void {
    this.matSelect.options.forEach((option: MatOption) => option.deselect());
  }
}
