import { InputModel } from './input-model';

export class SubsectionModel {
  public label!: string;
  public nextSkipLabelRule?: (values: any[]) => string;
  public controlName!: string;
  public visibilityDependencies?: string[];
  public visibilityRule?: (values: any[]) => boolean;
  public inputs!: InputModel[];
}
