import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MasterDetailComponent } from './master-detail/master-detail.component';
import { AnchorListModule } from "../anchor-list/anchor-list.module";

@NgModule({
  declarations: [
    MasterDetailComponent
  ],
  imports: [
    CommonModule,
    AnchorListModule
  ],
  exports: [
    MasterDetailComponent
  ]
})
export class MasterDetailModule { }
