import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormElement } from '../form-models/form-element.model';
import { ControlType, DraftStatus, FormModel } from '../form-models';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from '../form-services/local-storage.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ComplexArrayInput } from '../form-models/inputs/complex-array-input.model';
import { SimpleArrayInput } from '../form-models/inputs/simple-array-input.model';
import { FormInput } from '../form-models/inputs/form-input';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogConfirmModel } from '../../dialog/models/dialog-confirm.model';
import { DialogConfirmComponent } from '../../dialog/dialog-confirm/dialog-confirm.component';
import { errorSubmitField, errorSubmitTitle } from '@acacium-group-ng/message-constants';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { IReferral } from '@acacium-group-ng/data-definition';
import { ReferralService } from '@acacium-group-ng/referrals/data-access';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-generic-form',
  templateUrl: './generic-form.component.html',
  styleUrls: ['./generic-form.component.scss'],
})
export class GenericFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public formControls!: FormModel;
  @Input() public draftData!: string | IReferral;
  @Input() public errorStartedIndex!: Observable<number>;
  @Input() public triggerExternalSave!: Observable<boolean>;
  @Input() public saveSuccessful!: Observable<boolean>;

  @Input() public returnUrl!: string;
  @Input() public draftCachePath!: string;

  @Output() formSubmit = new EventEmitter<IReferral>();
  @Output() saveDraft = new EventEmitter<IReferral>();

  @ViewChild('matStepper') matStepper!: MatStepper;

  draft: string | null;
  draftStatus: DraftStatus;
  selectedIndex: number;

  allSectionsValid = true;
  isSelectNextClicked = false;
  subscriptions: Subscription = new Subscription();
  public formElement!: FormElement;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalStorageService,
    private apiService: ReferralService,
    private datePipe: DatePipe
  ) {
    this.draft = '';
    this.draftStatus = DraftStatus.Unchanged;
    this.selectedIndex = 0;
    this.initialize();
  }

  private initialize(): void {
    this.apiService.attachments = [];
    this.draft = this.localStorageService.get(this.draftCachePath);
    this.draftStatus = this.draft
      ? DraftStatus.HasDraft
      : DraftStatus.Unchanged;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('draftData' in changes) {
      if (this.formElement) this.setDraftData();
    }
  }

  setDraftData(): void {
    this.formElement.setValue(this.draftData);
  }

  ngOnInit() {
    if (this.formControls) {
      this.formElement = new FormElement(this.formControls);
      this.formElement.formGroup.addControl(
        'referrerName',
        new UntypedFormControl('', [Validators.required])
      );
      if (this.draftData != null && this.draftData) this.setDraftData();
      this.subscriptions.add(this.formElement.formGroup.valueChanges.subscribe(
        () => {
          // The form has changed so values could have been edited and any complex types could have additions or deletions.
          // mark as changed
          this.draftStatus = DraftStatus.Changed;
          this.checkCompletion();
          this.validateAllSections();
        }
      ));
    }
    if (this.errorStartedIndex) {
      this.subscriptions.add(this.errorStartedIndex.subscribe((res) => {
        this.selectedIndex = res;
        if (this.selectedIndex === -1 && this.isSelectNextClicked) {
          this.goToNextPage();
        }
      }));
    }

    if (this.triggerExternalSave) {
      this.subscriptions.add(this.triggerExternalSave.subscribe((action) => {
        if (action === true) this.emitSave();
      }));
    }

    if (this.saveSuccessful) {
      this.subscriptions.add(this.saveSuccessful.subscribe((action) => {
        if (action === true) this.draftStatus = DraftStatus.Saved;
      }));
    }
  }

  goToNextPage(): void {
    this.matStepper.next();
    this.selectedIndex = this.matStepper.selectedIndex;
    return;
  }

  ngOnDestroy() {
    this.formElement.subscriptions.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  // Required for ignoring default update behaviour of material stepper and
  // replacing with a manual updating of validation for custom input components.
  onNavigate(event: MouseEvent): void {
    if (
      event.composedPath().find((e: any) => e.tagName === 'MAT-STEP-HEADER')
    ) {
      if (this.draftStatus == DraftStatus.Changed) {
        this.localStorageService.set(
          this.draftCachePath,
          JSON.stringify(this.formElement.formGroup.value)
        );
        this.emitSave();
      }

      if (!this.validateSection(this.selectedIndex)) {
        // At this point the form has failed validation so we need to mark the form as touched to display the errors
        this.markFormGroupAsTouched(this.selectedIndex);
      }
      this.selectedIndex = this.matStepper.selectedIndex;
      this.validateAllSections();
    }
  }

  selectPrevious(): void {
    this.isSelectNextClicked = false;
    this.selectedIndex = this.matStepper.selectedIndex;
    this.saveDraft.emit({
      ...this.formElement.formGroup.value,
      dateOfBirth: this.datePipe.transform(this.formElement.formGroup.value.dateOfBirth, 'yyyy-LL-dd')
    });
    if (this.matStepper.selectedIndex !== this.formControls.sections.length) {
      this.markFormControlAsTouched();
    }
    this.checkCompletion();
    this.validateAllSections();
    this.matStepper.previous();
  }

  selectNext(): void {
    this.selectedIndex++;
    this.checkCompletion();
    this.draftButtonHandler();
    this.validateAllSections();
    this.markFormControlAsTouched();
    this.isSelectNextClicked = true;
  }

  submit(): void {
    if (this.hasError()) {
      this.showError();
    } else {
      this.formSubmit.emit({
        ...this.formElement.formGroup.value,
        dateOfBirth: this.datePipe.transform(this.formElement.formGroup.value.dateOfBirth, 'yyyy-LL-dd')
      });
    }
  }

  markFormGroupAsTouched(index: number): void {
    // Only mark the current section as touched else the next form in the stepper will display errors
    const controls = this.formElement.sections[index].subsections[0].formInputs;
    // map over the formInputs to get the control name
    controls.map((control) => {
      // Only mark the selected controls in the form group as touched
      // Note: the formElement.formGroup has ALL controls for all forms in the stepper, so we only mark the ones in the current subsection
      this.formElement.formGroup.get(control.fieldName)?.markAllAsTouched();
    });
  }

  markFormControlAsTouched(): void {
    const markAsTouchedIndex = this.selectedIndex > 0 ? this.selectedIndex - 1 : 0;
    const sectionInputs = this.formElement.sections[markAsTouchedIndex].subsections.map((e) => e.formInputs).flat();
    sectionInputs.forEach(input => {
      switch (input.dataModel.type) {
        case ControlType.ComplexArray:
          (input as ComplexArrayInput).items
            .map((e) => e.subsections)
            .flat()
            .map((e) => e.formInputs)
            .flat()
            .forEach((e) => {
              e.formControl.markAsTouched();
              e.formControl.updateValueAndValidity();
            });
          break;
        case ControlType.SimpleArray:
          (input as SimpleArrayInput).items.forEach((e) => {
            e.formControl.markAsTouched();
            e.formControl.updateValueAndValidity();
          });
          break;
        case ControlType.TwoOptions:
          input.formControl.markAsTouched();
          input.formControl.updateValueAndValidity();
          break;
        default:
          input.formControl.updateValueAndValidity();
          break;
      }
    });
  }


  validateAllSections(): void {
    this.formElement.visibleSections.map((section, index) => {
      this.allSectionsValid = this.validateSection(index);
    });
  }

  validateSection(index: number): boolean {
    // For result section case.
    if (this.formElement.sections.length <= index) {
      return true;
    }

    // Only validate visible sections
    const inputs = this.getAllVisibleInputs(index);

    let complexValidState = true; // assume valid until overwritten
    return inputs.every((input: FormInput) => {
      if (input.typeString === 'ComplexArray') {
        // It could be the case that its not got any controls yet (ie Add item not clicked) so check if its got any fields before
        // trying to validate
        if (input.formControl) {
          complexValidState = this.checkForComplexTypes(input.formControl);
        }
      }
      return input.formControl.valid && complexValidState;
    });
  }

  public getAllVisibleInputs(index: number) {
    return this.formElement.visibleSections
      .slice(0, index + 1)
      .map((e) => e.subsections.map((s) => s.formInputs).flat())
      .flat();
  }

  checkForComplexTypes(input: any): boolean {
    // If there are no entries in this complex type it means its been added but nothing filled in yet so assume its valid
    if (!(input.controls.length > 0)) {
      return true;
    }

    return input.controls.every((control: any) => {
      return control.valid;
    });
  }

  checkCompletion(): void {
    for (let i = 0; i < this.formElement.sections.length; i++) {
      const step = this.matStepper.steps.get(i);
      if (step) step.completed = !this.anyInvalidRequiredDraftFields(i);
    }
  }

  get allAttachmentsUploaded(): BehaviorSubject<boolean> {
    return this.apiService.allAttachmentsUploaded;
  }

  checkSectionsForRequiredDraftFields(): boolean {
    return this.formElement.visibleSections.some((section, index) => {
      return this.anyInvalidRequiredDraftFields(index);
    });
  }

  anyInvalidRequiredDraftFields(index: number): boolean {
    // Only validate visible sections
    const inputs = this.getAllVisibleInputs(index);

    return inputs.some((input) => {
      if (input.dataModel.isRequiredDraft) {
        if (input.formControl.status === 'INVALID') {
          return true;
        }
      }
      return false;
    });
  }

  showError(): void {
    const errorDialog = new DialogConfirmModel({
      title: errorSubmitTitle,
      hasNoButton: false,
      buttonOk: 'Close',
      buttonNo: '',
      message: errorSubmitField,
      isError: true,
    });
    this.dialog.open(DialogConfirmComponent, {
      autoFocus: false,
      data: errorDialog,
    });
  }

  draftButtonHandler(): void {
    if (this.checkSectionsForRequiredDraftFields()) {
      // There are required draft fields that are invalid. So behave as if form has changed. Component running the form will display any errors
      this.draftStatus = DraftStatus.Changed;
    }

    switch (this.draftStatus) {
      case DraftStatus.Unchanged:
        this.goToNextPage();
        break;
      case DraftStatus.Changed:
        this.localStorageService.set(
          this.draftCachePath,
          JSON.stringify(this.formElement.formGroup.value)
        );
        this.emitSave();
        break;
      case DraftStatus.HasDraft:
        this.formElement.setValue(this.draft ? JSON.parse(this.draft) : null);
        this.draftStatus = DraftStatus.Changed;
        this.localStorageService.remove(this.draftCachePath);
        break;
      case DraftStatus.Saved:
        this.goToNextPage();
        break;
    }
  }

  public hasError(): boolean {
    if (!this.allSectionsValid) return true;
    if (this.selectedIndex === this.formElement.visibleSections.length) {
      return this.formElement.formGroup.controls['referrerName'].hasError(
        'required'
      );
    }
    return false;
  }

  emitSave(): void {
    this.saveDraft.emit(
      {
        ...this.formElement.formGroup.value,
        dateOfBirth: this.datePipe.transform(this.formElement.formGroup.value.dateOfBirth, 'yyyy-LL-dd')
      });
    this.draftStatus = DraftStatus.Saved;
  }
}
