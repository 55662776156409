import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { LoginButtonComponent } from './login-button/login-button.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { LogoutButtonComponent } from './logout-button/logout-button.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogModule, ProfileModule } from '@acacium-group-ng/ui-components';

@NgModule({
  declarations: [
    LoginButtonComponent,
    NavBarComponent,
    LogoutButtonComponent,
    NotFoundComponent
  ],
  imports: [
    CommonModule,
    DialogModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    ProfileModule,
  ],
  exports: [
    NavBarComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class NavComponentModule { }
