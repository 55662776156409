<div *ngIf="(isForMenu) === false">
    <button [attr.data-cy]="elementId" mat-button (click)="loginWithRedirect()">
        <mat-icon class="mr">face</mat-icon>
        <span>Log in</span>
    </button>
</div>
<div *ngIf="(isForMenu)">
    <button [attr.data-cy]="elementId" mat-menu-item (click)="loginWithRedirect()">
        <mat-icon class="mr">face</mat-icon>
        <span>Log in</span>
    </button>
</div>
