/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/component-selector */
import { IProfileAvatarItem } from '@acacium-group-ng/ui-components';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment as env } from '../../../../environments/environment';
import { UserIdentityHelperService } from '../../services/user-identity/user-identity-helper.service';
import { AuthService } from '@auth0/auth0-angular';
import { Subject, takeUntil } from 'rxjs';
import { IAuthUserAccess, IAuthUserIdentity, INavItem } from "@acacium-group-ng/data-definition";


@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, OnDestroy {
  public maxLength = env.settings.maxProfileHeaderLineLength || 10;
  public authIdentity: IAuthUserIdentity = { trust: {}, access: {}, user: { nickname: 'Bob' } };
  public profileAvatarItem: IProfileAvatarItem = { name: '', trust: '' };
  public logoPath: string = env.assets.logoPath;
  public tag = '';
  public navBarItems: INavItem[] = []
  public access: IAuthUserAccess = {};
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private featureFlags: { [key: string]: boolean } = env.featureFlags

  constructor(public auth: AuthService) {
    if (env.name !== 'production') {
      this.tag = `${env.name}/${env.version}`;
    }
    this.navBarItems = [
      {
        label: 'Home',
        data_cy: 'dashboard',
        show: this.featureFlags['dashboard'],
        routerLink: '/home',
        routerLinkActive: 'active',
        showOnMobile: false,
        showOnTablet: true,
        showOnDesktop: true,
        isAuthenticated: true
      },
    ];
  }

  ngOnInit(): void {
    this.auth.idTokenClaims$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
        if (data) {
          this.profileAvatarItem = UserIdentityHelperService.getProfileAvatar(data);
          const identity = UserIdentityHelperService.getIdentity(data);
          this.access = identity.access;
          // ToDo: uncomment when pathways will become part of the project
          // if (this.access.patients && !this.navBarItems.some(x => x.label === 'Pathways')) {
          //   this.navBarItems.splice(2, 0, {
          //     label: 'Pathways',
          //     data_cy: 'pathways',
          //     show: this.featureFlags['pathways'],
          //     routerLink: '/pathways',
          //     routerLinkActive: 'active',
          //     showOnMobile: false,
          //     showOnTablet: false,
          //     showOnDesktop: true,
          //     isAuthenticated: true,
          //     cssClass: 'lighter'
          //   });
          // };
          if (this.access.referrals && !this.navBarItems.some(x => x.label === 'Referrals')) {
            this.navBarItems.splice(2, 0, {
              label: 'Referrals',
              data_cy: 'referrals',
              show: this.featureFlags['referrals'],
              routerLink: '/referrals',
              routerLinkActive: 'active',
              showOnMobile: false,
              showOnTablet: false,
              showOnDesktop: true,
              isAuthenticated: true,
              cssClass: 'lighter'
            });
          }
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
