import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { DragDropUploadDirective } from "./file-upload-directives/drag-drop-upload.directive";
import { DragDropUploadComponent } from "./file-upload-components/drag-drop-upload/drag-drop-upload.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { LoaderModule } from "../loader/loader.module";
import { PipesModule } from "../../pipes/pipes.module";
import { FileItemComponent } from "./file-upload-components/file-item/file-item.component";
import { FilesListComponent } from "./file-upload-components/files-list/files-list.component";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { azureBlobStorageFactory, BLOB_STORAGE_TOKEN } from "./file-upload-services/token";
import { AzureBlobStorageService } from "./file-upload-services/azure-blob-storage.service";

@NgModule({
  declarations: [ DragDropUploadDirective, DragDropUploadComponent, FileItemComponent, FilesListComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressBarModule,
    LoaderModule,
    PipesModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    DragDropUploadComponent
  ],
  providers: [
    {
      provide: BLOB_STORAGE_TOKEN,
      useFactory: azureBlobStorageFactory
    },
    AzureBlobStorageService
  ]
})
export class FileUploadModule { }
