<div class="form-input-complex">
  <div class="table-wrapper">
    <table
      [attr.data-cy]="arrayInput.fullControlName + '--view'"
      *ngIf="arrayInput.items.length > 0 && !isNewItem && !viewMode"
    >
      <tr [attr.data-cy]="arrayInput.fullControlName + '--headers'">
        <th
          [attr.data-cy]="arrayInput.fullControlName + '--header--index'"
          [width]="arrayInput.columnDefinitions[0]"
          class="mat-header-cell table-header "
        >
          №
        </th>
        <th
          *ngFor="let column of arrayInput.columns; index as i"
          [attr.data-cy]="
            arrayInput.fullControlName + '--header--' + column.controlName
          "
          class="mat-header-cell table-header"
        >
          {{ column.label }}
        </th>
      </tr>
      <tr
        *ngIf="arrayInput.items.length === 0"
        [attr.data-cy]="arrayInput.fullControlName + '--row-empty'"
        class="data-rows"
      >
        <td
          [attr.data-cy]="arrayInput.fullControlName + '--row-empty--index'"
          class="mat-cell"
        >
          <em>0</em>
        </td>
        <td
          *ngFor="let column of arrayInput.columns"
          [attr.data-cy]="
            arrayInput.fullControlName + '--row-empty--' + column.controlName
          "
          class="mat-cell"
        >
          <em>(Empty)</em>
        </td>
      </tr>
      <tr
        *ngFor="let item of arrayInput.items; index as i"
        [attr.data-cy]="arrayInput.fullControlName + '--row-' + i"
        class="{{ selectedItemIndex === i ? 'selected' : 'data-rows' }}"
        (click)="selectItem(i)"
        tabindex="0" role="button"
        (keypress)="onKeyPressEvent(i)"
      >
        <td
          [attr.data-cy]="arrayInput.fullControlName + '--row-' + i + '--index'"
          class="mat-cell"
        >
          {{ i + 1 }}
        </td>
        <td
          *ngFor="let column of arrayInput.columns"
          [attr.data-cy]="
            arrayInput.fullControlName +
            '--row-' +
            i +
            '--' +
            column.controlName
          "
          class="mat-cell"
        >
          {{
            column.displayRule
              ? column.displayRule(
                  arrayInput.getFormControl(i, column.bindingPath)?.value
                )
              : arrayInput.getFormControl(i, column.bindingPath)?.value
          }}
        </td>
      </tr>
    </table>
  </div>

  <ng-container *ngIf="viewMode">
    <ng-container *ngFor="let item of arrayInput.items; index as i">
      <h3 class="header"><p class="text">Relationship {{ i + 1 }} </p> <span class="spacer"></span></h3>
      <ng-container *ngFor="let formInput of item.subsections[0].formInputs">
        <app-form-input
          *ngIf="formInput.isDisplayed"
          [attr.data-cy]="formInput.fullControlName + '--form-input'"
          [formInput]="formInput"
          [id]="formInput.fullControlName"
          [viewMode]="viewMode"
          [isEditable]="isEditable"
        ></app-form-input>
      </ng-container>
    </ng-container>
  </ng-container>

  <div>
    <ng-template [ngIf]="isEditMode || (viewMode && selectedItemIndex !== -1)">
      <ng-template ngFor let-subsection [ngForOf]="selectedItem.subsections">
        <app-form-inputs-set
          class="form-input-set"
          [attr.data-cy]="subsection.fullControlName + '--inputs-set'"
          [subsection]="subsection"
          [isEditable]="isEditable"
        ></app-form-inputs-set>
      </ng-template>
    </ng-template>
    <div>
      <button
        *ngIf="selectedItemIndex !== -1 && isEditMode && !viewMode"
        [attr.data-cy]="arrayInput.fullControlName + '--cancel-button'"
        (click)="cancel()"
        mat-stroked-button
      >
        <span>{{ arrayInput.dataModel.cancelButtonLabel }}</span>
      </button>
      <button
        *ngIf="!isEditMode && !viewMode"
        [attr.data-cy]="arrayInput.fullControlName + '--add-button'"
        (click)="addItem()"
        mat-stroked-button
      >
        <mat-icon svgIcon="plus-solid" class="icon"></mat-icon>
        <span>{{ arrayInput.dataModel.addButtonLabel }}</span>
      </button>
      <button
        *ngIf="selectedItemIndex !== -1 && !isEditMode && !viewMode"
        [attr.data-cy]="arrayInput.fullControlName + '--edit-button'"
        (click)="editItem()"
        mat-stroked-button
      >
        <mat-icon svgIcon="edit-solid" class="icon"></mat-icon>
        <span>{{ arrayInput.dataModel.editButtonLabel }}</span>
      </button>
      <button
        *ngIf="selectedItemIndex !== -1 && !isEditMode && !viewMode"
        [attr.data-cy]="arrayInput.fullControlName + '--remove-button'"
        (click)="removeItem('relationship')"
        mat-stroked-button
      >
        <mat-icon svgIcon="cross" class="icon"></mat-icon>
        <span>{{ arrayInput.dataModel.removeButtonLabel }}</span>
      </button>
<!--  Save button removed for MVP as its not actually required and its confusing for the user -->
<!--      <button-->
<!--        *ngIf="selectedItemIndex !== -1 && isEditMode && !viewMode"-->
<!--        [attr.data-cy]="arrayInput.fullControlName + '&#45;&#45;save-button'"-->
<!--        (click)="saveItem()"-->
<!--        mat-stroked-button-->
<!--        fxLayout="row"-->
<!--        fxLayoutAlign="start stretch"-->
<!--      >-->
<!--        <mat-icon svgIcon="save" class="icon"></mat-icon>-->
<!--        <span>{{ arrayInput.dataModel.saveButtonLabel }}</span>-->
<!--      </button>-->
    </div>
  </div>
</div>
