import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnchorListComponent } from './anchor-list/anchor-list.component';
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [
    AnchorListComponent
  ],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [
    AnchorListComponent
  ]
})
export class AnchorListModule { }
