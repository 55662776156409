<div class="outer-wrapper">
  <!-- header -->
  <div class="header" *ngIf="auth.isAuthenticated$ | async">
    <app-nav-bar></app-nav-bar>
  </div>
  <!--<div class="container" [@SlideInAnimation]="o.isActivated ? o.activatedRoute : ''">
    <router-outlet #o="outlet"></router-outlet>
  </div>-->
  <!-- main content -->
  <div class="outer-trough">
    <div class="main-body">
      <div class="main-body__inner">
          <!-- spinner -->
        <div class="outer-spinner">
          <!--<mat-spinner *ngIf="authLoading || navLoading"></mat-spinner>-->
        </div>
        <div class="main-body__inner__router">
          <router-outlet></router-outlet>
        </div>
        <div *ngIf="auth.isAuthenticated$ | async">
          <acacium-group-ng-footer class="footer" [footerDef]='footerDef'></acacium-group-ng-footer>
        </div>
      </div>
    </div>
  </div>
</div>
