import { ControlType } from '../enums/control-type.enum';
import { SubsectionModel } from './subsection-model';
import { EventTrigger } from '../enums/event-trigger.enum';

export class InputModel {
  public type!: ControlType;
  public label!: string;
  public controlName!: string;
  public bindingPath!: string;
  public updateOn!: EventTrigger;
  public defaultValue?: any;
  public description?: string;
  public placeholder?: string;
  public options?: { label: string, value: any }[];
  public arrayViewColumns?: string[];
  public columnDefinitions?: string[];
  public isRequired?: boolean;
  public isOptionallyRequired?: boolean;
  public isRequiredErrorMessage?: string;
  public isRequiredDraft?: boolean;     // Field is a required field for a draft create/save.
  public isReadOnly?: boolean;
  public minLength?: number;
  public minLengthErrorMessage?: string;
  public maxLength?: number;
  public maxLengthErrorMessage?: string;
  public regexExpression?: RegExp;
  public regexExpressionErrorMessage?: string;
  public isCapitalized?: boolean;
  public visibilityDependencies?: string[];
  public visibilityRule?: (values: any[]) => boolean;
  public validationDependencies?: string[];
  public validationRule?: (value: any, otherValues: any[]) => string | null;
  // If provided - will throw validation error if value of FormControl with
  // bindingPath same as name property do not match value of the
  // own FormControl
  public validateAgainstField?: {
    // bindingPath of the object to validate against
    name: string,
    // Error message that will be displayed if values of the field do not match
    errorMessage: string
  };
  public addButtonLabel?: string;
  public editButtonLabel?: string;
  public removeButtonLabel?: string;
  public cancelButtonLabel?: string;
  public saveButtonLabel?: string;
  public labelDependencies?: string[];
  public labelRule?: (values: any[]) => string;
  public formTemplate?: SubsectionModel[];
  public inputTemplate?: InputModel;
  public displayRule?: (value: any) => string;
  public fileUploadOptions?: { baseUrl: string, blobStoragePath: string, allowedExtensions: string[], maxSize: number };
}
