import { Component, Input, OnInit } from '@angular/core';
import { FormInput } from '../form-models/inputs/form-input';
import { FieldInput } from '../form-models/inputs/field-input';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-form-input-two-options',
  templateUrl: './form-input-two-options.component.html',
  styleUrls: ['./form-input-two-options.component.scss']
})
export class FormInputTwoOptionsComponent implements OnInit {
  @Input() formInput!: FormInput;
  @Input() viewMode!: boolean;
  @Input() isEditable!: boolean;

  fieldInput!: FieldInput;

  ngOnInit(): void {
    this.fieldInput = this.formInput as FieldInput;
  }

  public get selectedOption(): string {
    const selectedOption = this.fieldInput.dataModel.options?.find(e => e.value == this.fieldInput.formControl.value);
    return selectedOption ? selectedOption.label : "Incomplete";
  }
}
