import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'acacium-group-ng-form-view-card',
  templateUrl: './form-view-card.component.html',
  styleUrls: ['./form-view-card.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class FormViewCardComponent implements OnInit {

  @Input() public safeContent: string | null =  '';
  content = '';

  ngOnInit(): void {
    this.content =this.safeContent || '';
  }
}
