<div class="detail-container">
  <div class="list-section">
    <ul>
      <li
        *ngFor="let detail of displayList"
        (click)="selectDetail(detail)"
        [class.active]="detail.active"
      >
        {{ detail.linkTitle }}
      </li>
    </ul>
  </div>
  <div class="detail-section">
    <ng-container *ngIf="detailType === detailEnum.QuestionAnswer">
      <acacium-group-ng-anchor-list [questionAnswers]= "currentDetail" [title]="currentTitle"></acacium-group-ng-anchor-list>
    </ng-container>
    <ng-container *ngIf="detailType === detailEnum.PathwayAppointment">
      <!--  todo - implement Pathway appointment here-->
      <h1>Appointment Component will go here</h1>
      <pre>
          {{ jsonAsString(currentDetail) }}
        </pre
      >
    </ng-container>
    <ng-container *ngIf="detailType === detailEnum.NotKnown"> </ng-container>
  </div>
</div>
