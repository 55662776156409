/* eslint-disable @typescript-eslint/no-explicit-any */
/// Service to upload and delete blobs from Azure Storage
/// The services makes use of a SAS token retreived from
/// an accociated Azure Function, and then passed into a
/// function here.
///
/// Example of IAzureBlobStorageToken:
/// {"token":"?sv=2018-03-28&sr=c&sig=t9ImUDO3wlPnGIjOqHx0nUZrp
/// %2B88ChtpUVddeMtmB9A%3D&st=2022-02-25T17%3A06%3A29Z&se=2022-
/// 02-25T17%3A26%3A29Z&sp=c",
/// "baseUri":"https://storecypdtassetsnp.blob.core.windows.net/",
/// "container":"file-uploads",
/// "uri":"https://storecypdtassetsnp.blob.core.windows.net/file-uploads?
/// sv=2018-03-28&sr=c&sig=t9ImUDO3wlPnGIjOqHx0nUZrp%2B88ChtpUVddeMtmB9A%
/// 3D&st=2022-02-25T17%3A06%3A29Z&se=2022-02-25T17%3A26%3A29Z&sp=c"}
///
/// tokens are valid for 15 mins as set in the Function
///
import { Inject, Injectable } from '@angular/core';
import { BlobServiceClient, BlockBlobUploadHeaders, ContainerClient } from '@azure/storage-blob';
import { IAzureBlobStorageToken } from '../file-upload-models/azure-blob-storage-token.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
// noinspection ES6PreferShortImport
import { DialogConfirmComponent } from '../../dialog/dialog-confirm/dialog-confirm.component'
// noinspection ES6PreferShortImport
import { DialogConfirmModel } from '../../dialog/models/dialog-confirm.model'
import { BLOB_STORAGE_TOKEN } from './token';
import { BlobStorageClientFactory, BlobStorageRequest } from '../file-upload-types/azure-storage';
import { catchError, defer, EMPTY, from, Observable, switchMap, tap, throwError, timer } from 'rxjs';
import { delayWhen, retryWhen } from 'rxjs/operators';
import { IUploadItem } from "@acacium-group-ng/data-definition";

@Injectable({
  providedIn: 'root'
})
export class AzureBlobStorageService {
  public uploadValidationFailureMessage = { title: 'Upload Validation Failed', message: '', hasNoButton: false, buttonOk: 'Okay', isError: true};

  constructor(
    @Inject(BLOB_STORAGE_TOKEN)
    private getBlobClient: BlobStorageClientFactory,
    private http: HttpClient,
    public dialog: MatDialog
  ) {}

  private buildClient(options: BlobStorageRequest): BlobServiceClient {
    return this.getBlobClient(options);
  }

  // FIXME: old code v

  getUploadToken(url: string) {
    return this.http.get<IAzureBlobStorageToken>(url).pipe();
  }

  /// Upload file(s) into blob storage
  upload(uploadName:string, file: IUploadItem, token: IAzureBlobStorageToken): Observable<BlockBlobUploadHeaders> {
    const blockBlobClient = this.containerClient(token).getBlockBlobClient(uploadName);
    return from(blockBlobClient.uploadData(file.item, { blobHTTPHeaders: { blobContentType: file.item.type } })).pipe(
      catchError((error: HttpErrorResponse) => {
        this.confirmDialog('File uploading is currently not available. Please contact us.');
        return EMPTY;
      })
    );
  }

  /// Delete file from blob storage - not using batch delete - one token
  /// per file to delete
  delete(filename: string, token: IAzureBlobStorageToken, handler: () => void) {
    defer(() => from(this.containerClient(token).deleteBlob(filename))).pipe(
      retryWhen(delayWhen((err, index) => index < 6 ? timer(500) : throwError(err))),
    ).subscribe({
      next: () => handler(),
      error: () =>this.confirmDialog('Attachment not deleted','Cancel','The attachment was not deleted. Please try again. If you\'re still unable to to delete it, please contact cypadmin@xyladigitaltherapies.com for more help.'),
    })
  }

  // Get a reference to the storage container
  private containerClient(token: IAzureBlobStorageToken): ContainerClient {
    return this.buildClient({
      storageUri: token.baseUri,
      storageAccessToken: token.token
    }).getContainerClient(token.container);
  }

  // display validation errors to the user
  private confirmDialog(title?: string, buttonCopy? : string, message: string = ''): void {
    this.uploadValidationFailureMessage = {
      ...this.uploadValidationFailureMessage,
      message: message,
      buttonOk: buttonCopy? buttonCopy : this.uploadValidationFailureMessage.buttonOk,
      title: title? title: this.uploadValidationFailureMessage.title
    };
    const data = new DialogConfirmModel(this.uploadValidationFailureMessage);
    this.dialog.open(DialogConfirmComponent, {
      autoFocus: false,
      disableClose: true,
      data: data,
    });
  }
}

/*
const formData = new FormData();
    Array.from(files).forEach((item: File) => {
      formData.append('fileUploads', item);
    });

    //console.log(`form data: ${formData}`);

    return this.http.post(url, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe()
*/
//return this.http.get(`${ url }/${id}`).pipe();//retry(2));
