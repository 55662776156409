<div  class="form-input">
  <div class="input-container">
    <label for="{{formInput.fullControlName}}--{{componentNumber}}">{{formInput.dataModel.label + (formInput.dataModel.isRequired ? '*' : '')}}</label>
    <mat-form-field appearance="outline" color="primary" >
      <acacium-group-ng-form-input-nhs-field
        minlength="10"
        inputId="{{formInput.fullControlName}}--{{componentNumber}}"
        [errorStateMatcher]="matcher"
        [formControl]="childControl"></acacium-group-ng-form-input-nhs-field>
      <mat-hint align="end">{{formInput.dataModel.description}}</mat-hint>
      <mat-error
        class="mat-small error"
        *ngIf='formInput.errorMessage'>{{formInput.errorMessage}}
      </mat-error>
    </mat-form-field>
  </div>
</div>


