import { Subdomain_Organisation_Map_Helper } from './subdomain-organization-map';
import { IAppConfig } from '@acacium-group-ng/data-definition';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: IAppConfig = {
  name: "development",
  version: "0.3.0",
  production: false,
  auth: {
    cacheLocation: 'localstorage',
    domain: 'auth-dev.acaciumgroup.com',
    clientId: '1xWl5KLrH1awY2Uv8f6PdeothOI3zphW',
    redirectUri: window.location.origin,
    audience: 'CYPDTAPI',
    scope: 'openid profile email',
    httpInterceptor: { allowedList: [] },
    ...Subdomain_Organisation_Map_Helper.getSubdomain()
  },
  servers: {
    fakeDataServiceUrl: 'https://fake-data.azurewebsites.net',
    cypdtServicesUrl: 'https://cypdt-dev-portal-api-app-service.azurewebsites.net/api/v1',
    fakeUploadServiceUrl: 'https://fake-upload-server.azurewebsites.net/api',
    cypdtFormSASFunctionUrl: 'https://fn-cypdt-sas-key-dev.azurewebsites.net/api',
    referralStorageAccountPath: 'https://fn-cypdt-autodownload-dev.azurewebsites.net/api/download?filename='
  },
  assets: {
    logoPath: 'https://stcypdtppdev.blob.core.windows.net/assets/Logo-Xyla-Landscape-White.png'
  },
  featureFlags: {
    dashboard: true,
    referrals: true,
    pathways: true
  },
  settings: {
    maxProfileHeaderLineLength: 10,
    uploadSingleFileMaxSizeKB: 5120,
    uploadFileAllowedExts: ['.pdf', '.doc', '.docx']
  },
  idleTimeout: { idle: 840, timeout: 61, ping: 600 },
  jwt: {
    storageKey: 'cypdt-acacium-token-dev',
    auth_grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
    auth_endpoint: '/oauth2/token/for-user/from-openid-connect'
  },
  bugsnag: {
    apiKey: 'bba291253b6e79157eab4e07dfee57c6'
  },
  appInsights: {
    instrumentationKey: '1b47061d-0ec9-4e31-a80b-c4b60cccd843'
  },
  logging: {
    console: true,
    appInsights: true,
    bugsnag: true,
    traceEnabled: true
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
