import { Component } from '@angular/core';

@Component({
  selector: 'acacium-group-ng-form-validation-error',
  templateUrl: './form-validation-error.component.html',
  styleUrls: ['./form-validation-error.component.scss']
})
export class FormValidationErrorComponent {

  constructor() { }
}
