<div class="footer-container" >
  <div class="base-container">
    <div  class="footer-links">
      <div class="text-middle {{footerDef.address.class}}">{{footerDef.address.text}}</div>
      <div class="vl">&nbsp;</div>
      <div class="text-middle"><a href="mailto:{{footerDef.email.text}}" class="{{footerDef.email.class}}">{{footerDef.email.text}}</a></div>
      <div class="vl">&nbsp;</div>
      <div class="text-middle"><a href="tel:{{footerDef.phone.text}}" class="{{footerDef.phone.class}}">{{footerDef.phone.text}}</a></div>
      <div class="vl">&nbsp;</div>
      <div class="text-middle">
        <div *ngFor="let item of footerDef.socialLinks" class="links-sub-container">
            <span><a href="{{item.url}}" target="{{item.target}}"><mat-icon svgIcon="{{item.icon}}" class="{{item.class}}"></mat-icon></a></span>
        </div>
      </div>
    </div>
    <div>
      <div></div>
      <div class="footer-text">
        <div *ngFor="let item of footerDef.baseTexts">
          <div class="{{ item.class }}">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <div class="footer-text footer-text-bottom">
      <div class="{{footerDef.cqcNumber.class}}">{{footerDef.cqcNumber.text}}</div>
      <div class="vl-solid" >&nbsp;&nbsp;</div>
      <div><a href="{{footerDef.privacyPolicyLink.url}}" target="{{footerDef.privacyPolicyLink.target}}" class="{{footerDef.privacyPolicyLink.class}}">{{footerDef.privacyPolicyLink.linkText}}</a></div>
      <div class="vl-solid" >&nbsp;</div>
      <div><a href="{{footerDef.accessibilityLink.url}}" target="{{footerDef.accessibilityLink.target}}" class="{{footerDef.accessibilityLink.class}}">{{footerDef.accessibilityLink.linkText}}</a></div>
      <div class="vl-solid" >&nbsp;</div>
      <div><a href="{{footerDef.serviceAccessLink.url}}" target="{{footerDef.serviceAccessLink.target}}" class="{{footerDef.serviceAccessLink.class}}">{{footerDef.serviceAccessLink.linkText}}</a></div>
    </div>
  </div>
</div>
