import { Component, Input } from '@angular/core';
import { Subsection } from '../form-models/subsection';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-form-inputs-set',
  templateUrl: './form-inputs-set.component.html',
  styleUrls: ['./form-inputs-set.component.scss'],
})
export class FormInputsSetComponent {
  @Input() subsection!: Subsection;
  @Input() viewMode!: boolean;
  @Input() isEditable!: boolean;
}
