<div [ngSwitch]="formInput.typeString">
  <app-form-input-string
    *ngSwitchCase="'String'"
    [attr.data-cy]='formInput.fullControlName + "--string-input"'
    [formInput]='formInput'
    [viewMode]="viewMode"
    [isEditable]="isEditable"
  ></app-form-input-string>
  <app-form-input-text
    *ngSwitchCase="'Text'"
    [attr.data-cy]='formInput.fullControlName + "--text-input"'
    [formInput]='formInput'
    [viewMode]="viewMode"
    [isEditable]="isEditable"
  ></app-form-input-text>
  <app-form-input-boolean
    *ngSwitchCase="'Boolean'"
    [attr.data-cy]='formInput.fullControlName + "--boolean-input"'
    [formInput]='formInput'
    [viewMode]="viewMode"
    [isEditable]="isEditable"
  ></app-form-input-boolean>
  <app-form-input-number
    *ngSwitchCase="'Number'"
    [attr.data-cy]='formInput.fullControlName + "--number-input"'
    [formInput]='formInput'
    [viewMode]='viewMode'
    [isEditable]="isEditable"
  ></app-form-input-number>
  <app-form-input-date
    *ngSwitchCase="'Date'"
    [attr.data-cy]='formInput.fullControlName + "--date-input"'
    [formInput]='formInput'
    [viewMode]="viewMode"
    [isEditable]="isEditable"
  ></app-form-input-date>
  <app-form-input-dropdown
    *ngSwitchCase="'Dropdown'"
    [attr.data-cy]='formInput.fullControlName + "--dropdown-input"'
    [formInput]='formInput'
    [viewMode]="viewMode"
    [isEditable]="isEditable"
  ></app-form-input-dropdown>
  <app-form-input-simple-array
    *ngSwitchCase="'SimpleArray'"
    [attr.data-cy]='formInput.fullControlName + "--simple-array-input"'
    [formInput]='formInput'
    [viewMode]="viewMode"
  ></app-form-input-simple-array>
  <app-form-input-complex-array
    *ngSwitchCase="'ComplexArray'"
    #appFormInputComplexArray
    [attr.data-cy]='formInput.fullControlName + "--complex-array-input"'
    [formInput]='formInput'
    [viewMode]="viewMode"
    [isEditable]="isEditable"
  ></app-form-input-complex-array>
  <app-form-input-two-options
    *ngSwitchCase="'TwoOptions'"
    [attr.data-cy]='formInput.fullControlName + "--two-options-input"'
    [formInput]='formInput'
    [viewMode]='viewMode'
    [isEditable]="isEditable"
  ></app-form-input-two-options>
  <app-form-input-files-upload
    *ngSwitchCase="'Files'"
    [attr.data-cy]='formInput.fullControlName + "--files-upload-input"'
    [formInput]='formInput'
    [viewMode]='viewMode'
    [isEditable]="isEditable"
  ></app-form-input-files-upload>
  <ng-container *ngSwitchCase="'NhsNumber'">
    <acacium-group-ng-form-input-nhs-number
      *ngIf="!viewMode"
      [formControl]="formInput.formControl"
      [attr.data-cy]='formInput.fullControlName + "--files-upload-input"'
      [formInput]='formInput'
      [isEditable]="isEditable"
    ></acacium-group-ng-form-input-nhs-number>
    <acacium-group-ng-form-input-nhs-number-display-only
      *ngIf="viewMode"
      [attr.data-cy]='formInput.fullControlName + "--files-upload-input"'
      [formInput]='formInput'
      [isEditable]="isEditable">
    </acacium-group-ng-form-input-nhs-number-display-only>
  </ng-container>

</div>
