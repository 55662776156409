/* eslint-disable @angular-eslint/component-selector */
import { DialogConfirmComponent, DialogConfirmModel } from '@acacium-group-ng/ui-components';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@auth0/auth0-angular';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-auth-login-button',
  templateUrl: './login-button.component.html',
  styles: []
})
export class LoginButtonComponent implements OnInit, OnDestroy {

  @Input() public isForMenu = false;
  @Input() public elementId = '';
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public options = { title: 'Authentication Failed', message: '', hasNoButton: false};

  constructor(private auth: AuthService, public dialog: MatDialog) {  }

  ngOnInit(): void {
    this.auth.error$.pipe(takeUntil(this.destroy$)).subscribe(data =>
    {
      this.options.message = data.message;
      this.confirmDialog();
    });
  }

  loginWithRedirect(): void {
    this.auth.loginWithRedirect();
  }

  public confirmDialog() {
    const data = new DialogConfirmModel(this.options);

    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      disableClose: true,
      data: data,

    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.auth.logout({ returnTo: `${window.location.origin}/#/auth` });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
