import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { JwtAuthService } from '../features/jwt-auth/services/jwt-auth.service';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { IAuthUserIdentity } from "@acacium-group-ng/data-definition";


/*
  Auth guard - secure request by requiring both a valid Auth0 and service layer token.
*/
@Injectable({
  providedIn: 'root'
})
export class JwtAuthGuard implements CanActivateChild {

  constructor(
    private auth: AuthService,
    private jwtService: JwtAuthService,
    private router: Router
  ) {
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.jwtService.ready$.pipe(
      filter(ready => ready),
      switchMap(() => {
        return this.jwtService.userIdentity$.pipe(
          map((authUserIdentity: IAuthUserIdentity) => {
            switch (true) {
              // allow access to referrals if it is permitted by token
              case state.url.startsWith('/referrals'):
                return !!authUserIdentity.access.referrals;
              case state.url.startsWith('/home'):
                // ToDo add home page to list of permitted routes on auth0
                // allow access to home page
                return true;
              default:
                return this.router.parseUrl('/auth');
            }
          }),
          take(1),
        )
      })
    );
  }
}
