import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FormInputComponent } from "./form-input/form-input.component";
import { GenericFormComponent } from "./generic-form/generic-form.component";
import { MatTabsModule } from '@angular/material/tabs';
import { FormInputStringComponent } from './form-input-string/form-input-string.component';
import { FormInputTextComponent } from './form-input-text/form-input-text.component';
import { FormInputBooleanComponent } from './form-input-boolean/form-input-boolean.component';
import { FormInputDateComponent } from './form-input-date/form-input-date.component';
import { FormInputDropdownComponent } from './form-input-dropdown/form-input-dropdown.component';
import { FormInputLabelComponent } from './form-input-label/form-input-label.component';
import { FormResultViewComponent } from './form-result-view/form-result-view.component';
import { RouterModule } from '@angular/router';
import { FormInputSimpleArrayComponent } from './form-input-simple-array/form-input-simple-array.component';
import { FormInputComplexArrayComponent } from './form-input-complex-array/form-input-complex-array.component';
import { FormInputsSetComponent } from './form-inputs-set/form-inputs-set.component';
import { FormInputNumberComponent } from './form-input-number/form-input-number.component';
import { FormInputTwoOptionsComponent } from './form-input-two-options/form-input-two-options.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { PopUpMessageComponent } from './pop-up-message/pop-up-message.component';
import { FormInputFilesUploadComponent } from './form-input-files-upload/form-input-files-upload.component';
import { ComponentsModule } from '../../components/components.module';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { PopUpConfirmComponent } from './pop-up-confirm/pop-up-confirm.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormValidationErrorComponent } from './form-validation-error/form-validation-error.component';
import { FormErrorIndicatorComponent } from './form-error-indicator/form-error-indicator.component';
import { FormViewCardComponent } from './form-view-card/form-view-card.component';
import { FormInputNhsNumberComponent } from './form-input-nhs-number/form-input-nhs-number.component';
import { FormInputNhsNumberDisplayOnlyComponent } from './form-input-nhs-number-display-only/form-input-nhs-number-display-only.component';
import { DirectivesModule } from '../../directives/directives.module';
import { FormInputNhsFieldComponent } from './form-input-nhs-field/form-input-nhs-field.component';
import { PipesModule } from '../../pipes/pipes.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';

@NgModule({
  declarations: [
    GenericFormComponent,
    FormInputComponent,
    FormInputStringComponent,
    FormInputTextComponent,
    FormInputBooleanComponent,
    FormInputDateComponent,
    FormInputDropdownComponent,
    FormInputLabelComponent,
    FormResultViewComponent,
    FormInputSimpleArrayComponent,
    FormInputComplexArrayComponent,
    FormInputsSetComponent,
    FormInputNumberComponent,
    FormInputTwoOptionsComponent,
    PopUpMessageComponent,
    PopUpConfirmComponent,
    FormInputFilesUploadComponent,
    FormValidationErrorComponent,
    FormErrorIndicatorComponent,
    FormViewCardComponent,
    FormInputNhsFieldComponent,
    FormInputNhsNumberComponent,
    FormInputNhsNumberDisplayOnlyComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    MatTabsModule,
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatStepperModule,
    MatDialogModule,
    ComponentsModule,
    FileUploadModule,
    DirectivesModule,
    PipesModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDateFnsModule,
  ],
  exports: [
    GenericFormComponent,
    FormInputComponent,
    FormInputDateComponent,
    FormValidationErrorComponent,
    FormErrorIndicatorComponent,
    FormInputNhsFieldComponent,
    FormInputsSetComponent,
    FormViewCardComponent
  ],
  bootstrap: [
    FormInputNhsFieldComponent
  ],
})
export class FormFeatureModule { }
