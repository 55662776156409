import {UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import { InputModel } from '../configuration-models/input-model';
import { ControlType } from '../enums/control-type.enum';

export abstract class FormInput {
  public dataModel: InputModel;
  public typeString!: string;
  public fieldName!: string;
  public fullControlName!: string;
  public rootFormGroup: UntypedFormGroup;
  public formControl!: UntypedFormControl;
  public parentFormGroup!: UntypedFormGroup;
  public visibilityDependencies: UntypedFormControl[] = [];

  public get isDisplayed(): boolean {
    if(!this.dataModel.visibilityRule) {
      return true;
    }

    return this.dataModel.visibilityRule(this.visibilityDependencies.map(e => e.value));
  }
 private applyValidation(): void {
    if (this.dataModel.validationRule) {
      const validationFunction:  ValidatorFn = (control) => {
        if(this.dataModel.validationRule!==undefined) {
          const error = this.dataModel.validationRule(control.value,[])
          if (error !== null) {
            return { customError: { errorMessage: error } } ;
          }
        }
        return null;
      };
     this.formControl.addValidators(validationFunction);
    }
  }


  public get errorMessage(): string | null {
    if(this.formControl.errors?.required) {
      return this.dataModel.isRequiredErrorMessage ? this.dataModel.isRequiredErrorMessage : "This field is required.";
    }

    if(this.formControl.errors?.['minlength']) {
      return this.dataModel.minLengthErrorMessage ? this.dataModel.minLengthErrorMessage : "This value should have at least " + this.dataModel.minLength + " characters."
    }

    if(this.formControl.errors?.['maxlength']) {
      return this.dataModel.maxLengthErrorMessage ? this.dataModel.maxLengthErrorMessage : "This value should have no more than " + this.dataModel.maxLength + " characters."
    }

    if(this.formControl.errors?.['pattern']) {
      return this.dataModel.regexExpressionErrorMessage ? this.dataModel.regexExpressionErrorMessage : "This value should have the next format " + this.dataModel.regexExpression
    }

    if(this.formControl.hasError('customError')) {
      const customError = this.formControl.getError('customError');
      if (customError && typeof customError.errorMessage === 'string') {
        return customError.errorMessage;
      }

    }

    return null;
  }

  constructor(rootFormGroup: UntypedFormGroup, dataModel: InputModel, fullControlName: string) {
    this.dataModel = dataModel;
    this.typeString = ControlType[dataModel.type];
    this.rootFormGroup = rootFormGroup;
    this.fullControlName = fullControlName;
    this.visibilityDependencies = dataModel.visibilityDependencies
      ? dataModel.visibilityDependencies.map(e => rootFormGroup.get(e) as UntypedFormControl)
      : [];
  }

  public abstract setValue(draftValue: any): void;

  protected initializeFormGroups(formGroup: UntypedFormGroup, parentArrayName = '') {
    const layers = this.dataModel.bindingPath.split('.');

    for(let i = 0; i < layers.length - 1; i++) {
      let childFormGroup = formGroup.get(layers[i]) as UntypedFormGroup;

      if(!childFormGroup) {
        childFormGroup = new UntypedFormGroup({}, {updateOn: 'change'});
        formGroup.addControl(layers[i], childFormGroup);
      }

      formGroup = childFormGroup;
    }

    this.fieldName = layers[layers.length - 1];
    this.parentFormGroup = formGroup;

    if(!parentArrayName) {
      this.parentFormGroup.addControl(this.fieldName, this.formControl);
    }
    this.applyValidation()
  }
}
