import {Component, Input, OnDestroy, OnInit, Optional, Self} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  FormGroupDirective,
  NgControl,
  NgForm,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {FormInput} from '../form-models/inputs/form-input';
import {distinctUntilChanged} from 'rxjs/operators';
import {ErrorStateMatcher} from '@angular/material/core';

/** A hero's name can't match the given regular expression */
export function valueMatchValidator(validateAgainstField: AbstractControl, message: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === null) {
      return null;
    } if (control.value === '') {
      return {['customError']: {['errorMessage']: 'This field is required.'}};
    }
    return validateAgainstField.value === control.value ? null : {['customError']: {['errorMessage']: message}};
  };
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  constructor(private _ownerControl: NgControl) {}
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (this._ownerControl.control.touched && this._ownerControl.control.invalid) || (control?.touched && control.invalid);
  }
}

@Component({
  selector: 'acacium-group-ng-form-input-nhs-number',
  templateUrl: './form-input-nhs-number.component.html',
  styleUrls: ['./form-input-nhs-number.component.scss'],
})

export class FormInputNhsNumberComponent implements OnDestroy, OnInit, ControlValueAccessor {
  private static counter = 0;
  public componentNumber: number;
  @Input() formInput!: FormInput;
  @Input() isEditable = true;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private _onChange: (value: any) => void;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private _onTouched: () => void;
  private subscriptions = new Subscription();
  public childControl = new UntypedFormControl(null);
  public matcher!: MyErrorStateMatcher;

  constructor(
    @Optional() @Self() public ngControl: NgControl
  ) {
    FormInputNhsNumberComponent.counter++;
    this.componentNumber = FormInputNhsNumberComponent.counter;

    if (this.ngControl) {
      if (this.ngControl.control !== null) {
        this.matcher = new MyErrorStateMatcher(this.ngControl);
      }
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }


  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.formInput.formControl['thisOne'] = true;
    if (this.formInput.dataModel.validateAgainstField) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.formInput.formControl.addValidators([
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        valueMatchValidator(this.ngControl.control.root.get(this.formInput.dataModel.validateAgainstField.name), this.formInput.dataModel.validateAgainstField.errorMessage)
      ]);
      this.childControl.setValidators(this.formInput.formControl.validator);
      this.subscriptions.add(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.ngControl.control.root
          .get(this.formInput.dataModel.validateAgainstField.name).valueChanges
          .pipe(
            distinctUntilChanged()
          )
          .subscribe({
        next: value => {
          this._onChange(this.childControl.value);
          this.childControl.updateValueAndValidity({
            onlySelf: false,
            emitEvent: true
          })
        }
      }))
    }
    this.childControl.setValidators(this.formInput.formControl.validator);
    this.subscriptions.add(
      this.childControl.valueChanges.pipe(
        distinctUntilChanged((previous, current) => {
          return JSON.stringify(previous) === JSON.stringify(current) || this._onChange === undefined;
        })
      ).subscribe({
        next: (value: string) => {
          if (value !== this.formInput.formControl.value.replace(/\D/g, '')) {
            this._onChange(value);
            this._onTouched();
          }

        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  writeValue(obj: string): void {
    if (typeof obj === 'string' && (this.childControl.value !== obj)) {
      if (obj !== this.childControl.value) {
        this.childControl.setValue(obj);
      }
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
}
