import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Section } from "../form-models/section-element.model";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-form-result-view',
  templateUrl: './form-result-view.component.html',
  styleUrls: ['./form-result-view.component.scss']
})
export class FormResultViewComponent  {
  @Input() public sections!: Section[];
  @Input() formValid = true;    // Let generic form work out if all sections are valid
  @Output() navigateTo = new EventEmitter<number>();
  lastStepBanner: string =
    '<h1>Remember: Please provide as much information as possible</h1>' +
    '<p>Any missing information may cause delays to the referral, as we might need to ask you for more details.</p>';

  public navigate(index: number) {
    this.navigateTo.emit(index);
  }
}
