import { NgModule } from "@angular/core";
import { NhsNumberPipe } from "./nhs-number/nhs-number.pipe";
import { SortByDatePipe } from './sort-by-date/sort-by-date.pipe';

@NgModule({
  declarations: [
    NhsNumberPipe,
    SortByDatePipe
   ],
  exports: [
    NhsNumberPipe,
    SortByDatePipe
  ],
  providers: [ ]
})
export class PipesModule { }
