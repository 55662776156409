import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { APP_CONFIG } from '@acacium-group-ng/shared/data-access/agng-app-config';
import {
  IAppConfig,
  IAttachment,
  IPaginatedRequest,
  IReferral,
  IReferralView,
  IResAttachment,
  IUploadItem,
} from '@acacium-group-ng/data-definition';
import { format } from 'date-fns';
import { BehaviorSubject, catchError, EMPTY, forkJoin, Observable, of, switchMap, take, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { data } from "browserslist";
import { logger } from "@azure/storage-blob";

@Injectable({
  providedIn: 'root',
})
export class ReferralService {
  curReferral = new BehaviorSubject<{
    referralDate?: string | undefined;
    referralId: string;
    referrerName?: string;
  }>({
    referralId: '',
    referralDate: format(new Date(), 'yyyy-MM-dd'),
    referrerName: '',
  });
  url: string;
  attachments: IAttachment[] = [];
  apiAttachments: IResAttachment[] = [];
  allAttachmentsUploaded = new BehaviorSubject(false);

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(APP_CONFIG) private readonly appConfig: IAppConfig
  ) {
    console.log('Instance created with config: ', this.appConfig);
    this.url = `${appConfig.servers.cypdtServicesUrl}/referrals`;
  }

  referralsPage$(pageRequest: IPaginatedRequest): Observable<IReferralView> {
    const requestOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/text',
        Accept: 'application/json',
        'Accept-Language': 'en-gb',
        'Access-Control-Allow-Headers': 'Content-Type',
      }),
      params: new HttpParams({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fromObject: pageRequest,
      }),
    };

    return (
      this.httpClient
        .get<IReferralView>(this.url, requestOptions)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .pipe(
          map((referralViewModel) => {
            referralViewModel.referrals = referralViewModel.referrals.map((r) =>
              this._normalizeNhsNumber(r)
            );
            return referralViewModel;
          })
        )
    );
  }

  private _normalizeNhsNumber(refItem: IReferral): IReferral {
    if (refItem.nhsNumber && typeof refItem.nhsNumber === 'string') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      refItem.nhsNumber = refItem.nhsNumber.replace(/\D/g, '');
    } else if (!refItem.nhsNumber) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      refItem.nhsNumber = '';
    }
    // eslint-disable-next-line no-prototype-builtins,@typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (
      refItem.nhsNumberConfirmation &&
      typeof refItem.nhsNumberConfirmation === 'string'
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      refItem.nhsNumberConfirmation = refItem.nhsNumberConfirmation.replace(
        /\D/g,
        ''
      );
    } else if (!refItem.nhsNumberConfirmation) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      refItem.nhsNumberConfirmation = '';
    }
    return refItem;
  }

  getReferral$(id: string): Observable<IReferral> {
    const getDraftEndpoint = `${this.url}/${id}`;
    return this.httpClient.get<IReferral>(getDraftEndpoint).pipe(
      tap((data) => {
        if (data.payload) {
          data.payload = this.addAttachmentsInPayload(data);
          data.payload = this.addReferrerNameAndeDate(data);
        }
        this.curReferral.next({
          referralDate: data.referralDateTime,
          referralId: data.id,
          referrerName: data.referrerName,
        });
      }),
      map((data) => {
        return data.payload ? data.payload : data;
      }),
      map((something) => {
        if (typeof something === 'string') {
          return this._normalizeNhsNumber(JSON.parse(something));
        }
        return this._normalizeNhsNumber(something);
      })
    );
  }

  getAttachments(referralId: string): Observable<IResAttachment[]> {
    const endpoint = `${this.url}/${referralId}/attachments`;

    return this.httpClient
      .get<IResAttachment[]>(endpoint)
  }

  addAttachmentsInPayload(data: IReferral): string {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const tempPayload = JSON.parse(data.payload);
    data?.attachedDocuments?.forEach((item: IUploadItem) => {
      item.progress = 100;
    });
    tempPayload.attachedDocuments = data.attachedDocuments;
    return JSON.stringify(tempPayload);
  }

  addReferrerNameAndeDate(data: IReferral): string {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const tempPayload = JSON.parse(data.payload);
    tempPayload.referrerName = data.referrerName || '';
    tempPayload.referralDateTime = data.referralDateTime || new Date();
    return JSON.stringify(tempPayload);
  }

  createDraft$(referralDetails: IReferral): Observable<any> {
    referralDetails.payload = JSON.stringify(referralDetails);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      responseType: 'text' as const,
    };
    const endpoint = `${this.url}/draft`;

    return this.httpClient.post(endpoint, referralDetails, options).pipe(
      tap((data: string) => {
        this.curReferral.next({ referralId: JSON.parse(data).id });
      })
    );
  }

  updateDraft$(referralDetails: IReferral): Observable<any> {
    const id = referralDetails.id;
    referralDetails.payload = JSON.stringify(referralDetails);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      responseType: 'text' as const,
    };
    const endpoint = `${this.url}/draft/${id}`;

    return this.httpClient.put(endpoint, referralDetails, options);
  }

  deleteDraft$(id: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      responseType: 'text' as const,
    };
    const endpoint = `${this.url}/draft/${id}`;

    return this.httpClient.delete(endpoint, options);
  }

  submitReferral$(referralDetails: any): Observable<any> {
    referralDetails.payload = JSON.stringify(referralDetails);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      responseType: 'text' as const,
    };
    const endpoint = `${this.url}/draft/${referralDetails.id}/submission `;
    return this.httpClient.post(endpoint, referralDetails, options);
  }

  uploadAttachments(): Observable<IUploadItem[]> {
    if (this.attachments.length > 0) {
      const attachments: IAttachment[] = [...this.attachments];
      const tempAttachments: IUploadItem[] = attachments.map((att) => att.file);

      const requests = attachments.map(attachment =>
      {
        return this.uploadAttachment$(attachment.referralId, attachment.file).pipe(
          catchError((res)=>{
            this.attachments.forEach(newAttachment => {
              newAttachment.file.progress = 100;
              newAttachment.file.status = 'CANCELED';
            })
            attachment.file.status = 'EXIST';
            if (res && res.error?.messages){
              return throwError(()=> new Error(`Error: ${res.error.messages[0]}`))
            }
            else {
              return throwError(()=> new Error(`Error: something went wrong, please try again later.`))
            }

          }),
          tap(res => {
              tempAttachments.forEach((item: IUploadItem) => {
              if (item.url === attachment.file.url)
                attachment.file.attachmentId = res.attachmentId;
            });
          })
        )
      }

      )

      return forkJoin(requests).pipe(
        tap(()=> this.attachments = []),
        switchMap(() => of(tempAttachments))
      )
    }
    return of([]);
  }

  uploadAttachment$(id: string, item: IUploadItem): Observable<IUploadItem> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Skip-Interceptor': 'true'
      })
    };
    const endpoint = `${this.url}/${id}/attachments`;

    return this.httpClient
      .post<IUploadItem> (endpoint, item, options)
  }

  deleteAttachment$(referralId: string, attachmentId: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      responseType: 'text' as const,
    };
    const endpoint = `${this.url}/${referralId}/attachments/${attachmentId}`;

    return this.httpClient.delete(endpoint, options);
  }
}
