import { SectionModel } from './configuration-models/section-model';
import { Subsection } from './subsection';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export class Section {
  public subsections: Subsection[] = [];
  public visibilityDependencies: UntypedFormControl[] = [];
  public nextButtonLabelDependencies: UntypedFormControl[] = [];
  public previousButtonLabelDependencies: UntypedFormControl[] = [];

  public get isDisplayed(): boolean {
    if(!this.dataModel.visibilityRule) {
      return true;
    }

    return this.dataModel.visibilityRule(this.visibilityDependencies.map(e => e.value));
  }

  public get nextButtonLabel(): string {
    if(!this.dataModel.nextButtonLabelRule) {
      return 'Save and continue';
    }

    return this.dataModel.nextButtonLabelRule(this.nextButtonLabelDependencies.map(e => e.value));
  }

  public get nextSkipLabel(): string {
    let nextSkipLabelTxt = '--next';
     this.dataModel.subsections.find( subSectionModel => {
        if(subSectionModel.nextSkipLabelRule) {
          nextSkipLabelTxt = subSectionModel.nextSkipLabelRule(this.nextButtonLabelDependencies.map(e => e.value));
        }
    });
    return nextSkipLabelTxt;
  }

  public get previousButtonLabel(): string {
    if(!this.dataModel.previousButtonLabelRule) {
      return 'Previous step';
    }

    return this.dataModel.previousButtonLabelRule(this.previousButtonLabelDependencies.map(e => e.value));
  }

  constructor(rootFormGroup: UntypedFormGroup,  public dataModel: SectionModel, public fullControlName: string) {
    if(!this.dataModel.subsections) {
      throw new Error('Group control must contain child controls');
    }

    for(let i = 0; i < this.dataModel.subsections.length; i++) {
      const subsectionModel = this.dataModel.subsections[i];
      const subsection = new Subsection(rootFormGroup, subsectionModel, `${this.fullControlName}--${subsectionModel.controlName}`);
      this.subsections.push(subsection);
    }

    this.visibilityDependencies = dataModel.visibilityDependencies
      ? dataModel.visibilityDependencies.map(e => rootFormGroup.get(e) as UntypedFormControl)
      : [];

    this.nextButtonLabelDependencies = dataModel.nextButtonLabelDependencies
      ? dataModel.nextButtonLabelDependencies.map(e => rootFormGroup.get(e) as UntypedFormControl)
      : [];

    this.previousButtonLabelDependencies = dataModel.previousButtonLabelDependencies
      ? dataModel.previousButtonLabelDependencies.map(e => rootFormGroup.get(e) as UntypedFormControl)
      : [];
  }

  public setValue(value: any): void {
    this.subsections.forEach(subsection => subsection.setValue(value));
  }
}
