<div *ngIf="!viewMode" class="form-input">
  <app-form-input-label
    [id]="fieldInput.fullControlName"
    [attr.data-cy]='fieldInput.fullControlName + "--input-label"'
    [formInput]="fieldInput"
    [viewMode]="viewMode"
    class="input-label"
  ></app-form-input-label>
  <div [formGroup]="fieldInput.parentFormGroup">
    <div class="input-container">
      <div *ngIf="!fieldInput.parentFormArray && !viewMode">
        <mat-radio-group [formControlName]="fieldInput.fieldName"
                         [attr.aria-labelledby]="fieldInput.fullControlName">
          <mat-radio-button
            role="radio"
            [name]="fieldInput.fullControlName"
            [id]="fieldInput.fullControlName + '--input-first'"
            [attr.data-cy]='fieldInput.fullControlName + "--input-first"'
            name='{{formInput.fieldName}}'
            value="{{formInput.dataModel.options ? formInput.dataModel.options[0].value : ''}}"
            class='{{fieldInput.fieldControl.invalid && (formInput.formControl.dirty || formInput.formControl.touched) ? "invalid" : "valid"}}'
          >{{formInput.dataModel.options ? formInput.dataModel.options[0].label : ''}}</mat-radio-button>
          <mat-radio-button
            role="radio"
            [name]="fieldInput.fullControlName"
            [id]="fieldInput.fullControlName + '--input-second'"
            [attr.data-cy]='fieldInput.fullControlName + "--input-second"'
            name='{{formInput.fieldName}}'
            value="{{formInput.dataModel.options ? formInput.dataModel.options[1].value : ''}}"
            class='{{fieldInput.fieldControl.invalid && (formInput.formControl.dirty || formInput.formControl.touched) ? "invalid" : "valid"}}'
          >{{formInput.dataModel.options ? formInput.dataModel.options[1].label : ''}}</mat-radio-button>
        </mat-radio-group>
        <mat-hint
          *ngIf='fieldInput.dataModel.description && !(formInput.formControl.invalid && (formInput.formControl.dirty || formInput.formControl.touched))'
          [attr.data-cy]='fieldInput.fullControlName + "--hint"'
          class='mat-small hint'
        >{{fieldInput.dataModel.description}}</mat-hint>
        <mat-error
          *ngIf="formInput.formControl.invalid && (formInput.formControl.dirty || formInput.formControl.touched)"
          class='mat-small error'
        >{{fieldInput.errorMessage}}</mat-error>
      </div>
      <div *ngIf="fieldInput.parentFormArray && !viewMode" formArrayName="{{fieldInput.parentArrayName}}">
        <mat-radio-group [formControlName]="fieldInput.fieldName"
                         [attr.aria-labelledby]="fieldInput.fullControlName">
          <mat-radio-button
            role="radio"
            [name]="fieldInput.fullControlName"
            [id]="fieldInput.fullControlName + '--input-first'"
            [attr.data-cy]='fieldInput.fullControlName + "--input-first"'
            name='{{formInput.fieldName}}'
            value="{{formInput.dataModel.options ? formInput.dataModel.options[0].value : ''}}"
            class='{{fieldInput.fieldControl.invalid && (formInput.formControl.dirty || formInput.formControl.touched) ? "invalid" : "valid"}}'
          >{{formInput.dataModel.options ? formInput.dataModel.options[0].label : ''}}</mat-radio-button>
          <mat-radio-button
            role="radio"
            [name]="fieldInput.fullControlName"
            [id]="fieldInput.fullControlName + '--input-second'"
            [attr.data-cy]='fieldInput.fullControlName + "--input-second"'
            name='{{formInput.fieldName}}'
            value="{{formInput.dataModel.options ? formInput.dataModel.options[1].value : ''}}"
            class='{{fieldInput.fieldControl.invalid && (formInput.formControl.dirty || formInput.formControl.touched) ? "invalid" : "valid"}}'
          >{{formInput.dataModel.options ? formInput.dataModel.options[1].label : ''}}</mat-radio-button>
        </mat-radio-group>
        <mat-hint
          *ngIf='fieldInput.dataModel.description && !(formInput.formControl.invalid && (formInput.formControl.dirty || formInput.formControl.touched))'
          [attr.data-cy]='fieldInput.fullControlName + "--hint"'
          class='hint'
        >{{fieldInput.dataModel.description}}</mat-hint>
        <mat-error
          *ngIf="formInput.formControl.invalid && (formInput.formControl.dirty || formInput.formControl.touched )"
          class='error'
        >{{fieldInput.errorMessage}}</mat-error>
      </div>
    </div>
  </div>
</div>
<div *ngIf="viewMode && isEditable" class="form-view">
  <app-form-input-label
    [attr.data-cy]='fieldInput.fullControlName + "--input-label"'
    [formInput]="fieldInput"
    [viewMode]="viewMode"
    class="input-label"
    [ngClass]="{'field-error': fieldInput.formControl.invalid}"
  ></app-form-input-label>
  <div
    [attr.data-cy]='fieldInput.fullControlName + "--view"'
    class="result-value"
    [ngClass]="{'field-error': fieldInput.formControl.invalid}"
  ><span *ngIf="fieldInput.formControl.invalid"><acacium-group-ng-form-error-indicator>!</acacium-group-ng-form-error-indicator>&nbsp;<em>{{fieldInput.errorMessage}}</em></span>
    <span *ngIf="fieldInput.formControl.valid">{{selectedOption}}</span></div>
</div>
<div *ngIf="viewMode && !isEditable" class="form-view">
  <app-form-input-label
    [attr.data-cy]='fieldInput.fullControlName + "--input-label"'
    [formInput]='fieldInput'
    [viewMode]='viewMode'
    class="input-label"
  ></app-form-input-label>
  <div
    [attr.data-cy]='fieldInput.fullControlName + "--view"'
    class="result-value">
    <span *ngIf="fieldInput.formControl.invalid">-</span>
    <span *ngIf="fieldInput.formControl.valid">{{selectedOption}}</span>
  </div>
</div>
