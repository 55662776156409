import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StylePaginatorDirective } from './pager-directive/pager-directive.directive';

@NgModule({
  declarations: [
    StylePaginatorDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    StylePaginatorDirective
  ]
})
export class PagerModule { }
