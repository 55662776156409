<div *ngIf="!viewMode" class="form-input">
  <app-form-input-label
    [attr.data-cy]="fieldInput.fullControlName + '--input-label'"
    [formInput]="fieldInput"
    [viewMode]="viewMode"
    class="input-label"
  ></app-form-input-label>
  <div [formGroup]="fieldInput.parentFormGroup">
    <div class="input-container">
      <div *ngIf="!fieldInput.parentFormArray && !viewMode">
        <mat-form-field appearance="outline">
          <input
            [value]="fieldInput.fieldControl.value"
            role="textbox"
            [name]="fieldInput.fullControlName"
            [attr.data-cy]="fieldInput.fullControlName + '--input'"
            placeholder="{{ fieldInput.dataModel.placeholder }}"
            type="number"
            id="{{fieldInput.dataModel.controlName}}"
            matInput
            formControlName="{{ fieldInput.fieldName }}"
          />
          <mat-hint
            *ngIf="fieldInput.dataModel.description"
            [attr.data-cy]="fieldInput.fullControlName + '--hint'"
            >{{ fieldInput.dataModel.description }}</mat-hint
          >
          <mat-error>{{ fieldInput.errorMessage }}</mat-error>
        </mat-form-field>
      </div>
      <div
        *ngIf="fieldInput.parentFormArray && !viewMode"
        formArrayName="{{ fieldInput.parentArrayName }}"
      >
        <mat-form-field appearance="outline">
          <input
            [value]="fieldInput.fieldControl.value"
            role="textbox"
            [name]="fieldInput.fullControlName"
            [attr.data-cy]="fieldInput.fullControlName + '--input'"
            placeholder="{{ fieldInput.dataModel.placeholder }}"
            type="number"
            id="{{fieldInput.fullControlName}}"
            matInput
            formControlName="{{ fieldInput.fieldName }}"
          />
          <mat-hint
            *ngIf="fieldInput.dataModel.description"
            [attr.data-cy]="fieldInput.fullControlName + '--hint'"
            >{{ fieldInput.dataModel.description }}</mat-hint
          >
          <mat-error>{{ fieldInput.errorMessage }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<div *ngIf="viewMode" class="form-view">
  <app-form-input-label
    [attr.data-cy]="fieldInput.fullControlName + '--input-label'"
    [formInput]="fieldInput"
    [viewMode]="viewMode"
    class="input-label"
    [ngClass]="{ 'field-error': fieldInput.formControl.invalid }"
  ></app-form-input-label>
  <div
    [attr.data-cy]="fieldInput.fullControlName + '--view'"
    class="result-value"
    [ngClass]="{ 'field-error': fieldInput.formControl.invalid }"
  >
    <span *ngIf="fieldInput.formControl.invalid"
      ><acacium-group-ng-form-error-indicator
        >!</acacium-group-ng-form-error-indicator
      >&nbsp;<em>{{fieldInput.errorMessage}}</em></span
    >
    <span *ngIf="fieldInput.formControl.valid">{{
      fieldInput.formControl.value
    }}</span>
  </div>
</div>
