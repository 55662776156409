<acacium-group-ng-form-validation-error class="form-validation-error" *ngIf="!formValid"></acacium-group-ng-form-validation-error>
<acacium-group-ng-form-view-card [safeContent]="lastStepBanner"></acacium-group-ng-form-view-card>
<ng-template ngFor let-section [ngForOf]="sections" let-index="index">
  <mat-card
    *ngIf="section.isDisplayed"
    [attr.data-cy]="section.fullControlName + '--section-card'"
  >
    <div class="header-container">
      <h2
        [attr.data-cy]="section.fullControlName + '--section-label'"
        class="header"
      >
        {{ section.dataModel.label }}
      </h2>
      <a
        [attr.data-cy]="section.fullControlName + '--edit-button'"
        mat-button
        class="edit-button"
        (click)="navigate(index)"
      >
        <h2 class="edit-label">Edit</h2>
        <mat-icon svgIcon="edit-solid" class="icon"></mat-icon>
      </a>
    </div>
    <ng-template ngFor let-subsection [ngForOf]="section.subsections">
      <app-form-inputs-set
        class="form-input-set"
        [attr.data-cy]="subsection.fullControlName + '--inputs-set'"
        [subsection]="subsection"
        [viewMode]="true"
        [isEditable]="true"
      ></app-form-inputs-set>
    </ng-template>
  </mat-card>
</ng-template>
