import { Component, Input } from '@angular/core';
import { FormInput } from '../form-models/inputs/form-input';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-form-input-label',
  templateUrl: './form-input-label.component.html',
  styleUrls: ['./form-input-label.component.scss']
})
export class FormInputLabelComponent {
  @Input() formInput!: FormInput;
  @Input() viewMode!: boolean;
}
