import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ICareRecord, IMasterDetailList, IPathwayAppointment, IPathwayRisk } from '@acacium-group-ng/data-definition';

export enum DetailType {
  NotKnown,
  QuestionAnswer,
  PathwayAppointment,
}

@Component({
  selector: 'acacium-group-ng-master-detail',
  templateUrl: './master-detail.component.html',
  styleUrls: ['./master-detail.component.scss'],
})
export class MasterDetailComponent implements OnInit {
  // todo - Safety record needs adding when model updated
  @Input() list: ICareRecord | IPathwayRisk[] | IPathwayAppointment[] | undefined;

  displayList: IMasterDetailList[] | undefined;
  detailEnum = DetailType; // to allow use in template
  detailType: DetailType = DetailType.NotKnown;

  currentDetail: any;
  currentTitle: string | null = '';

  datePipe = new DatePipe('en-UK');
  ngOnInit(): void {
    this.displayList = this.getDisplayListFromType(this.list);
    if (this.displayList.length > 0) {
      this.currentDetail = this.displayList[0].contents;
    this.currentTitle = this.displayList[0].linkTitle;
    } else {
      this.setUnknownAndEmpty();
    }
  }

  setUnknownAndEmpty(): void {
    this.currentDetail = {};
    this.detailType = DetailType.NotKnown;
  }

  selectDetail(detail: IMasterDetailList) {
    this.deactivateAll();
    detail.active = true;
    this.currentDetail = detail.contents;
    this.currentTitle = detail.linkTitle;
  }

  deactivateAll(): void {
    this.displayList?.forEach((value) => (value.active = false));
  }

  // just for debug todo - remove after question/answer and appointment components plugged in
  jsonAsString(data: any): string {
    return JSON.stringify(data);
  }

  getDisplayListFromType(
    list: ICareRecord | IPathwayRisk[] | IPathwayAppointment[] | undefined
  ): IMasterDetailList[] {
    let displayList: IMasterDetailList[] = [];

    // What type is list and build displayList Accordingly
    if (this.isCareRecord(list)) {
      this.detailType = DetailType.QuestionAnswer;
      displayList = this.buildDisplayListFromCareRecord(list as ICareRecord);
    }
    if (this.isPathwayRisk(list)) {
      this.detailType = DetailType.QuestionAnswer;
      displayList = this.buildDisplayListFromPathwayRisk(list as IPathwayRisk[]);
    }
    if (this.isPathwayAppointment(list)) {
      this.detailType = DetailType.PathwayAppointment;
      displayList = this.buildDisplayListFromPathwayAppointment(
        list as IPathwayAppointment[]
      );
    }

    return displayList;
  }

  buildDisplayListFromCareRecord(list: ICareRecord): IMasterDetailList[] {
    const masterDetailToReturn: IMasterDetailList[] = [];
    let detailItem: IMasterDetailList = {
      linkTitle: list.assessment,
      contents: list.assessmentDetails,
    };
    masterDetailToReturn.push(detailItem);
    detailItem = {
      active: false,
      linkTitle: list.carePlan,
      contents: list.carePlanDetails,
    };
    masterDetailToReturn.push(detailItem);
    masterDetailToReturn[0].active = true;
    return masterDetailToReturn;
  }

  buildDisplayListFromPathwayRisk(list: IPathwayRisk[]): IMasterDetailList[] {
    const masterDetailToReturn: IMasterDetailList[] = [];
    list.forEach((pathwayRisk: IPathwayRisk) => {
      const detailItem: IMasterDetailList = {
        active: false,
        linkTitle: this.datePipe.transform(
          pathwayRisk.dateRisk as string,
          'dd/MM/yyyy'
        ),
        contents: pathwayRisk.riskNotes,
      };
      masterDetailToReturn.push(detailItem);
    });
    masterDetailToReturn[0].active = true;
    return masterDetailToReturn;
  }

  buildDisplayListFromPathwayAppointment(
    list: IPathwayAppointment[]
  ): IMasterDetailList[] {
    const masterDetailToReturn: IMasterDetailList[] = [];
    list.forEach((pathwayAppointment: IPathwayAppointment) => {
      const detailItem: IMasterDetailList = {
        active: false,
        linkTitle: this.datePipe.transform(
          pathwayAppointment.dateAppointment as string,
          'dd/MM/yyyy'
        ),
        contents: pathwayAppointment,
      };
      masterDetailToReturn.push(detailItem);
    });
    masterDetailToReturn[0].active = true;
    return masterDetailToReturn;
  }

  isCareRecord(
    list: ICareRecord | IPathwayRisk[] | IPathwayAppointment[] | undefined
  ): boolean {
    const test = list as ICareRecord;
    if (test) {
      return !!test.carePlan;
    } else {
      return false;
    }
  }

  isPathwayRisk(
    list: ICareRecord | IPathwayRisk[] | IPathwayAppointment[] | undefined
  ): boolean {
    const test = list as IPathwayRisk[];
    if (test) {
      if (test[0]) {
        return test[0].riskNotes !== undefined;
      }
    }
    return false;
  }

  isPathwayAppointment(
    list: ICareRecord | IPathwayRisk[] | IPathwayAppointment[] | undefined
  ): boolean {
    const test = list as IPathwayAppointment[];
    if (test) {
      if (test[0]) {
        return test[0].appointmentPathway !== undefined;
      }
    }
    return false;
  }
}
