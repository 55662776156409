import { IProfileAvatarItem } from '../../models/profile-avatar-item.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'acacium-group-ng-profile-avatar',
  templateUrl: './profile-avatar.component.html',
  styleUrls: ['./profile-avatar.component.scss']
})

export class ProfileAvatarComponent {
  @Input() public profileAvatarItem: IProfileAvatarItem = { name: '', trust: ''};
  @Input() public cssClasses = '';
  @Input() public elementId = '';
}
