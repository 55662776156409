import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {Observable, throwError, timer} from "rxjs";
import {delayWhen, retryWhen} from "rxjs/operators";

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {

// eslint-disable-next-line @typescript-eslint/no-explicit-any
intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  if (req.headers.has('Skip-Interceptor')) {
    const newReq = req.clone({ headers: req.headers.delete('Skip-Interceptor') });
    return next.handle(newReq);
  }
    return next.handle(req).pipe(
        retryWhen(delayWhen((err, index) => index < 2 ? timer(1000) : throwError(err))),
        );
  }
}
