import { IProfileAvatarItem } from '../../models/profile-avatar-item.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'acacium-group-ng-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.scss']
})
export class ProfileDetailComponent {
  @Input() public profileAvatarItem: IProfileAvatarItem = { name: '', trust: ''};
}
