import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FieldInput } from '../form-models/inputs/field-input';
import { FormInput } from '../form-models/inputs/form-input';
import { MatSelect } from '@angular/material/select';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-form-input-dropdown',
  templateUrl: './form-input-dropdown.component.html',
  styleUrls: ['./form-input-dropdown.component.scss']
})
export class FormInputDropdownComponent implements OnInit, OnChanges {
  @Input() formInput!: FormInput;
  @Input() viewMode!: boolean;
  @Input() isEditable!: boolean;

  @ViewChild('matSelectElem') matSelectRef?: MatSelect;

  fieldInput!: FieldInput;

  ngOnInit(): void {
    this.fieldInput = this.formInput as FieldInput;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fieldInput = this.formInput as FieldInput;
  }

  public focusOnSelect(): void {
    if(this.matSelectRef) this.matSelectRef.focus();
  }

  public get selectedOption(): string {
    const selectedOption = this.fieldInput.dataModel.options?.find(e => {
      return e.value === this.fieldInput.formControl.value;
    });
    return selectedOption ? selectedOption.label : (this.fieldInput.formControl.invalid) ?
      "Incomplete" :
      (this.fieldInput.formControl.value !== null) ?
          this.fieldInput.formControl.value : '-';
  }
}
