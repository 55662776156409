<div class="wrapper">
  <div *ngIf="(isForMenu) === false">
      <button [attr.data-cy]="elementId" mat-button (click)="logout()">
          <mat-icon class="mr">face</mat-icon>
          <span>Log out</span>
          <span *ngIf="countdown !== maxTimeout">{{countdown}})</span>
      </button>
  </div>
  <div *ngIf="(isForMenu)">
      <button mat-button [attr.data-cy]="elementId" (click)="logout()">
        <div class="wrapper-logout-button">
          <mat-icon svgIcon="signout" class="icon"></mat-icon>
          <span>Log out </span><span *ngIf="countdown !== maxTimeout">({{countdown}})</span>
        </div>
      </button>
  </div>
</div>
