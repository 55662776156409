import { MatDateFormats } from '@angular/material/core';

export const DateFormats: MatDateFormats = {
  parse: {
    dateInput: 'dd/MM/yyyy HH:mm',
  },
  display: {
    dateInput: 'dd/MM/yyyy HH:mm',
    monthYearLabel: 'MMMM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM yyyy'
  },
};
