export const contactEmail ='cypadmin@xyladigitaltherapies.com';
export const contactPhoneNumber ='+443331885317';

export const errorMinimumDraft = 'Please provide the  first name, last name and date of birth to create a draft.';
export const errorSubmittingForm = 'There has been an issue with submitting your referral form, please try again later. If the issue still persists, please contact support. \n'+ '\n' + '<b>'+ contactEmail + '</b>';
export const errorDeletingDraft = 'There has been an issue with deleting your draft referral, please try again later. If the issue still persists, please contact support. \n'+ '\n' + '<b>'+ contactEmail + '</b>';
export const dataRetrieveError = 'There has been an issue with retrieving your referral, please try again later. If the issue still persists, please contact support. \n'+ '\n' + '<b>'+ contactEmail + '</b>';

export const deleteTitle = 'Delete draft referral';
export const deleteConfirmationDraft = 'You are about to permanently delete this draft referral. You will not be able to undo this action.\n' +
  'Are you sure you wish to proceed?';

export const errorFieldTitle = 'Fields have an error';
export const errorField = 'One or more fields have an error. Please check and try again.';

export const errorSubmitTitle = 'Unable to submit';
export const errorSubmitField = 'There are issues with some of the fields in the referral. \n' + '\n' +
  'These issues have been highlighted on the ‘Review and submit screen’. Use the ‘Edit’ button to go back and make changes.';
