<acacium-group-ng-loader *ngIf="isLoading"></acacium-group-ng-loader>
<div id="drag-drop-component" class="drag-drop-component">
  <div *ngIf='!viewMode' class="drag-drop-component__wrapper">
    <!-- Drag and drop area -->
    <div class="file-upload-wrapper">
      <div class="form">
        <div class="file-upload"
          [ngClass]="{
           'is-invalid': isValid === false,
           'is-valid': isValid === true
        }"
          appDragDropUpload
          [preventBodyDrop]="preventBodyDrop"
          [activeStyles]="styleOptions"
          (click)="fileField.click()"
          (fileDropped)="upload($event)"
        >
          <div class="file-upload__content">
            <div>
              <mat-icon svgIcon="upload" class="icon"></mat-icon>
            </div>
            <div class="mat-body text">{{description ? description : "Upload files"}}<br/>{{fileOptions.uploadFileAllowedExts}}<br/></div>
            <!-- Select file button -->
            <div class="mat-h4 text"><br/>Drag and drop files here<br/></div>
          </div>
          <input
            [attr.data-cy]="'Upload file' + '--input'"
            type="file"
            name="fileUploads"
            role="textbox"
            [value]="fileField.value"
            #fileField
            (change)="onFileInput($event)"
            accept="{{fileOptions.uploadFileAllowedExts}}"
            hidden
            multiple
          >
        </div>
      </div>
    </div>
    <!-- Select file button -->
    <div class='upload-button-container'>
      <button
        class="button-margin button-alternate mat-body-strong"
        (click)="fileField.click()"
      >Select a file(s)</button>
    </div>
  </div>
  <!-- List of uploaded files -->
  <acacium-group-ng-files-list
    [uploadItems]="uploadItems"
    [viewMode]="viewMode"
    [isEditable]="isEditable"
    (deleteFileRequest)="deleteFile($event)"></acacium-group-ng-files-list>
</div>
