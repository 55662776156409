// format file size of an uploaded file for display
export function formatFileSize(bites: number, decimals: number = 2): string {
  if (bites === 0) {
    return '0 Bites';
  }
  const k = 1000;
  const dm = decimals <= 0 ? 0 : decimals || 2;
  const sizes = ['Bites', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bites) / Math.log(k));
  return parseFloat((bites / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
