<div *ngIf="!viewMode" class="form-input">
  <app-form-input-label
    [attr.data-cy]="formInput.fullControlName + '--input-label'"
    [formInput]="formInput"
    [viewMode]="viewMode"
    class="input-label"
  ></app-form-input-label>
    <div class="input-container">
      <div *ngIf="!formInput.hasOwnProperty('parentFormArray')">
        <mat-form-field   appearance="outline">
          <input
            role="textbox"
            [name]="formInput.fullControlName"
            [attr.data-cy]="formInput.fullControlName + '--input'"
            placeholder="{{ formInput.dataModel.placeholder }}"
            id="{{formInput.fullControlName}}"
            matInput
            [matDatepickerFilter]="dateFilter"
            [matDatepicker]="picker"
            [formControl]="formInput.formControl"
          />
          <mat-datepicker-toggle
            [attr.data-cy]="formInput.fullControlName + '--toggle'"
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker
            [disabled]="formInput.dataModel.isReadOnly"
            [attr.data-cy]="formInput.fullControlName + '--datepicker'"
            #picker
          ></mat-datepicker>
          <mat-hint *ngIf="formInput.dataModel.description">{{
            formInput.dataModel.description
          }}</mat-hint>
          <mat-error>{{ formInput.errorMessage }}</mat-error>
        </mat-form-field>
      </div>
      <div
        *ngIf="formInput.hasOwnProperty('parentFormArray')"
        formArrayName="{{ formInput['parentArrayName'] }}"
      >
        <mat-form-field  appearance="outline">
          <input
            role="textbox"
            [name]="formInput.fullControlName"
            [attr.data-cy]="formInput.fullControlName + '--input'"
            placeholder="{{ formInput.dataModel.placeholder }}"
            id="{{formInput.fullControlName}}"
            matInput
            [matDatepicker]="picker"
            [disabled]="true"
            [formControl]="formInput.formControl"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
            [attr.data-cy]="formInput.fullControlName + '--toggle'"
          ></mat-datepicker-toggle>
          <mat-datepicker
            [disabled]="formInput.dataModel.isReadOnly"
            [attr.data-cy]="formInput.fullControlName + '--datepicker'"
            #picker
          ></mat-datepicker>
          <mat-hint *ngIf="formInput.dataModel.description">{{
            formInput.dataModel.description
          }}</mat-hint>
          <mat-error>{{ formInput.errorMessage }}</mat-error>
        </mat-form-field>
      </div>
    </div>

</div>
<div *ngIf="viewMode && isEditable" class="form-view">
  <app-form-input-label
    [attr.data-cy]="formInput.fullControlName + '--input-label'"
    [formInput]="formInput"
    [viewMode]="viewMode"
    class="input-label"
    [ngClass]="{ 'field-error': formInput.formControl.invalid }"
  ></app-form-input-label>
  <div
    [attr.data-cy]="formInput.fullControlName + '--view'"
    class="result-value"
    [ngClass]="{ 'field-error': formInput.formControl.invalid }"
  >
    <span *ngIf="formInput.formControl.invalid"
      ><acacium-group-ng-form-error-indicator
        >!</acacium-group-ng-form-error-indicator
      >&nbsp;<em>{{formInput.errorMessage}}</em></span
    >
    <span *ngIf="formInput.formControl.valid">{{
      formInput.dataModel.displayRule
        ? formInput.dataModel.displayRule(formInput.formControl.value)
        : (formInput.formControl.value | date:'dd/MM/yyyy')
    }}</span>
  </div>
</div>
<div *ngIf="viewMode && !isEditable" class="form-view">
  <app-form-input-label
    [attr.data-cy]="formInput.fullControlName + '--input-label'"
    [formInput]="formInput"
    [viewMode]="viewMode"
    class="input-label"
  ></app-form-input-label>
  <div
    [attr.data-cy]="formInput.fullControlName + '--view'"
    class="result-value"
  >
    <span *ngIf="formInput.formControl.invalid">-</span>
    <span *ngIf="formInput.formControl.valid">{{
      formInput.dataModel.displayRule
        ? formInput.dataModel.displayRule(formInput.formControl.value)
        : (formInput.formControl.value | date:'dd/MM/yyyy')
    }}</span>
  </div>
</div>
