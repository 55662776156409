import { Component, Input } from '@angular/core';
import { IQuestionAnswer } from '@acacium-group-ng/data-definition';

@Component({
  selector: 'acacium-group-ng-anchor-list',
  templateUrl: './anchor-list.component.html',
  styleUrls: ['./anchor-list.component.scss']
})

export class AnchorListComponent {
  @Input() public questionAnswers: IQuestionAnswer[] = [];
  @Input() public title: string | null = '';
  @Input() public subTitle: string | null = 'Contents';

  scrollToElement(i: number): void {
    const element = document.getElementById(i.toString());
    if ( element != undefined ) element.scrollIntoView();
  }

  downloadCopy(): void {
    // TODO: update the method
    console.log('clicked to download', this.questionAnswers.length);
  }
}
