import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, environment as env } from '../../../../../environments/environment';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { LocalStorageService } from '../../../../shared/services/storage/local-storage.service';
import { AuthService, IdToken } from '@auth0/auth0-angular';
import jwtDecode from 'jwt-decode';
import { BehaviorSubject, Observable, Subject, takeUntil, tap } from 'rxjs';
import { UserIdleService } from 'angular-user-idle';
import { IAuthUserIdentity, IDialogConfirmModelOptions, IServiceLayerAccess } from '@acacium-group-ng/data-definition';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogConfirmComponent, DialogConfirmModel } from '@acacium-group-ng/ui-components';

/*
  Class created for servicing the service layer access token
*/
@Injectable({
  providedIn: 'root'
})
export class JwtAuthService {
  private options: IDialogConfirmModelOptions = {
    title: 'Session expire warning',
    message: '',
    hasNoButton: true,
    buttonOk: 'Continue session',
    buttonNo: 'Log out',
    hasClose: false
  };
  private baseUrl: string = env.servers.cypdtServicesUrl;
  private storageKey: string = env.jwt.storageKey;

  constructor(
    private storage: LocalStorageService,
    private http: HttpClient,
    private auth: AuthService,
    private userIdleService: UserIdleService,
    private dialog: MatDialog
  ) {
  }

  private _ready$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _logoutCancel$: Subject<void> = new Subject<void>();

  get ready$(): Observable<boolean> {
    return this._ready$.asObservable();
  }

  get userIdentity$(): Observable<IAuthUserIdentity> {
    return this.auth.idTokenClaims$.pipe(
      map((idToken: IdToken | null | undefined) => {
        const userIdentity: IAuthUserIdentity = { trust: {}, access: {}, user: {} };
        if (idToken) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          userIdentity.user = idToken;
          userIdentity.trust = JSON.parse(idToken['http://info.trust.com']);
          userIdentity.access = JSON.parse(idToken['http://access.trust.com']);
        }
        return userIdentity;
      })
    )
  }

  boot(): void {
    this.auth.isLoading$.pipe(
      filter(loading => !loading),
      take(1),
      switchMap(() => {
        return this.auth.getAccessTokenSilently().pipe(
          switchMap((authToken) => {
            return this.http.post(`${this.baseUrl}${env.jwt.auth_endpoint}`,
              (new HttpParams()
                .set('grant_type', env.jwt.auth_grant_type)
                .set('assertion', authToken)).toString(),
              {
                headers: new HttpHeaders()
                  .set('Content-Type', 'application/x-www-form-urlencoded')
              });
          })
        );
      })
    ).subscribe({
      next: jwt => {
        // eslint-disable-next-line no-prototype-builtins
        if (jwt && jwt.hasOwnProperty('access_token')) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.storage.set(this.storageKey, jwt.access_token);
        }
        this._ready$.next(true);
      },
      error: error => {
        console.error('Authentication error: ', error);
      }
    });
    this.userIdleService.startWatching();

    this.userIdleService.onTimerStart()
      .pipe(
        // ToDo tap callback function contains side effects.
        //  Message should be updated from inside DialogConfirmComponent, and not from the service!
        //  Move this functionality in to DialogConfirmComponent
        takeUntil(this._logoutCancel$),
        tap((count) => {
          this.options.message = `Your session is due to expire. You will be logged out in ${environment.idleTimeout.timeout - count} seconds, continue session or log out?`;
        }),
        // grab only first timer emission
        filter(cnt => cnt === 1),
        switchMap((count) => {
          const existingDialog: MatDialogRef<DialogConfirmComponent, boolean> | undefined =
            this.dialog.getDialogById('session-expiration-dialog');
          if (existingDialog !== undefined) {
            return existingDialog.afterClosed();
          }
          const dialogRef: MatDialogRef<DialogConfirmComponent, boolean> = this.dialog.open(DialogConfirmComponent, {
            disableClose: true,
            data: new DialogConfirmModel(this.options),
            id: 'session-expiration-dialog'
          });
          return dialogRef.afterClosed();
        })
      )
      .subscribe({
        next: (doNotLogOut) => {
          console.log('onTimerStart', doNotLogOut);
          this.userIdleService.resetTimer();
          if (!doNotLogOut) {
            this._logoutCancel$.next();
            this.logout();
          }

        },
        error: err => {
          console.error('Error: ', err)
        },
        complete: () => {
          console.log('Completed!')
        }
      })

    this.userIdleService.onTimeout().subscribe(() => {
      console.log('Time is up!');
      this._logoutCancel$.next();
      this.logout();
    });

  }

  logout(): void {
    this.storage.clear();
    this.auth.logout({ returnTo: `${window.location.origin}/#/auth` });
  }

  /*
    get access token from local storage
  */
  serviceLayerAccessTokenString(): string | null {
    return this.storage.get(this.storageKey);
  }

  serviceLayerAccess(): IServiceLayerAccess | null {
    const _str = this.serviceLayerAccessTokenString();
    if (_str) {
      return jwtDecode(_str);
    }
    return null;
  }

  /*
    clear token from local storage on device
  */
  clear() {
    this.storage.remove(this.storageKey);
  }
}

