import { Component, Input, OnInit } from '@angular/core';
import { FormInput } from '../form-models/inputs/form-input';
import { DragDropStyles } from '../../file-upload/file-upload-models/drag-drop-styles.model';
import { DragDropFiles } from '../../file-upload/file-upload-models/drag-drop-files.model';
import { IUploadItem } from '@acacium-group-ng/data-definition';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-form-input-files-upload',
  templateUrl: './form-input-files-upload.component.html',
  styleUrls: ['./form-input-files-upload.component.scss']
})
export class FormInputFilesUploadComponent implements OnInit {
  @Input() formInput!: FormInput;
  @Input() viewMode!: boolean;
  @Input() isEditable!: boolean;

  public baseUrl = '';
  public blobStoragePath = '';
  public styleOptions: DragDropStyles = { backgroundColor: '#d9d9d9', color: '#666', border: '1px solid #666' }
  public fileOptions: DragDropFiles = { uploadFileAllowedExts: [], uploadSingleFileMaxSizeKB: 0 };

  ngOnInit(): void {
    if(!this.formInput.dataModel.fileUploadOptions) {
      return;
    }
    this.baseUrl = this.formInput.dataModel.fileUploadOptions.baseUrl;
    this.blobStoragePath = this.formInput.dataModel.fileUploadOptions.blobStoragePath;
    this.fileOptions = {
      uploadFileAllowedExts: this.formInput.dataModel.fileUploadOptions.allowedExtensions,
      uploadSingleFileMaxSizeKB: this.formInput.dataModel.fileUploadOptions.maxSize
    }
  }

  onUpload(items: IUploadItem[]): void {
    this.formInput.formControl.markAsTouched();
    this.formInput.formControl.markAsDirty();
    this.formInput.formControl.patchValue(items.map(e => e));
    this.formInput.formControl.updateValueAndValidity();
  }
}
