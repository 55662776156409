<div role="group"
     class="nhs-number-input-container"
     [attr.aria-labelledby]="_formField.getLabelId()"
     [formGroup]="innerFormGroup"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)"
     acaciumGroupNgNhsNumber [value]="innerFormGroup.value"
     (pasteHandler)="setInputs($event)"
>
  <input class="numGroup--input nhs-number-input-element"
         [value]="innerFormGroup.controls.p1.value"
         role="textbox"
         name="nhsNumber-input1"
         [id]="inputId"
         formControlName="p1"
         maxlength="3"
         size="3"
         [attr.data-cy]='"nhsNum--input1"'
         (input)="_handleInput(innerFormGroup.controls.p1, p2)"
         acaciumGroupNgOnlyNumber
         #p1>
  <span class="nhs-number-input-spacer">&ndash;</span>
  <input class="numGroup--input nhs-number-input-element"
         [value]="innerFormGroup.controls.p2.value"
         role="textbox"
         name="nhsNumber-input2"
         [attr.data-cy]='"nhsNum--input2"'
         formControlName="p2"
         maxlength="3"
         size="3"
         acaciumGroupNgOnlyNumber
         (input)="_handleInput(innerFormGroup.controls.p2, p3)"
         (keydown.backspace)="autoFocusPrev(innerFormGroup.controls.p2, p1)"
         #p2>
  <span class="nhs-number-input-spacer">&ndash;</span>
  <input class="numGroup--input nhs-number-input-element"
         [value]="innerFormGroup.controls.p3.value"
         role="textbox"
         name="nhsNumber-input3"
         [attr.data-cy]='"nhsNum--input3"'
         formControlName="p3"
         maxlength="4"
         size="4"
         (input)="_handleInput(innerFormGroup.controls.p3)"
         acaciumGroupNgOnlyNumber
         (keydown.backspace)="autoFocusPrev(innerFormGroup.controls.p3, p2)"
         #p3>
</div>
