import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer-component/footer.component';
import { MatIconModule } from '@angular/material/icon';
//https://www.angularjswiki.com/angular/how-to-use-font-awesome-icons-in-angular-applications/
//import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule
  ],
  declarations: [
    FooterComponent
  ],
  exports: [
    FooterComponent,
  ]
})
export class FooterModule {}
