<div>
  <div class="search-box">
    <mat-icon
      svgIcon="magnifying-glass-solid"
      class="magnifier-icon"
    ></mat-icon>
    <input
      [attr.data-cy]="searchEntity + '-searchByText'"
      matInput
      autocomplete="off"
      [(ngModel)]="searchText"
      #input
      (keyup.enter)="triggerSearch()"
      (ngModelChange)="onSearchTextChange($event)"
      [placeholder]="'Type a name'"
    />
<!--    <mat-icon-->
<!--      (click)="triggerClearSearch()"-->
<!--      *ngIf="searchText.length > 0"-->
<!--      svgIcon="cross"-->
<!--      class="clear-search-icon"-->
<!--    ></mat-icon>-->
<!--    <mat-icon-->
<!--      (click)="triggerSearch()"-->
<!--      svgIcon="arrow-right-solid"-->
<!--      class="search-arrow-icon"-->
<!--    ></mat-icon>-->
  </div>
  <h4 class="helper-text">
    {{searchInstructions}}
  </h4>
</div>
