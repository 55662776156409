import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormInput } from '../form-models/inputs/form-input';
import { SimpleArrayInput } from '../form-models/inputs/simple-array-input.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogErrorComponent, ErrorDialogData } from "@acacium-group-ng/ui-components";
import { errorField, errorFieldTitle } from "@acacium-group-ng/message-constants";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-form-input-simple-array',
  templateUrl: './form-input-simple-array.component.html',
  styleUrls: ['./form-input-simple-array.component.scss']
})
export class FormInputSimpleArrayComponent implements OnInit, OnChanges {
  @Input() formInput!: FormInput;
  @Input() viewMode!: boolean;

  arrayInput!: SimpleArrayInput;

  selectedItemIndex = -1;
  selectedItem!: FormInput;
  isEditMode = false;
  isNewItem = false;
  previousValue: string | null = null;

  constructor(private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.arrayInput = this.formInput as SimpleArrayInput;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.arrayInput = this.formInput as SimpleArrayInput;
  }

  addItem() {
    this.arrayInput.addNewItem();
    this.selectedItemIndex = this.arrayInput.items.length - 1;
    this.selectedItem = this.arrayInput.items[this.selectedItemIndex];
    this.isNewItem = true;
    this.editItem();
  }

  removeItem() {
    this.arrayInput.removeItem(this.selectedItemIndex);
    this.selectedItemIndex = -1;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.selectedItem = undefined;
  }

  selectItem(index: number) {
    if(this.isEditMode) {
      return;
    }

    if(index === this.selectedItemIndex) {
      this.selectedItemIndex = -1;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.selectedItem = undefined;
    } else {
      this.selectedItemIndex = index;
      this.selectedItem = this.arrayInput.items[index];
    }
  }

  editItem() {
    this.previousValue = this.selectedItem.formControl.value;
    this.isEditMode = true;
  }

  saveItem() {
    if(!this.validateSection()) {
      return;
    }

    this.isNewItem = false;
    this.isEditMode = false;
    this.selectedItemIndex = -1;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.selectedItem = undefined;
  }

  cancel() {
    const previousValue = this.previousValue;
    this.selectedItem.formControl.reset(previousValue);
    this.isEditMode = false;

    if(this.isNewItem) {
      this.arrayInput.removeItem(this.selectedItemIndex);
    }

    this.selectedItemIndex = -1;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.selectedItem = undefined;
  }

  validateSection(): boolean {
    this.selectedItem.formControl.markAsTouched();

    const errorDialog: ErrorDialogData = {
      title: errorFieldTitle,
      message: errorField
    }

    if(!this.selectedItem.formControl.valid) {
      this.dialog.open(DialogErrorComponent, {
        data: errorDialog,
      });
    }

    return this.selectedItem.formControl.valid;
  }
}
