import { Directive, EventEmitter, Output, HostListener, HostBinding, Input } from '@angular/core';
import { DragDropStyles } from '../file-upload-models/drag-drop-styles.model';
// Angular Drag and Drop File
//
// Add this directive to an element to turn it into a dropzone
// for drag and drop of files.
// Example:
//
// <div (appDragDropUpload)="onDrop($event)"></div>
//
// Any files dropped onto the region are then
// returned as a Javascript array of file objects.
// Which in TypeScript is `Array<File>`
//
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appDragDropUpload]'
})
export class DragDropUploadDirective {
  // The directive emits a `fileDrop` event
  // with the list of files dropped on the element
  // as an JS array of `File` objects.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public fileDropped = new EventEmitter<Array<File>>();

  // Disable dropping on the body of the document.
  // This prevents the browser from loading the dropped files
  // using it's default behaviour if the user misses the drop zone.
  // Set this input to false if you want the browser default behaviour.
  @Input() preventBodyDrop = true;

  public inactiveStyles: DragDropStyles = {
    backgroundColor: '#f7f7f7',  color: '#d9d9d9', border: '1px solid #d9d9d9'
  }

  // Default style values *hacky - could not get class via hostbinding working.
  @Input() public activeStyles: DragDropStyles = this.inactiveStyles;

  @HostBinding('class.fileover') public fileOver = false;
  @HostBinding('style.background-color') private background = this.inactiveStyles.backgroundColor;
  @HostBinding('style.color') private foreground = this.inactiveStyles.color;
  @HostBinding('style.border') private border = this.inactiveStyles.border;

  // Dragover Event
  @HostListener('dragover', ['$event']) dragOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.setStyles(this.activeStyles);
    this.fileOver = true;
  }

  // Dragleave Event
  @HostListener('dragleave', ['$event']) public dragLeave(event: DragEvent) {
    //event.stopPropagation();
    event.preventDefault();
    this.setStyles(this.inactiveStyles);
    this.fileOver = false;
  }

  // Dragging onto page body events are seperated out
  @HostListener('body:dragover', ['$event']) public onBodyDragOver(event: DragEvent) {
    if (this.preventBodyDrop) {
      event.preventDefault();
      event.stopPropagation();
      this.fileOver = false;
    }
  }

  // Dragging onto page body events are seperated out
  @HostListener('body:dragover', ['$event']) public dragStart(event: DragEvent) {
    if (this.preventBodyDrop) {
      event.preventDefault();
      event.stopPropagation();
      this.fileOver = false;
      event.dataTransfer?.clearData();
    }
  }
  @HostListener('body:drop', ['$event']) public onBodyDrop(event: DragEvent) {
    if (this.preventBodyDrop) {
      event.preventDefault();
      this.fileOver = false;
    }
  }

  // Drop Event
  @HostListener('drop', ['$event']) public drop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.setStyles(this.inactiveStyles);
    this.fileOver = false;

    if (event.dataTransfer) {
      const files = event.dataTransfer.files;
      //event.dataTransfer.clearData();
      if (files.length > 0) {
        this.fileDropped.emit(Array.from(files))
      }
    }
  }

  private setStyles(styleSet: DragDropStyles) {
    this.background = styleSet.backgroundColor;
    this.foreground = styleSet.color;
    this.border = styleSet.border;
  }
}
