
<h1 (keydown.escape)="handleClose()"  mat-dialog-title class="mat-h1 {{ dialog.options.isError ? 'errorTitle' : '' }}">
  <button
  *ngIf='dialog.options.hasClose'
  mat-dialog-close
  class='dismiss-button'
  color='accent' mat-button>
  <mat-icon svgIcon='cross' class='close' [ngClass]="{'error-close' : dialog.options.isError }"></mat-icon>
</button>
  {{dialog.options.title}}
</h1>
<div mat-dialog-content>
    <p [innerHtml]="dialog.options.message"></p>
    <p class="second-line" [innerHtml]="dialog.options.message2"></p>
</div>
<div mat-dialog-actions class='action-buttons'>

  <button
    color='accent'
    *ngIf="dialog.options.hasCancelButton"
    mat-stroked-button
    (click)="onCancel()">
    {{dialog.options.buttonCancel ? dialog.options.buttonCancel : 'Cancel'}}</button>

  <button
    color='accent'
    *ngIf="dialog.options.hasNoButton"
    mat-stroked-button
    (click)="onNo()">
    {{dialog.options.buttonNo ? dialog.options.buttonNo : 'No' }}</button>

  <button color='accent'
          mat-raised-button
          (click)="onOk()"
          autofocus>
    {{dialog.options.buttonOk ? dialog.options.buttonOk : 'Okay'}}</button>

</div>
