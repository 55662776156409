import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {debounceTime, fromEvent, pluck, Subscription, tap} from "rxjs";

@Component({
  selector: 'acacium-group-ng-search-component',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements AfterViewInit, OnDestroy {

  searchText = '';
  @Input() searchEntity = 'search';
  @Input() searchInstructions = 'Enter search string';
  @Input() noOfCharsToTriggerSearch = 3;

  @Output() runSearch = new EventEmitter<string>();
  @ViewChild('input', { static: false }) searchInput: ElementRef | undefined;
  subscription: Subscription | undefined;

  ngAfterViewInit() {
    this.subscription = fromEvent(this.searchInput?.nativeElement, 'keyup').pipe(
      pluck('target', 'value'),
      debounceTime(1000),
      tap((value) => {
        // @ts-ignore
        if (value.length >= this.noOfCharsToTriggerSearch) {
          this.triggerSearch();
        }
      })
    ).subscribe()
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  triggerSearch(): void {
    this.runSearch.emit(this.searchText);
  }

  triggerClearSearch(): void {
    this.searchText = '';
    this.triggerSearch();
  }

  onSearchTextChange(value: any): void {
    if(value === '')
    {
      this.triggerSearch();
    }
  }

}
