import {
  AfterViewInit,
  Directive,
  Host,
  Optional,
  Renderer2,
  Self,
  ViewContainerRef,
  Input,
  OnChanges
} from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatButton } from "@angular/material/button";

interface PageObject {
  length: number;
  pageIndex: number;
  pageSize: number;
  previousPageIndex: number;
}

enum direction {
  prev,
  next
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[stylePaginator]"
})
export class StylePaginatorDirective implements AfterViewInit, OnChanges {
  // Range start and ends < [1] [2] [3] [4] >
  private _rangeStart!: number;   // The start range is page index of 1st button shown [1]
  private _rangeEnd!: number;     // The end range is page index of last button shown [4]
  private _buttons = [];          // Holds the state of the button objects
  private _curPageObj: PageEvent = {
    previousPageIndex: 0,
    pageSize: 0,
    length: 0,
    pageIndex: 0
  };

  @Input()
  get curPage(): number {
    return this._curPageObj.pageIndex;
  }
  set curPage(v: number) {
    this._curPageObj.pageIndex = v;
  }

  @Input()
  get stylerShowTotalPages(): number {
    return this._showTotalPages;
  }

  set stylerShowTotalPages(value: number) {
    this._showTotalPages = value % 2 == 0 ? value + 1 : value;
  }

  private _showTotalPages = 2;

  @Input() totalRecordCount = 0;

  get inc(): number {
    return this._showTotalPages % 2 == 0
      ? this.stylerShowTotalPages / 2
      : (this.stylerShowTotalPages - 1) / 2;
  }

  get numOfPages(): number {
    return (this.totalRecordCount % this._curPageObj.pageSize === 0) ?
      this.totalRecordCount / this._curPageObj.pageSize :
      Math.round((this.totalRecordCount / this._curPageObj.pageSize) + 0.5)
  }

  get lastPageIndex(): number {
    return (this.numOfPages === 0) ? this.numOfPages : this.numOfPages - 1;
  }

  constructor(
    @Host() @Self() @Optional() private readonly matPag: MatPaginator,
    private vr: ViewContainerRef,
    private ren: Renderer2
  ) {
  }

  ngOnChanges(): void {
    this.rerender({...this._curPageObj});
  }

  // to rerender buttons on items per page change and first, last, next and prior buttons
  rerender(newPage: PageEvent): void {
    if (
      this._curPageObj.pageSize != newPage.pageSize &&
      this._curPageObj.pageIndex != 0
    ) {
      newPage.pageIndex = 0;
      this._rangeStart = 0;
      this._rangeEnd = this._showTotalPages - 1;
    }
    this._curPageObj = newPage;
    this.matPag.pageIndex = newPage.pageIndex;
    this._curPageObj.previousPageIndex = newPage.previousPageIndex;

    if (this.totalRecordCount > 0) {
      this.showPaginator();
      this.buildPageRange();
      this.hidePageSize();
      this.hidePaginatorNextPrevButtons();
      this.styleRangeLabel();
      this.decorateRangeLabel();
    } else {
      this.hidePaginator();
    }
  }

  // Hides the whole of the paginator
  hidePaginator(): void {
    const pagerContainer = this.vr.element.nativeElement.querySelector(
      "div.mat-paginator-outer-container"
    );
    if (pagerContainer) {
      this.ren.setStyle(pagerContainer, "display", "none");
    }
  }

  // Shows the whole of the paginator
  showPaginator(): void {
    const pagerContainer = this.vr.element.nativeElement.querySelector(
      "div.mat-paginator-outer-container"
    );
    if (pagerContainer) {
      this.ren.removeStyle(pagerContainer, "display");
    }
  }

  // Hides the page size options. This is an option on the paginator "hidePageSize" but this directive
  // needs the pageSize to work, so we just hide it
  hidePageSize(): void {
    const pageSizeContainer = this.vr.element.nativeElement.querySelector(
      "div.mat-paginator-page-size"
    );
    if (pageSizeContainer) {
      this.ren.setStyle(pageSizeContainer, "display", "none");
    }
  }

  // Hide the paginator next and prev buttons as these now only work with what's in datasource at any one time.
  // The paginator will disable these because it thinks there is only 1 page
  hidePaginatorNextPrevButtons(): void {
    const buttonArray = this.vr.element.nativeElement.querySelectorAll("button");
    this.ren.setStyle(buttonArray[0], "display", "none");
    this.ren.setStyle(buttonArray[buttonArray.length - 1], "display", "none")
  }

  // This is the 1 of xx label
  styleRangeLabel(): void {
    const pageRangeContainer = this.vr.element.nativeElement.querySelector(
      "div.mat-paginator-range-label"
    );
    this.ren.addClass(pageRangeContainer, 'range-label');
  }

  decorateRangeLabel(): void {
    const pageRangeContainer = this.vr.element.nativeElement.querySelector("div.mat-paginator-range-label");
    const start = this.calculateStart();
    let end = start + this._curPageObj.pageSize - 1;
    if (end >= this.totalRecordCount) {
      end = this.totalRecordCount;
    }
    const newLabel = `Showing ${start} to ${end} of <strong>${this.totalRecordCount}</strong> results`;
    this.ren.setProperty(pageRangeContainer, 'innerHTML', newLabel);
  }

  calculateStart(): number {
    if (this.totalRecordCount >= this._curPageObj.pageSize) {
      return (this.matPag.pageIndex * this._curPageObj.pageSize) + 1;
    } else {
      if (this.totalRecordCount <= this._curPageObj.pageSize) {
        return 1;
      }
      return this.totalRecordCount;
    }
  }

  private buildPageButtons(totalNumberOfPages: number, lastPageIndex: number, currentPage: number) {
    const actionContainer = this.vr.element.nativeElement.querySelector(
      "div.mat-paginator-range-actions"
    );
    const nextPageNode = this.vr.element.nativeElement.querySelector(
      "button.mat-paginator-navigation-next"
    );

    // remove locally cached buttons before creating new ones
    if (this._buttons.length > 0) {
      this._buttons.forEach(button => {
        this.ren.removeChild(actionContainer, button);
      });
      // Empty state array
      this._buttons.length = 0;
    }

    // initialize next page and last page buttons ie < >
    if (this._buttons.length == 0) {
      const nodeArrayOuter = this.vr.element.nativeElement.childNodes[0].childNodes[0].childNodes[2];
      // Deal with case first time through that the buttons haven't been created
      if (nodeArrayOuter) {
        const nodeArray = this.vr.element.nativeElement.childNodes[0].childNodes[0].childNodes[2].childNodes

        setTimeout(() => {
          for (let i = 0; i < nodeArray.length; i++) {
            if (nodeArray[i].nodeName === "BUTTON") {
              this.ren.removeClass(nodeArray[i], 'selected-page-button');
              this.ren.removeClass(nodeArray[i], 'unselected-page-button');
              if (nodeArray[i].innerHTML.includes('mat-button-wrapper') && nodeArray[i].disabled) {
                // special case for < and > buttons as they are a mat-button
                this.ren.addClass(nodeArray[i].querySelector('span.mat-button-wrapper'), 'unselected-page-button');
              } else if (nodeArray[i].innerHTML.includes('mat-button-wrapper') && !nodeArray[i].disabled) {
                this.ren.addClass(nodeArray[i], 'unselected-page-button');
              } else if (nodeArray[i].disabled) {
                this.ren.addClass(nodeArray[i], 'selected-page-button')
              }
            }
          }
        });

        // If we are on the first page don't display prev
        if (currentPage !== 0) {
          this.ren.insertBefore(actionContainer, this.createNextPrevButton(direction.prev), nextPageNode);
        }

        for (let pageNumber = 0; pageNumber < totalNumberOfPages; pageNumber++) {
          if (pageNumber >= this._rangeStart && pageNumber <= this._rangeEnd) {
            this.ren.insertBefore(
              actionContainer,
              this.createPageButton(pageNumber, this.matPag.pageIndex),
              nextPageNode
            );
          }
        }

        // If we are on the last page don't display next
        if (currentPage !== this.lastPageIndex) {
          this.ren.insertBefore(actionContainer, this.createNextPrevButton(direction.next), nextPageNode);
        }
      }
    }

  }

  private createPageButton(pageNumber: number, currentPage: number): MatButton {
    const linkBtn: MatButton = this.ren.createElement("button");
    this.ren.addClass(linkBtn, 'pager-button')

    const pagingTxt = pageNumber + 1;
    const text = this.ren.createText(pagingTxt + "");

    this.ren.addClass(linkBtn, "mat-custom-page");
    if (pageNumber === currentPage) {  // if this is the current page disable the button
      this.ren.setAttribute(linkBtn, "disabled", "disabled");
    }
    this.ren.listen(linkBtn, "click", () => {
      this.changePage(pageNumber, currentPage);
    });

    this.ren.appendChild(linkBtn, text);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this._buttons.push(linkBtn); // Add button to private array for state
    return linkBtn;
  }

  private createNextPrevButton(marker: direction): MatButton {
    const linkBtn: MatButton = this.ren.createElement("button");
    this.ren.addClass(linkBtn, 'prev-next-button');
    this.ren.addClass(linkBtn, "mat-custom-page");
    const markerText = (marker === direction.prev) ? '<' : '>';

    this.ren.appendChild(linkBtn, this.ren.createText(markerText));

    this.ren.listen(linkBtn, "click", () => {
      if (marker === direction.prev) {
        this.changePage(this._curPageObj.pageIndex - 1, this._curPageObj.pageIndex );
      } else {
        this.changePage(this._curPageObj.pageIndex + 1, this._curPageObj.pageIndex);
      }
      this._curPageObj.previousPageIndex = this._curPageObj.pageIndex;
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this._buttons.push(linkBtn); // Add button to private array for state
    return linkBtn;
  }

  // creates the button range based on class input parameters and based on current page index value.
  // Used to render new buttons either initially or after a page change event.
  // ie initially will be something like < [1] [2] [3] [4] [5] >
  // as we page through becomes something like < [5] [6] [7] [8] [9] >
  private buildPageRange(): void {
    this.setPageRange();
    const middleIndex = (this._rangeStart + this._rangeEnd) / 2;
    this._rangeStart = this.calcRangeStart(
      middleIndex,
      this.lastPageIndex,
      this._curPageObj.pageIndex,
      (this._curPageObj.previousPageIndex === undefined ? 0 : this._curPageObj.previousPageIndex));
    this._rangeEnd = this.calcRangeEnd(
      middleIndex,
      this.lastPageIndex,
      this._curPageObj.pageIndex,
      (this._curPageObj.previousPageIndex === undefined ? 0 : this._curPageObj.previousPageIndex));
    this.buildPageButtons(this.numOfPages, this.lastPageIndex, this._curPageObj.pageIndex);
  }

  // Helper function To calculate start of button range from the middle
  private calcRangeStart(middleIndex: number, lastPageIndex: number, currentPage: number, previousPage: number): number {
    switch (true) {
      case currentPage == 0 && this._rangeStart != 0:
        return 0;
      case currentPage > this._rangeEnd:
        return currentPage + this.inc > lastPageIndex
          ? lastPageIndex - this.inc * 2
          : currentPage - this.inc;
      case currentPage > previousPage && currentPage > middleIndex && this._rangeEnd < lastPageIndex:
        return this._rangeStart + 1;
      case currentPage < previousPage && currentPage < middleIndex && this._rangeStart > 0:
        return this._rangeStart - 1;
      default:
        return this._rangeStart;
    }
  }

  private setPageRange(): void {
    const currentPageIndex = this._curPageObj.pageIndex;
    const totalPages = Math.ceil(this.totalRecordCount / this.matPag.pageSize)
    const extraButtons = Math.floor(this.stylerShowTotalPages / 2);

    if (currentPageIndex <= (totalPages - extraButtons - 1)) {
      if (currentPageIndex >= extraButtons) {
        this._rangeStart = currentPageIndex - extraButtons
        this._rangeEnd = currentPageIndex + extraButtons
      } else {
        this._rangeEnd = this.stylerShowTotalPages - 1;
        this._rangeStart = 0;
      }
    }
  }

  // Helper function to calculate end of button range
  private calcRangeEnd(middleIndex: number, lastPageIndex: number, currentPage: number, previousPage: number): number {
    switch (true) {
      case currentPage == 0 &&
      this._rangeEnd != this._showTotalPages:
        return this._showTotalPages - 1;
      case currentPage > this._rangeEnd:
        return currentPage + this.inc > lastPageIndex
          ? lastPageIndex
          : this._curPageObj.pageIndex + 1;
      case currentPage > previousPage && currentPage > middleIndex && this._rangeEnd < lastPageIndex:
        return this._rangeEnd + 1;
      case currentPage < previousPage && currentPage < middleIndex && this._rangeStart >= 0 && this._rangeEnd > this._showTotalPages - 1:
        return this._rangeEnd - 1;
      default:
        return this._rangeEnd;
    }
  }

  // Helper function to switch page
  private changePage(pageIndexToGoTo: number, previousPageIndex: number): void {
    this.matPag.pageIndex = pageIndexToGoTo;
    this.matPag["_emitPageEvent"](previousPageIndex);
    this.buildPageRange();
  }

  // Initialize default state after view init
  public ngAfterViewInit() {
    this._rangeStart = 0;
    this._rangeEnd = this._showTotalPages - 1;
    this._curPageObj = {
      pageIndex: 0,
      pageSize: this.matPag.pageSize,
      length: this.totalRecordCount,
      previousPageIndex: 0
    };
    this.rerender(this._curPageObj);
  }
}
