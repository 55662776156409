import { SubsectionModel } from './subsection-model';
export class SectionModel {
  public label!: string;
  public controlName!: string;
  public announcementBanner?: string;
  public visibilityDependencies?: string[];
  public visibilityRule?: (values: any[]) => boolean;
  public nextButtonLabelDependencies?: string[];
  public nextButtonLabelRule?: (values: any[]) => string;
  public previousButtonLabelDependencies?: string[];
  public previousButtonLabelRule?: (values: any[]) => string;
  public subsections!: SubsectionModel[];
}
