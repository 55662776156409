import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormInput } from '../form-models/inputs/form-input';
import { Subscription } from 'rxjs';
import { endOfToday } from 'date-fns';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-form-input-date',
  templateUrl: './form-input-date.component.html',
  styleUrls: ['./form-input-date.component.scss'],
})
export class FormInputDateComponent implements OnInit, OnDestroy {
  @Input() formInput!: FormInput;
  @Input() viewMode!: boolean;
  @Input() isEditable!: boolean;

  subscriptions: Subscription = new Subscription();

  ngOnInit(): void {

    this.subscriptions.add(this.formInput.formControl.valueChanges.subscribe(
      (value: string | Date) => {
        // When a date is entered by date picker value is a Date object
        // When form is rehyrdated value is a string
        // If it's not Date then it's a string on rehydrating its in the dd/mm/yyyy format
        if (typeof value === 'string') {
          const dateParts = value.split('/');
          if (dateParts.length === 3) {
            const dob = new Date(
              // year
              Number(dateParts[2]),
              // monthIndex, zero based.
              // Check https://developer.mozilla.org/en-US/docs/web/javascript/reference/global_objects/date/date#syntax
              (Number(dateParts[1]) -1),
              Number(dateParts[0]));

            this.formInput.formControl.setValue(dob);
          }

        }
      }
    ));

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }


  public dateFilter = (value: Date | null): boolean => {
    if (value === null || value?.getTime() > endOfToday().getTime()) {
      return false;
    }

    return (
      !this.formInput.dataModel.validationRule ||
      !this.formInput.dataModel.validationRule(value, [])
    );
  };
}
