/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JwtAuthService } from '../../features/jwt-auth/services/jwt-auth.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class JwtAuthInterceptor implements HttpInterceptor {
  private baseUrl: string = environment.servers.cypdtServicesUrl;
  private formSASFunctionUrl: string = environment.servers.cypdtFormSASFunctionUrl;

  constructor(private jwtService: JwtAuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /*
     This can be moved into config list - allow and deny list.
     Only allow interceptor to intercept for all service layer endpoints except auth endpoint
    */
    if ((req.url.includes(this.baseUrl) || req.url.includes(this.formSASFunctionUrl)) && !req.url.includes(environment.jwt.auth_endpoint)) {
      const token = this.jwtService.serviceLayerAccessTokenString();

      if (token) {
        const cloned = req.clone({
          headers: req.headers
            .set('Authorization', `Bearer ${token}`)
        });

        // forward updated request back into request stream
        return next.handle(cloned);
      }
    }

    // no action, return original stream
    return next.handle(req);
  }
}

