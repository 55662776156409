<div class="status-filter-container">
  <mat-form-field appearance="outline" color="accent">
    <mat-label>{{ entityLabel }}</mat-label>
    <mat-select
      disableOptionCentering
      [attr.data-cy]="entity"
      (selectionChange)="onSelectChange($event.value)"
      autocomplete="off"
      multiple
    >
      <mat-option *ngFor="let item of options" value="{{ item.value }}">{{
        item.viewValue
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
