<mat-toolbar color="primary">
  <div class="header">
    <div class="header-logo">
      <Span><img class="logo" src="{{logoPath}}" alt="XYLA digital therapies"/></Span>
    </div>
  </div>
  <ng-container *ngFor="let item of navBarItems">
    <button class="menu-tab" mat-button [attr.data-cy]="item.data_cy" routerLink="{{item.routerLink}}" routerLinkActive="{{item.routerLinkActive}}" *ngIf="(item.show === true && ((item.isAuthenticated === false) || (auth.isAuthenticated$ | async) === (item.isAuthenticated)))">
        <mat-icon class="mr" *ngIf="item.icon !== undefined">{{item.icon}}</mat-icon>
        <span class="button-text {{item.cssClass}}">{{item.label}}</span>
    </button>
  </ng-container>
  <ng-container>
    <app-auth-logout-button [elementId]="'logout'" [isForMenu]="true" *ngIf="(auth.isAuthenticated$ | async)"></app-auth-logout-button>
    <acacium-group-ng-profile-avatar class="profile-avatar" [elementId]="'profile-avatar'" [cssClasses]="'background primary'" [profileAvatarItem]="profileAvatarItem" *ngIf="(auth.isAuthenticated$ | async)"></acacium-group-ng-profile-avatar>
    <button class="hamburger-tab" mat-icon-button [attr.data-cy]="'ellipses'" [matMenuTriggerFor]="dropMenu" *ngIf="(auth.isAuthenticated$ | async)">
      <mat-icon class="ellipses">more_vert</mat-icon>
    </button>
    <mat-menu class="ellipses-menu" #dropMenu="matMenu">
        <mat-divider></mat-divider>
        <ng-container *ngFor="let item of navBarItems">
          <div class="jasmine-menu" *ngIf="(item.show === true && ((item.isAuthenticated === false) || (auth.isAuthenticated$ | async) === (item.isAuthenticated)))">
              <button class="under-ellipses-item" mat-menu-item [attr.data-cy]="item.data_cy"
              routerLink="{{item.routerLink}}" routerLinkActive="{{item.routerLinkActive}}">{{item.label}}
              </button>
              <mat-divider></mat-divider>
          </div>
        </ng-container>
    </mat-menu>
  </ng-container>
</mat-toolbar>
