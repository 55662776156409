import {Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';

@Directive({
  selector: '[acaciumGroupNgNhsNumber]'
})
export class NhsNumberDirective {
  @Input() value: NhsNumber | null = null;
  @Output() pasteHandler: EventEmitter<string | null> = new EventEmitter();
  @HostBinding('style.backgroundColor') backgroundColor: string | undefined;
  @HostBinding('style.color') color: string | undefined;
  @HostBinding('value') formValue: NhsNumber | undefined;

  constructor(private elRef: ElementRef, private clipboard: Clipboard) {}

  @HostListener('focusout') onBlur(){
    this._removeStyle();
  }
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const e = event;
    if (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      this.backgroundColor = 'rgb(0,138,255)'
      this.color = '#ffffff'
    }
    if (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      if (this.value!==null) {
        this._copy(this.value);
      }

    }
    if (e.keyCode === 88 && (e.ctrlKey || e.metaKey))  {
      e.preventDefault();
      if (this.value!==null) {
        this._copy(this.value);
      }
      this.pasteHandler.emit('');

    }
    if(e.keyCode === 86 && (e.ctrlKey || e.metaKey)){
      e.preventDefault()
      navigator.clipboard.readText().then(
          text => {
            this.pasteHandler.emit(text);
            let numericCharactersOnly = text.replace(/\D/g, '');
            if (numericCharactersOnly.length > 10) {
              numericCharactersOnly = numericCharactersOnly.substr(0, 10);
            }
            if (this.value!==null) {
              const payload: string = this._getStringsFromNhsNumber(this.value) + numericCharactersOnly;
              this.pasteHandler.emit(payload);
            } else {
              this.pasteHandler.emit(numericCharactersOnly);
            }

            this._removeStyle()
          }
      )
          .catch(error => {
                console.error('Cannot read clipboard text: ', error);
            this._removeStyle()
              }
          );
    }
        }

    private _copy(value:NhsNumber):void{
      if (this.value!==null) {
        const nhsNumber = value.p1 + value.p2 + value.p3;
        this.clipboard.copy(nhsNumber);
      }
      this._removeStyle()
    }
    private _removeStyle():void {
      this.backgroundColor = 'transparent'
      this.color = '#000000'
    }
    private _getStringsFromNhsNumber(nhsNumber:NhsNumber):string{
      if (nhsNumber.p1 !==null && nhsNumber.p2 !==null && nhsNumber.p3 !==null) {
        return nhsNumber.p1 + nhsNumber.p2 + nhsNumber.p3;
      }
      if (nhsNumber.p1 !==null && nhsNumber.p2 !==null && nhsNumber.p3 ===null) {
        return nhsNumber.p1 + nhsNumber.p2;
      }
      if (nhsNumber.p1 !==null && nhsNumber.p2 ===null && nhsNumber.p3 ===null) {
        return nhsNumber.p1;
      }
      else return '';
    }

}
export interface NhsNumber {
  p1:string;
  p2:string;
  p3:string;
}

