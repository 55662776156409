import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IUploadItem } from '@acacium-group-ng/data-definition';


@Component({
  selector: 'acacium-group-ng-files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.component.scss']
})
export class FilesListComponent {
  @Input() public uploadItems: IUploadItem[] = [];
  @Input() public viewMode = false;
  @Input() public isEditable = false;
  @Output() deleteFileRequest = new EventEmitter<IUploadItem>();

  public deleteFile(file: IUploadItem): void {
    this.deleteFileRequest.emit(file);
  }

  public hasWarnStatus(file: IUploadItem) {
    return ["MALWARE", "TIMEOUT", "EXIST", "CANCELED"].includes(file.status ?? "")
  }
}
