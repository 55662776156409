import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher, mixinErrorState } from '@angular/material/core';

export class FormInputNhsFieldComponentBase {
  constructor(
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    public _parentForm: NgForm,
    public _parentFormGroup: FormGroupDirective,
    public ngControl: NgControl,
    public stateChanges: any
  ) {}
}

export const FormInputNhsFieldComponentMixinBase = mixinErrorState(FormInputNhsFieldComponentBase);
