<div class="form-input">
  <table [attr.data-cy]='arrayInput.fullControlName + "--view"'>
    <tr [attr.data-cy]='arrayInput.fullControlName + "--headers"'>
      <th
        [attr.data-cy]='arrayInput.fullControlName + "--header--index"'
        [width]='arrayInput.columnDefinitions[0]'
        class='mat-header-cell table-header'
      >№</th>
      <th
        [attr.data-cy]='arrayInput.fullControlName + "--header--" + (arrayInput.dataModel.inputTemplate ? arrayInput.dataModel.inputTemplate.controlName : "")'
        [width]='arrayInput.columnDefinitions[1]'
        class='mat-header-cell table-header'
      >{{arrayInput.dataModel.inputTemplate ? arrayInput.dataModel.inputTemplate.label : ''}}</th>
    </tr>
    <tr
      *ngIf='arrayInput.items.length === 0'
      [attr.data-cy]='arrayInput.fullControlName + "--row-empty"'
      class='data-rows'
    >
      <td
        [attr.data-cy]='arrayInput.fullControlName + "--row-empty--index"'
        class='mat-cell'
      ><em>0</em></td>
      <td
        [attr.data-cy]='arrayInput.fullControlName + "--row-empty--" + (arrayInput.dataModel.inputTemplate ? arrayInput.dataModel.inputTemplate.controlName : "")'
        class='mat-cell'
      ><em>(Empty)</em></td>
    </tr>
    <tr
      *ngFor='let item of arrayInput.items; index as i'
      [attr.data-cy]='arrayInput.fullControlName + "--row-" + i'
      class='{{selectedItemIndex === i ? "selected" : "data-rows"}}'
      (click)='selectItem(i)'
    >
      <td
        [attr.data-cy]='arrayInput.fullControlName + "--row-" + i + "--index"'
        class='mat-cell'
      >{{i + 1}}</td>
      <td
        [attr.data-cy]='arrayInput.fullControlName + "--row-" + i + "--" + (arrayInput.dataModel.inputTemplate ? arrayInput.dataModel.inputTemplate.controlName : "")'
        class='mat-cell'
      >{{arrayInput.getFormControl(i).value}}</td>
    </tr>
  </table>
  <div>
    <div *ngIf='isEditMode || (viewMode && selectedItemIndex !== -1)' class='inputs-container'>
      <app-form-input
        [attr.data-cy]='selectedItem.fullControlName + "--form-input"'
        [formInput]="selectedItem"
        [viewMode]='viewMode'
      ></app-form-input>
    </div>
    <div class='buttons'>
      <button
        *ngIf="selectedItemIndex !== -1 && isEditMode && !viewMode"
        [attr.data-cy]='arrayInput.fullControlName + "--cancel-button"'
        (click)='cancel()'
        mat-stroked-button
      >
        <mat-icon svgIcon="cancel" class="icon"></mat-icon>
        <span>{{arrayInput.dataModel.cancelButtonLabel}}</span>
      </button>
      <button
        *ngIf="selectedItemIndex === -1 && !viewMode"
        [attr.data-cy]='arrayInput.fullControlName + "--add-button"'
        (click)="addItem()"
        mat-stroked-button
      >
        <mat-icon svgIcon="plus-solid" class="icon"></mat-icon>
        <span>{{arrayInput.dataModel.addButtonLabel}}</span>
      </button>
      <button
        *ngIf="selectedItemIndex !== -1 && !isEditMode && !viewMode"
        [attr.data-cy]='arrayInput.fullControlName + "--edit-button"'
        (click)="editItem()"
        mat-stroked-button
      >
        <mat-icon svgIcon="edit-solid" class="icon"></mat-icon>
        <span>{{arrayInput.dataModel.editButtonLabel}}</span>
      </button>
      <button
        *ngIf="selectedItemIndex !== -1 && !isEditMode && !viewMode"
        [attr.data-cy]='arrayInput.fullControlName + "--remove-button"'
        (click)="removeItem()"
        mat-stroked-button
      >
        <mat-icon svgIcon="cross" class="icon"></mat-icon>
        <span>{{arrayInput.dataModel.removeButtonLabel}}</span>
      </button>
      <button
        *ngIf="selectedItemIndex !== -1 && isEditMode && !viewMode"
        [attr.data-cy]='arrayInput.fullControlName + "--save-button"'
        (click)='saveItem()'
        mat-stroked-button
      >
        <mat-icon svgIcon="save" class="icon"></mat-icon>
        <span>{{arrayInput.dataModel.saveButtonLabel}}</span>
      </button>
    </div>
  </div>
</div>
