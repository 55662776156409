import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormInput } from '../form-models/inputs/form-input';
import { FieldInput } from '../form-models/inputs/field-input';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-form-input-string',
  templateUrl: './form-input-string.component.html',
  styleUrls: ['./form-input-string.component.scss']
})
export class FormInputStringComponent implements OnInit, OnChanges {
  @Input() formInput!: FormInput;
  @Input() viewMode!: boolean;
  @Input() isEditable!: boolean;

  fieldInput!: FieldInput;

  ngOnInit(): void {
    this.fieldInput = this.formInput as FieldInput;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fieldInput = this.formInput as FieldInput;
  }
}
