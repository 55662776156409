<div class="file-icon">
  <mat-icon svgIcon="upload" class="color primary"></mat-icon>
</div>
<div class="file-info">
  <div class="mat-body">
    <div class="file-title" [class.error]="hasNegativeStatus(status)">
      <span>
        {{
        file.fileName.length > 50
          ? (file.fileName | slice:0:49) + '...'
          : file.fileName
        }}
      </span>
      <span [class.error]="hasNegativeStatus(status)">{{status}}</span>
    </div>
  </div>
  <div class="mat-body">{{fileSize}}</div>
  <mat-progress-bar *ngIf='!viewMode' mode="determinate" value="{{file.progress}}" color="accent"></mat-progress-bar>
</div>
