import { Component, Input, OnInit } from "@angular/core";
import { formatFileSize } from "../../file-upload-services/file-utilities";
import { IUploadItem } from '@acacium-group-ng/data-definition';

@Component({
  selector: 'acacium-group-ng-file-item',
  templateUrl: './file-item.component.html',
  styleUrls: ['./file-item.component.scss']
})
export class FileItemComponent implements OnInit {
  @Input() public viewMode: boolean = false;
  @Input() public file!: IUploadItem;
  public fileSize = '';

  ngOnInit(): void {
    this.fileSize = formatFileSize(this.file.fileSize);
  }

  hasNegativeStatus(status: string){
    return ['File already exist', 'Timeout', 'Canceled', 'Malware'].includes(status)
  }

  get status(): string {
    switch (this.file.status) {
      case 'CREATED':
        return 'Created'
      case 'TO_SCAN':
        return 'Scanning..'
      case 'MALWARE':
        return 'Malware'
      case 'TIMEOUT':
        return 'Timeout'
      case 'CLEAR':
        return ''
      case 'EXIST':
        return 'File already exist'
      case 'CANCELED':
        return 'Canceled'
      default:
        return 'Initializing..'
    }
  }
}
