export class Subdomain_Organisation_Map_Helper {
  private static subdomain_organization_map: { [ key: string ]: string } = {
    'referrer-dev': 'org_i5kmu4uEHhR4nJ4S',
    'cypdt-referrer': 'org_6dV6TaXYKVEdCTME',
    'dev-cypdt-trust': 'org_FqqNhi5EDTvMYbau',
    'test-cypdt-trust': 'org_Qgv9ZL62nOyDXnHX',
    'beta-cypdt-trust': 'org_KMpQLRkoMnLZOG3X',
    'dev-berkshire': 'org_L6mLYKZudAKVNV6e',
    'test-berkshire': 'org_9bTazTmRiyoNVIu9',
    'beta-berkshire': 'org_3VwNt6eDwNUSj4NI',
    'prod-cypdt-trust': 'org_0TW1gGcNXUD1IBF4',
    'berkshire': 'org_cgWKVFo5G7UanS41'
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static getSubdomain() : { organization?: string } {

    const org_id = Subdomain_Organisation_Map_Helper.subdomain_organization_map[window.location.hostname.split('.')[0]];

    return (org_id) ? { organization: org_id } : {};
  }

}

