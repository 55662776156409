import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { DialogErrorComponent } from './dialog-error/dialog-error.component';
import { SafeHtmlPipeModule } from '../safe-html-pipe/safe-html-pipe.module';
import { MatIconModule } from '@angular/material/icon';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    SafeHtmlPipeModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    DialogConfirmComponent,
    DialogErrorComponent,
    SpinnerOverlayComponent
  ],
  exports: [
    DialogConfirmComponent,
    DialogErrorComponent,
    SpinnerOverlayComponent
  ],
  bootstrap: [
    SpinnerOverlayComponent
  ]
})
export class DialogModule {}
