<div *ngIf="!viewMode" class="form-input">
  <app-form-input-label
    [attr.data-cy]="fieldInput.fullControlName + '--input-label'"
    [formInput]="fieldInput"
    [viewMode]="viewMode"
    class="input-label"
  ></app-form-input-label>
  <div [formGroup]="fieldInput.parentFormGroup">
    <div class="input-container">
      <div *ngIf="!fieldInput.parentFormArray && !viewMode">
        <mat-checkbox
          role="checkbox"
          [name]="fieldInput.fullControlName"
          [value]="fieldInput.formControl.value"
          [attr.data-cy]="fieldInput.fullControlName + '--input'"
          [formControl]="fieldInput.fieldControl"
          id="{{fieldInput.dataModel.controlName}}"
          class="{{
            fieldInput.fieldControl.invalid &&
            (formInput.formControl.dirty || formInput.formControl.touched)
              ? 'invalid'
              : 'valid'
          }}"
        ></mat-checkbox>
        <mat-hint
          *ngIf="
            fieldInput.dataModel.description &&
            !(
              formInput.formControl.invalid &&
              (formInput.formControl.dirty || formInput.formControl.touched)
            )
          "
          [attr.data-cy]="fieldInput.fullControlName + '--hint'"
          class="mat-small hint"
          >{{ fieldInput.dataModel.description }}</mat-hint
        >
        <mat-error
          *ngIf="
            formInput.formControl.invalid &&
            (formInput.formControl.dirty || formInput.formControl.touched)
          "
          class="mat-small error"
          >{{ fieldInput.errorMessage }}</mat-error
        >
      </div>
      <div
        *ngIf="fieldInput.parentFormArray && !viewMode"
        formArrayName="{{ fieldInput.parentArrayName }}"
      >
        <mat-checkbox
          role="checkbox"
          [name]="fieldInput.fullControlName"
          [value]="fieldInput.formControl.value"
          [attr.data-cy]="fieldInput.fullControlName + '--input'"
          [formControl]="fieldInput.fieldControl"
          class="{{
            fieldInput.fieldControl.invalid &&
            (formInput.formControl.dirty || formInput.formControl.touched)
              ? 'invalid'
              : 'valid'
          }}"
        ></mat-checkbox>
        <mat-hint
          *ngIf="
            fieldInput.dataModel.description &&
            !(
              formInput.formControl.invalid &&
              (formInput.formControl.dirty || formInput.formControl.touched)
            )
          "
          [attr.data-cy]="fieldInput.fullControlName + '--hint'"
          class="mat-small hint"
          >{{ fieldInput.dataModel.description }}</mat-hint
        >
        <mat-error
          *ngIf="
            formInput.formControl.invalid &&
            (formInput.formControl.dirty || formInput.formControl.touched)
          "
          class="mat-small error"
          >{{ fieldInput.errorMessage }}</mat-error
        >
      </div>
    </div>
  </div>
</div>
<div *ngIf="viewMode && isEditable" class="form-view">
  <app-form-input-label
    [attr.data-cy]="fieldInput.fullControlName + '--input-label'"
    [formInput]="fieldInput"
    [viewMode]="viewMode"
    class="input-label"
    [ngClass]="{ 'field-error': fieldInput.formControl.invalid }"
  ></app-form-input-label>
  <div
    [attr.data-cy]="fieldInput.fullControlName + '--view'"
    class="result-value"
    [ngClass]="{ 'field-error': fieldInput.formControl.invalid }"
  >
    <span *ngIf="fieldInput.formControl.invalid"
      ><acacium-group-ng-form-error-indicator
        >!</acacium-group-ng-form-error-indicator
      >&nbsp;<em>{{fieldInput.errorMessage}}</em></span
    >
    <span *ngIf="fieldInput.formControl.valid">{{
      fieldInput.formControl.value ? 'Yes' : 'No'
    }}</span>
  </div>
</div>
<div *ngIf="viewMode && !isEditable" class="form-view">
  <app-form-input-label
    [attr.data-cy]="fieldInput.fullControlName + '--input-label'"
    [formInput]="fieldInput"
    [viewMode]="viewMode"
    class="input-label"
  ></app-form-input-label>
  <div
    [attr.data-cy]="fieldInput.fullControlName + '--view'"
    class="result-value"
  >
    <span *ngIf="fieldInput.formControl.valid">{{
      fieldInput.formControl.value ? 'Yes' : 'No'
    }}</span>
    <span *ngIf="fieldInput.formControl.invalid">-</span>
  </div>
</div>
