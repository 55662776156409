/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import {
  catchError,
  filter, forkJoin, from, interval,
  map,
  Observable,
  Subject,
  Subscription,
  switchMap,
  take,
  takeUntil,
  tap, throwError
} from 'rxjs';
import { DragDropStyles } from '../../file-upload-models/drag-drop-styles.model';
import { DialogConfirmModel } from '../../../dialog/models/dialog-confirm.model';
import { DialogConfirmComponent } from '../../../dialog/dialog-confirm/dialog-confirm.component';
import { DragDropFiles } from '../../file-upload-models/drag-drop-files.model';
import { MatDialog } from "@angular/material/dialog";
import { ErrorDialogData } from "../../../dialog/dialog-error/dialog-error.model";
import { DialogErrorComponent } from "../../../dialog/dialog-error/dialog-error.component";
import { formatFileSize } from "../../file-upload-services/file-utilities";
import { IResAttachment, IUploadItem } from '@acacium-group-ng/data-definition';
import { format } from 'date-fns';
import { ReferralService } from '@acacium-group-ng/referrals/data-access';
import { AzureBlobStorageService } from "../../file-upload-services/azure-blob-storage.service";
import { IAzureBlobStorageToken } from "../../file-upload-models/azure-blob-storage-token.model";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-drag-drop-upload',
  templateUrl: './drag-drop-upload.component.html',
  styleUrls: ['./drag-drop-upload.component.scss']
})
export class DragDropUploadComponent implements OnDestroy {
  @Input() public styleOptions: DragDropStyles = { backgroundColor: '', color: '', border: '' }
  @Input() public fileOptions: DragDropFiles = {
    uploadFileAllowedExts: [],
    uploadSingleFileMaxSizeKB: 0
  }
  @Input() public preventBodyDrop = true;
  @Input() public baseUrl = '';
  @Input() public description?: string;
  @Input() public blobStoragePath = '';
  @Input() public viewMode = false;
  @Input() public isEditable!: boolean;
  @Input() public uploadItems: IUploadItem[] = [];

  @Input() referralId: number | null = null;
  @Output() uploaded = new EventEmitter<IUploadItem[]>();
  @Output() attachedDocument = new EventEmitter<IUploadItem>();
  @Output() detachedDocument = new EventEmitter<IUploadItem>();
  @Output() numberOfFilesSent = new EventEmitter<number>();
  @Output() singleFileSent = new EventEmitter<number>();
  @ViewChild('fileField') fileField!: ElementRef<HTMLInputElement>;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public uploadSubscription: Subscription | null = null;
  public attachmentSubscription: Subscription | null = null;
  public isValid: boolean | null = null;
  public uploadValidationFailureMessage = {
    title: 'Upload Validation Failed',
    message: '',
    hasNoButton: false,
    buttonNo: 'Okay',
    isError: true
  };
  public isLoading = false;

  constructor(
    public apiService: ReferralService,
    public dialog: MatDialog,
    public storageService: AzureBlobStorageService
  ) {
  }

  // Upload event from select file popup (button press)
  onFileInput(e: Event) {
    const target = e.target as HTMLInputElement;
    this.upload(target.files);
    // reset current value of the input
    this.fileField.nativeElement.value = '';
  }

  // On form submit, upload the files - validate and upload, or return error
  upload(e: any) {
    this.isValid = true;
    this.apiService.allAttachmentsUploaded.next(false);
    const fileList: File[] = Array.from(e);

    if (!fileList.length) {
      return;
    }

    if (fileList.find(e => !(this.validateFileSize(e) && this.validateFileType(e)))) {
      this.cancelUpload();
      return;
    }

    this.numberOfFilesSent.emit(fileList.length);
    const uploadItemsList = fileList.map(e => this.createUploadItem(e))
    this.initiateUploadProcess(uploadItemsList)
  }

  private removeFileExtension(filename: string): string {
    const dotIndex = filename.indexOf('.');
    if (dotIndex !== -1) {
      return filename.substring(0, dotIndex);
    } else {
      return filename;
    }
  }

  private createUploadItem(file: File): IUploadItem {
    const fileExtension = this.fileExtension(file.name).toLowerCase();
    const uniqueFileName: string = URL.createObjectURL(file);
    const uploadUrl: string = new URL(new URL(uniqueFileName).pathname).pathname;
    console.warn('url being generated is', uniqueFileName, ' : ', uploadUrl);
    const messageID = uploadUrl.substring(1);
    const dateTimeUtcNow: string = new Date().toISOString().replace(/[-T:.Z]/g, '').slice(0, -1);
    const _referralId = this._refId();
    const fileNameWithoutExtension = this.removeFileExtension(file.name)
    const uploadName = `${ _referralId }/${ dateTimeUtcNow }/${ fileNameWithoutExtension }_${ messageID }${ fileExtension }`;
    const item: IUploadItem = {
      item: file,
      uploadedDateTime: new Date(),
      fileName: file.name,
      fileSize: file.size,
      uploadName: uploadName,
      progress: 1,
      url: `${ this.blobStoragePath }${ uploadName }`
    };
    if (!this.uploadItems) this.uploadItems = [];
    this.uploadItems.push(item);
    return item;
  }

  private initiateUploadProcess(items: IUploadItem[]) {
    items.forEach(file => file.progress = 40)
    const referralId = this.apiService.curReferral.getValue().referralId
    if (referralId){
      items.forEach(file => this.uploadAttachment(file, referralId))
      this.apiService.uploadAttachments().pipe(
        switchMap(()=>forkJoin(items.map(file => this.checkFileStatusUntilCreated(file)))),
        switchMap(uploadItems=>forkJoin(uploadItems.map(uploadItem =>this.waitForFileStatusClear(uploadItem.file, uploadItem.attachment) ))),
        // tap(()=>this.uploadFileAndUpdateProgress(items))
      ).subscribe(
        {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          next: ()=>{},
          error: () => {
            this.singleFileSent.emit(0)
          }
        }
      );
    } else {
      items.forEach(file => this.attachedDocument.emit(file))
      void this.uploadFileAndUpdateProgress(items)
    }
  }

  getAttachments(): Observable<IResAttachment[]> {
    return this.apiService.getAttachments(this._refId()).pipe(
      tap(res => {
        this.apiService.apiAttachments = res;
      })
    )
  }

  checkAllAttachmentsStatus(attachments: IResAttachment[]): boolean {
    const allowedStatuses = ['CLEAR', 'DELETED'];
    return attachments.every(attachment => allowedStatuses.includes(attachment.status));
  }

  private async uploadFileAndUpdateProgress(files: IUploadItem[]) {

    files.forEach(file => file.progress = 50)
    const referralId = this.apiService.curReferral.getValue().referralId
    if (referralId){
      await this.uploadAndCheckFileStatus(files, referralId)
    } else {
      files.forEach(file => this.attachedDocument.emit(file))
    }
  }

  private waitForFileStatusClear(file: IUploadItem, uploadedFile: IResAttachment) {
    const uploadToken = uploadedFile.uploadToken;
    // TODO After solving the problems with the token, remove the unnecessary code
    const token = {
      token: `?${uploadToken}`,
      baseUri: 'https://streferralscypdtdev.blob.core.windows.net',
      container: 'new-files',
      uri: `https://streferralscypdtdev.blob.core.windows.net'/new-files${uploadToken}`
    } as IAzureBlobStorageToken;
    return this.storageService.getUploadToken(`${ this.baseUrl }/file_upload`).pipe(

      tap(()=>console.log('new token: ', token.token)),
      tap((newToken)=>console.log('old token: ', newToken.token)),
      switchMap((newToken)=> this.storageService.upload(uploadedFile.uploadPath, file, newToken)),
      switchMap(()=>this.checkFileStatusUntilClear(file))
    )
  }

  private async uploadAndCheckFileStatus(files: IUploadItem[], referralId: string) {
    files.forEach(file => this.uploadAttachment(file, referralId))
    await this.apiService.uploadAttachments();
    files.forEach(file => this.checkFileStatusUntilCreated(file))
  }

  private checkFileStatusUntilCreated(file: IUploadItem): Observable<{ file: IUploadItem; attachment: IResAttachment }> {
    const allowedStatuses = ['CREATED', 'DELETED', 'TIMEOUT']
    return interval(3000)
      .pipe(
        switchMap(() => from(this.getAttachments())),
        map((attachments: IResAttachment[]) => attachments.find(attachment => attachment.fileName === file.fileName)),
        filter((fileStatus: IResAttachment | undefined): fileStatus is IResAttachment => !!fileStatus && allowedStatuses.includes(fileStatus.status)),
        map( (attachment) => {
          return {file, attachment}
        }),
        take(1)
      )
  }

  private checkFileStatusUntilClear(file: IUploadItem): Observable<IResAttachment> {
    const allowedStatuses = ['CLEAR', 'DELETED', 'TIMEOUT', 'MALWARE']

    return interval(3000)
      .pipe(
        switchMap(() => from(this.getAttachments().pipe(
          tap(attachments => {
            this.apiService.allAttachmentsUploaded.next(
              this.checkAllAttachmentsStatus(attachments)
            );
            attachments = attachments.filter(attachment => attachment.status !== 'DELETED')
            file.status = attachments.find(a => a.fileName == file.fileName)?.status ?? ''
          })
        ))),
        map((attachments: IResAttachment[]) => attachments.filter(attachment => attachment.status !== 'DELETED')),
        map((attachments: IResAttachment[]) => attachments.find(attachment => attachment.fileName === file.fileName)),
        filter((fileStatus: IResAttachment | undefined): fileStatus is IResAttachment => !!fileStatus && allowedStatuses.includes(fileStatus.status)),
        tap(()=>{
            file.progress = 100;
            this.singleFileSent.emit(-1)
        }),
        take(1)
      )
  }

  public uploadAttachment(file: IUploadItem, referralId: string): void {
    this.apiService.attachments.push({ file, referralId });
  }

  // Custom and manual validation - cannot use custom validators due to input type
  // Validate the file types from list sent via environment file
  private validateFileType(file: File): boolean {
    const extension = this.fileExtension(file.name);

    for (let i = 0; i < this.fileOptions.uploadFileAllowedExts.length; i++) {
      const typeFile = this.fileOptions.uploadFileAllowedExts[i].toUpperCase();

      if (typeFile === extension.toUpperCase() && (file.type === 'application/pdf' || file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        // return true if file extensions equals one from the allowed list
        return true;
      }
    }
    this.confirmDialog(`File type validation failed: each file must have one of the following formats: ${ this.fileOptions.uploadFileAllowedExts }`);
    return false;
  }

  // Function to get file extension
  private fileExtension(filename: string): string {
    // eslint-disable-next-line no-useless-escape
    const path = filename.replace(/^.*[\\\/]/, '');
    const extension = path.split('.')[1].toUpperCase();

    return `.${ extension }`;
  }

  // Validate the file size from vakue sent via environment file
  private validateFileSize(file: File): boolean {
    const fileSize = file.size;
    const fileSizeInKB = Math.round(fileSize / 1000);


    if (fileSizeInKB <= this.fileOptions.uploadSingleFileMaxSizeKB) {
      return true;
    }

    this.confirmDialog(`File size validation failed: the maximum size of each file is ${ formatFileSize(this.fileOptions.uploadSingleFileMaxSizeKB * 1000, 0) }`)
    return false;
  }

  // display validation errors to the user
  private confirmDialog(message: string = ''): void {
    this.uploadValidationFailureMessage.message = message;
    const data = new DialogConfirmModel(this.uploadValidationFailureMessage);


    this.dialog.open(DialogConfirmComponent, {
      autoFocus: false,
      disableClose: true,
      data: data,
    });
  }

  // terminate the upload  - ***to be extended to remove uploaded files*** */
  public cancelUpload(): void {
    this.isLoading = false;
    // unsubscribe from subscription if active
    this.uploadSubscription?.unsubscribe();
    // upload box shows as invalid
    this.isValid = false;
    // initialise list of uploaded files
    this.uploadItems = this.uploadItems ? this.uploadItems : [];
    this.uploaded.emit(this.uploadItems);
    // initialise upload subscription
    this.uploadSubscription = null;
  }

  // delete file from uploaded list - ***to be extended to remove uploaded files***
  public deleteFile(file: IUploadItem): void {
    this.isLoading = true;
    this.getAttachments().pipe(
          takeUntil(this.destroy$),
          catchError(error => {
            this.cancelUpload();
            const errorDialog: ErrorDialogData = {
              title: 'Issue on deleting the attachment',
              message:
                `There has been an issue deleting the attachment, please try again later. If the issue still persists, please contact support&nbsp;<strong>aaa@zyla.com</strong>`,
            };
            this.dialog.open(DialogErrorComponent, {
              data: errorDialog,
            });
            return throwError(error);
          })
    )
      .subscribe(( attachments ) => {
        const fileToDelete = attachments.filter(attachment => attachment.status !== 'DELETED').find(attachment => attachment.fileName === file.fileName);

        if (!fileToDelete) {
          throw new Error('File not found.');
        }

        this.deleteFileWithToken(file, fileToDelete);
      });
  }

  private deleteFileWithToken(file: IUploadItem, attachment: IResAttachment): void {
    this.deleteFileAndUpdate(file, attachment);
    this.isLoading = false;
  }

  public deleteFileAndUpdate(file: IUploadItem, resAttachment: IResAttachment) {
    this.apiService.curReferral.pipe(
      tap(() => (this.isLoading = false)),
      take(1)).subscribe((res) => {
      const uploadItemsIndex: number = this.uploadItems.findIndex(item => item.uploadedDateTime == file.uploadedDateTime);
      const attachmentsIndex: number = this.apiService.attachments.findIndex(item => item.file === file);

      this.apiService.getAttachments(this._refId()).subscribe(attachments => {
        const attachment = attachments
          .filter(attachment => attachment.status !== 'DELETED')
          .find(attachment => attachment.fileName == file.fileName);

        (attachment) ? this.deleteAttachment(resAttachment) : this.detachedDocument.emit(file);
        if (uploadItemsIndex != -1) this.uploadItems.splice(uploadItemsIndex, 1);
        if (attachmentsIndex != -1) this.apiService.attachments.splice(attachmentsIndex, 1);

        this.uploaded.emit(this.uploadItems);
      })
    });
  }

  public deleteAttachment(resAttachment: IResAttachment): void {
    this.apiService.deleteAttachment$(resAttachment.referralId, resAttachment.attachmentId).pipe(take(1)).subscribe();
  }

  ngOnDestroy(): void {
    this.uploadSubscription?.unsubscribe();
    this.uploadSubscription = null;
    this.attachmentSubscription?.unsubscribe();
    this.apiService.curReferral.next({
      referrerName: '',
      referralDate: format(new Date(), 'yyyy-MM-dd'),
      referralId: ''
    });
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private _refId(): string {
    let _referralId;
    if (this.referralId === null) {
      _referralId = this.apiService.curReferral.value.referralId;
    } else {
      _referralId = this.referralId;
    }
    return _referralId as string;
  }
}
