/* eslint-disable @angular-eslint/component-selector */
import { Component, Input } from '@angular/core';
import { environment as env } from './../../../../environments/environment';
import { JwtAuthService } from '../../features/jwt-auth/services/jwt-auth.service';

@Component({
  selector: 'app-auth-logout-button',
  templateUrl: './logout-button.component.html',
  styleUrls: ['./logout-button.component.scss']
})
export class LogoutButtonComponent {

  @Input() public isForMenu = false;
  @Input() public elementId = '';

  public maxTimeout = env.idleTimeout.timeout;
  public countdown = this.maxTimeout;

  constructor(public jwtAuthService: JwtAuthService) {}

  logout(): void {
    this.jwtAuthService.logout();
  }

}
