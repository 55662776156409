<div *ngIf='!viewMode' class="form-input">
  <app-form-input-label
    [attr.data-cy]='fieldInput.fullControlName + "--input-label"'
    [formInput]='fieldInput'
    [viewMode]='viewMode'
    class='input-label'
  ></app-form-input-label>
  <div [formGroup]="fieldInput.parentFormGroup">
    <div class='input-container'>
      <div *ngIf='!fieldInput.parentFormArray && !viewMode'>
        <mat-form-field  appearance='outline'>
          <textarea
            [value]="fieldInput.fieldControl.value"
            role="textbox"
            [name]="fieldInput.fullControlName"
            [attr.data-cy]='fieldInput.fullControlName + "--input"'
            placeholder='{{fieldInput.dataModel.placeholder}}'
            matInput
            id="{{fieldInput.fullControlName}}"
            formControlName="{{fieldInput.fieldName}}"
            (change)='fieldInput.fieldControl.updateValueAndValidity()'
            maxlength='{{fieldInput.dataModel.maxLength}}'
            #input
          ></textarea>
          <mat-hint
            *ngIf='fieldInput.dataModel.maxLength'
            [attr.data-cy]='fieldInput.fullControlName + "--counter"'
            align="end"
          >Remaining characters: {{fieldInput.dataModel.maxLength - input.value.length}}/{{fieldInput.dataModel.maxLength}}</mat-hint>
          <mat-hint
            *ngIf='fieldInput.dataModel.description'
            [attr.data-cy]='fieldInput.fullControlName + "--hint"'
          >{{fieldInput.dataModel.description}}</mat-hint>
          <mat-error>{{fieldInput.errorMessage}}</mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="fieldInput.parentFormArray && !viewMode" formArrayName="{{fieldInput.parentArrayName}}">
        <mat-form-field appearance="outline">
          <textarea
            [value]="fieldInput.fieldControl.value"
            role="textbox"
            [name]="fieldInput.fullControlName"
            [attr.data-cy]='fieldInput.fullControlName + "--input"'
            placeholder='{{fieldInput.dataModel.placeholder}}'
            matInput
            id="{{fieldInput.fullControlName}}"
            formControlName="{{fieldInput.fieldName}}"
            (change)='fieldInput.fieldControl.updateValueAndValidity()'
            maxlength='{{fieldInput.dataModel.maxLength}}'
            #input
          ></textarea>
          <mat-hint
            *ngIf='fieldInput.dataModel.maxLength'
            [attr.data-cy]='fieldInput.fullControlName + "--counter"'
            align="end"
          >Remaining characters: {{fieldInput.dataModel.maxLength - input.value.length}}/{{fieldInput.dataModel.maxLength}}</mat-hint>
          <mat-hint
            *ngIf='fieldInput.dataModel.description'
            [attr.data-cy]='fieldInput.fullControlName + "--hint"'
          >{{fieldInput.dataModel.description}}</mat-hint>
          <mat-error>{{fieldInput.errorMessage}}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<div *ngIf="viewMode && isEditable" class="form-view">
  <app-form-input-label
    [attr.data-cy]='fieldInput.fullControlName + "--input-label"'
    [formInput]='fieldInput'
    [viewMode]='viewMode'
    class='input-label'
    [ngClass]="{'field-error': fieldInput.formControl.invalid}"
  ></app-form-input-label>
  <div class='result-value'>
    <div
      *ngIf='fieldInput.formControl.value && fieldInput.formControl.valid'
      [attr.data-cy]='fieldInput.fullControlName + "--view"'
    >{{fieldInput.formControl.value}}</div>
    <em
      *ngIf="!fieldInput.formControl.value && fieldInput.fieldControl.valid"
      [attr.data-cy]='fieldInput.fullControlName + "--view"'
    >-</em>
    <div
      *ngIf="fieldInput.formControl.invalid"
      [attr.data-cy]='fieldInput.fullControlName + "--view-error"'
      class="field-error"
    ><acacium-group-ng-form-error-indicator>!</acacium-group-ng-form-error-indicator>&nbsp;<em>{{fieldInput.errorMessage}}</em></div>
  </div>
</div>
<div *ngIf="viewMode && !isEditable" class="form-view">
  <app-form-input-label
    [attr.data-cy]='fieldInput.fullControlName + "--input-label"'
    [formInput]='fieldInput'
    [viewMode]='viewMode'
    class='input-label'
  ></app-form-input-label>
  <div class="result-value">
    <div
      *ngIf='fieldInput.formControl.value && fieldInput.formControl.valid'
      [attr.data-cy]='fieldInput.fullControlName + "--view"'
    >{{fieldInput.formControl.value}}</div>
    <em
      *ngIf='!fieldInput.formControl.value && fieldInput.fieldControl.valid'
      [attr.data-cy]='fieldInput.fullControlName + "--view"'
    >-</em>
  </div>
</div>
