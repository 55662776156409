import { Component, Input, OnInit } from '@angular/core';
import { FormInput } from '../form-models/inputs/form-input';
import { ComplexArrayInput } from '../form-models/inputs/complex-array-input.model';
import { UntypedFormGroup } from '@angular/forms';
import { Subsection } from '../form-models/subsection';
import { MatDialog } from '@angular/material/dialog';
import { PopUpConfirmComponent } from '../pop-up-confirm/pop-up-confirm.component';
import { errorField, errorFieldTitle } from "@acacium-group-ng/message-constants";
import { ErrorDialogData } from '../../dialog/dialog-error/dialog-error.model';
import { DialogErrorComponent } from '../../dialog/dialog-error/dialog-error.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-form-input-complex-array',
  templateUrl: './form-input-complex-array.component.html',
  styleUrls: ['./form-input-complex-array.component.scss'],
})
export class FormInputComplexArrayComponent implements OnInit {
  @Input() formInput!: FormInput;
  @Input() viewMode!: boolean;
  @Input() isEditable!: boolean;

  arrayInput!: ComplexArrayInput;

  selectedItemIndex = -1;
  selectedItem!: { formGroup: UntypedFormGroup; subsections: Subsection[] };
  isEditMode = false;
  isNewItem = false;
  previousValue: string | null = null;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.arrayInput = this.formInput as ComplexArrayInput;
  }


  addItem() {
    this.arrayInput.addNewItem();
    this.selectedItemIndex = this.arrayInput.items.length - 1;
    this.selectedItem = this.arrayInput.items[this.selectedItemIndex];
    this.isNewItem = true;
    this.initialiseItemToValid();
    this.editItem();
  }

  initialiseItemToValid(): void {
    const controlName = this.arrayInput.dataModel.controlName;
    const formGroup = this.arrayInput.items[0].formGroup.get(controlName);
    formGroup?.setErrors(null);
    formGroup?.markAsUntouched();
    formGroup?.markAsPristine();
    formGroup?.updateValueAndValidity()
  }

  removeItem(itemName?: string) {
    if (!itemName) itemName = this.arrayInput.fieldName;
    const dialogRef = this.dialog.open(PopUpConfirmComponent, {
      data: `Are you sure you want to remove this ${itemName}?`,
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.arrayInput.removeItem(this.selectedItemIndex);
        this.selectedItemIndex = -1;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.selectedItem = undefined;
      }
    });
  }

  selectItem(index: number) {
    if (this.isEditMode) {
      return;
    }

    if (index === this.selectedItemIndex) {
      this.selectedItemIndex = -1;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.selectedItem = undefined;
    } else {
      this.selectedItemIndex = index;
      this.selectedItem = this.arrayInput.items[index];
    }
  }

  editItem() {
    this.previousValue = this.selectedItem.formGroup.value
      ? JSON.stringify(this.selectedItem.formGroup.value)
      : null;
    this.isEditMode = true;
  }

  saveItem() {
    this.isNewItem = false;
    if (!this.validateSection()) {
      return;
    }

    // this.isNewItem = false;
    this.isEditMode = false;
    this.selectedItemIndex = -1;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.selectedItem = undefined;
  }

  validateSection(): boolean {
    const sectionInputs = this.selectedItem.subsections.map((e) => e.formInputs).flat();
    if (!this.isNewItem) {
      sectionInputs.forEach((input) => {
        input.formControl.markAsTouched();
        input.formControl.updateValueAndValidity();
      });
    }


    const isValid = sectionInputs.every((e) => e.formControl.valid);

    const errorDialog: ErrorDialogData = {
      title: errorFieldTitle,
      message: errorField
    }

    if (!isValid) {
      this.dialog.open(DialogErrorComponent, {
        data: errorDialog,
      });
    }

    return isValid;
  }

  cancel() {
    // const previousValue = this.previousValue
    //   ? JSON.parse(this.previousValue)
    //   : null;
    // this.selectedItem.formGroup.reset(previousValue);
    this.isEditMode = false;

    // if (this.isNewItem) {
    //   this.arrayInput.removeItem(this.selectedItemIndex);
    // }
    this.isNewItem = false;
    this.selectedItemIndex = -1;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.selectedItem = undefined;
  }

  onKeyPressEvent(i: number) {
    this.selectItem(i);
  }
}
