export enum ControlType {
  String,
  Text,
  Number,
  Boolean,
  TwoOptions,
  Checkboxes,
  RadioButtons,
  Dropdown,
  ListBox,
  Date,
  DateTime,
  SimpleArray,
  ComplexArray,
  Files,
  NhsNumber
}
