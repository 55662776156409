export class DialogConfirmModel {
  public options: { title: string; message: string; message2?: string | undefined; hasNoButton: boolean; hasCancelButton?: boolean, buttonNo?: string; buttonOk?: string,
    buttonCancel?: string, isError?: boolean, hasClose?: boolean } = {
    title: '',
    message: '',
    message2: '',
    hasNoButton: true,
    hasCancelButton: false,
    buttonOk: 'ok',
    buttonNo: 'no',
    buttonCancel: 'cancel',
    isError: false,
    hasClose: true
  }

  constructor(public _options: { title: string, message: string, message2?: string, hasNoButton: boolean, hasCancelButton?: boolean, buttonNo?: string, buttonOk?: string, buttonCancel?: string, isError?: boolean, hasClose?: boolean }) {
    this.options = _options;
    this.setDefaults(_options);
  }

  setDefaults(_options: { title: string, message: string, message2?: string, hasNoButton: boolean, hasCancelButton?: boolean, buttonNo?: string, buttonOk?: string, buttonCancel?: string, isError?: boolean, hasClose?: boolean }): void {
    if (_options.message2 === undefined) {
      this.options.message2 = '';
    }
    if (_options.hasNoButton === undefined) {
      this.options.hasNoButton = true;
    }
    if (_options.hasCancelButton === undefined) {
      this.options.hasCancelButton = false;
    }
    if (_options.hasClose === undefined) {
      this.options.hasClose = true;
    }
    if(_options.isError === undefined) {
      this.options.isError = false;
    }
  }
}

